import React from 'react';
import PropTypes from 'prop-types';
import Panel from '@x-functions/freyja/lib/components/panel';
import {
  Field,
  Label,
  Control,
  Input,
} from '@x-functions/freyja/lib/components/form';
import { workflowNodeType } from '@x-functions/x-react-workflow/lib/components/workflow/types';

function EndInspector({
  node,
  updateNode,
}) {
  const handleValueChange = React.useCallback(e => {
    const { name, value } = e.target;
    updateNode({ id: node.id, changes: { [name]: value } });
  }, [node.id, updateNode]);

  return (
    <>
      <Panel.Block>
        <Field fullwidth>
          <Label>Step Name</Label>
          <Control fullwidth>
            <Input fullwidth name="name" id="name" value={node.name} onChange={handleValueChange} />
          </Control>
        </Field>
      </Panel.Block>
    </>
  );
}

EndInspector.propTypes = {
  node: workflowNodeType.isRequired,
  updateNode: PropTypes.func.isRequired,
};

EndInspector.defaultProps = {
};

export default EndInspector;
