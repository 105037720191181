export const capitalise = ([first, ...rest]) => [first.toUpperCase(), rest.join('').toLowerCase()].join('');

export const camelToSencenceCase = text => capitalise(
  text.replace(/([a-z0-9])([A-Z])/g, '$1 $2').replace(/([A-Z])([A-Z])([a-z0-9])/g, '$1 $2$3').trim(),
);

export const pluralize = (singular, plural, n) => {
  if (n === 1) return singular;

  return plural;
};
