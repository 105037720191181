import { get, set, unset } from 'lodash/fp';

export const forEachNodeLevel = (nodes, predicate, path = []) => {
  if (!nodes) {
    return undefined;
  }

  if (predicate(nodes, path)) return 'found';

  const nodeList = Object.values(nodes || {});
  for (let i = 0; i < nodeList.length; i += 1) {
    const node = nodeList[i];
    const childPath = path.concat(node.id);
    if (node.nodes) {
      if (forEachNodeLevel(node.nodes, predicate, childPath.concat('nodes'))) return 'found';
    }
    if (node.branches) {
      for (let j = 0; j < node.branches.length; j += 1) {
        const branchPath = childPath.concat('branches', `${j}`, 'nodes');
        if (forEachNodeLevel(node.branches[j].nodes, predicate, branchPath)) return 'found';
      }
    }
  }

  return undefined;
};

export const findInNodes = (nodes, predicate, path = []) => {
  let result = { node: undefined, path };
  forEachNodeLevel(nodes, (nodeLevel, levelPath) => {
    const found = predicate(nodeLevel, levelPath);
    if (found) {
      result = { node: found, path: levelPath };
      return true;
    }
    return false;
  }, path);

  return result;
};

export const findNodeById = (nodes, nodeId, path = []) => {
  const findResults = findInNodes(nodes, nodeLevel => nodeLevel && nodeLevel[nodeId], path);
  return {
    ...findResults,
    path: findResults.node ? findResults.path.concat(nodeId) : findResults.path,
  };
};

export const findParentNode = (nodes, childId, path = []) => {
  const findResults = findInNodes(nodes, nodeLevel => {
    const levelArray = Object.values(nodeLevel || {});
    return levelArray.find(n => {
      if (n?.firstNode === childId || n?.onSuccess === childId) return n;
      if (n?.onNoMatch === childId || !!n?.choices?.find(c => c?.onSuccess === childId)) return n;
      if (n?.branches?.find(b => b?.firstNode === childId)) return n;
      return false;
    });
  }, path);
  return {
    ...findResults,
    path: findResults.node ? findResults.path.concat(findResults.node.id) : findResults.path,
  };
};

export const getNodeByPath = get;

export const setNodeByPath = set;

export const deleteNodeByPath = unset;

export const deleteNodeById = (nodes, nodeId) => {
  const { node, path } = findNodeById(nodes, nodeId);
  if (node) {
    return deleteNodeByPath(path, nodes);
  }

  return nodes;
};

export const setNodeById = (nodes, nodeId, newNode) => {
  const { node, path } = findNodeById(nodes, nodeId);
  if (node) {
    return setNodeByPath(path, newNode, nodes);
  }

  return nodes;
};

export const updateNodeByPath = (path, changes, tree, originalNode = undefined) => {
  const node = originalNode || get(path, tree);
  return set(path, { ...node, ...changes }, tree);
};

export const updateNodeById = (id, changes, nodes) => {
  const { node, path } = findNodeById(nodes, id);
  return updateNodeByPath(path, changes, nodes, node);
};

export const updateNodeLink = (node, path, oldChildId, newChildId, tree) => {
  let newTree = tree;

  // Fix all possible parent links
  if (node?.onSuccess === oldChildId) {
    newTree = updateNodeByPath(path, { onSuccess: newChildId }, newTree);
  }
  if (node?.onNoMatch === oldChildId) {
    newTree = updateNodeByPath(path, { onNoMatch: newChildId }, newTree);
  }
  if (node?.firstNode === oldChildId) {
    newTree = updateNodeByPath(path, { firstNode: newChildId }, newTree);
  }
  if (node?.choices) {
    node.choices.forEach((c, idx) => {
      if (c.onSuccess === oldChildId) {
        newTree = updateNodeByPath(
          path.concat('choices', idx),
          { onSuccess: newChildId },
          newTree,
        );
      }
    });
  }
  if (node?.branches) {
    node.branches.forEach((b, idx) => {
      if (b.firstNode === oldChildId) {
        newTree = updateNodeByPath(
          path.concat('branches', idx),
          { firstNode: newChildId },
          newTree,
        );
      }
    });
  }

  return newTree;
};

// Add a node to in a direct flow (no forks)
const addNodeDirectFlow = (nodes, parentPath, fieldName, newNode, nextNodeId) => {
  let newNodes = nodes;

  // Add new node
  newNodes = setNodeByPath(
    parentPath.slice(0, parentPath.length - 1).concat(newNode.id),
    {
      ...newNode,
      [newNode.type === 'conditional' ? 'onNoMatch' : 'onSuccess']: nextNodeId,
    },
    newNodes,
  );

  // Remap the parent
  newNodes = updateNodeByPath(parentPath, { [fieldName]: newNode.id }, newNodes);

  return newNodes;
};

// Add a node to in a different flow (e.g. conditional)
const addNodeForkedFlow = (nodes, parentPath, forkPath, newNode, nextNodeId) => {
  let newNodes = nodes;

  // Add new node
  newNodes = setNodeByPath(
    parentPath.slice(0, parentPath.length - 1).concat(newNode.id),
    {
      ...newNode,
      [newNode.type === 'conditional' ? 'onNoMatch' : 'onSuccess']: nextNodeId,
    },
    newNodes,
  );

  // Remap the parent
  newNodes = updateNodeByPath(parentPath.concat(...forkPath), { onSuccess: newNode.id }, newNodes);

  return newNodes;
};

export const insertNodeBefore = (id, node, nodes) => {
  let newNodes = nodes;
  const { node: parent, path: parentPath } = findParentNode(newNodes, id);

  // Direct flow?
  const directFlow = ['onSuccess', 'onNoMatch'];
  const directFlowProp = directFlow.find(p => parent[p] === id);
  if (directFlowProp) {
    newNodes = addNodeDirectFlow(newNodes, parentPath, directFlowProp, node, id);
  } else if (parent?.type === 'conditional') {
    const conditionIndex = parent.choices?.findIndex(c => c.onSuccess === id);
    if (conditionIndex > -1) {
      newNodes = addNodeForkedFlow(
        newNodes,
        parentPath,
        ['choices', conditionIndex],
        node,
        id,
      );
    }
  } else if (parent?.type === 'parallel') {
    const branch = parent.branches.findIndex(b => b.firstNode === id);
    if (branch > -1) {
      // Add new node
      newNodes = setNodeByPath(
        parentPath.concat('branches', branch, 'nodes', node.id),
        {
          ...node,
          [node.type === 'conditional' ? 'onNoMatch' : 'onSuccess']: id,
        },
        newNodes,
      );

      // Remap the parent
      newNodes = updateNodeByPath(parentPath.concat('branches', branch), { firstNode: node.id }, newNodes);
    }
  } else if (parent?.nodes && parent.nodes[id]) {
    // add to parent's nodes
    newNodes = setNodeByPath(
      parentPath.concat('nodes', node.id),
      {
        ...node,
        [node.type === 'conditional' ? 'onNoMatch' : 'onSuccess']: id,
      },
      newNodes,
    );

    // Now fix the link
    newNodes = updateNodeByPath(parentPath, { firstNode: node.id }, newNodes);
  }

  return newNodes;
};

export const insertNodeAfter = (id, node, nodes) => {
  let newNodes = nodes;
  const { node: target, path } = findNodeById(newNodes, id, []);
  // add to parent's nodes
  newNodes = setNodeByPath(
    path.slice(0, path.length - 1).concat(node.id),
    {
      ...node,
      onSuccess: target.onSuccess,
    },
    newNodes,
  );

  newNodes = updateNodeByPath(path, {
    onSuccess: node.id,
  }, newNodes);

  return newNodes;
};

export const insertFirstNode = (id, node, nodes, branchId, conditionId) => {
  let newNodes = nodes;
  const { node: target, path } = findNodeById(newNodes, id, []);

  if (target?.type === 'parallel') {
    const idx = target.branches.findIndex(b => b.id === branchId);
    if (idx > -1) {
      // add to parent's nodes
      newNodes = setNodeByPath(
        path.concat('branches', idx, 'nodes', node.id),
        node,
        newNodes,
      );

      newNodes = updateNodeByPath(
        path.concat('branches', idx),
        {
          firstNode: node.id,
        },
        newNodes,
      );
    }
  } else if (target?.type === 'conditional') {
    newNodes = setNodeByPath(
      path.slice(0, path.length - 1).concat(node.id),
      node,
      newNodes,
    );

    if (conditionId === 'onNoMatch') {
      newNodes = updateNodeByPath(path, {
        onNoMatch: node.id,
      }, newNodes);
    } else {
      const idx = target.choices.findIndex(c => c.id === conditionId);
      if (idx > -1) {
        newNodes = updateNodeByPath(
          path.concat('choices', idx),
          {
            onSuccess: node.id,
          },
          newNodes,
        );
      }
    }
  } else {
    newNodes = setNodeByPath(
      path.concat('nodes', node.id),
      node,
      newNodes,
    );
    newNodes = updateNodeByPath(path, {
      firstNode: node.id,
    }, newNodes);
  }

  return newNodes;
};
