import { shallowEqual, useSelector } from 'react-redux';
import { selectCurrentOrg } from '../reducers/user';
import useActions from './useActions';
import {
  createNewWorkflow,
  loadWorkflow,
  addNode,
  removeNode,
  selectNode,
  saveWorkflow,
  updateWorkflowName,
  updateNode,
  fetchWorkflowExecutions,
  fetchExecutionEvents,
  startNewExecution,
  setCurrentExecution,
  setNodes,
  undo,
  redo,
  setEditorMode,
  selectWorkflow,
  selectSelectedNode,
  selectEditorMode,
  selectEditorView,
  selectWorkflowExecutions,
  selectCurrentExecution,
  selectDetails,
  selectUndoHistory,
  selectSelectedPath,
} from '../reducers/workflowEditor';

const actionCreators = {
  addNode,
  updateNode,
  removeNode,
  selectNode,
  loadWorkflow,
  createNewWorkflow,
  setEditorMode,
  saveWorkflow,
  updateWorkflowName,
  fetchWorkflowExecutions,
  fetchExecutionEvents,
  setCurrentExecution,
  startNewExecution,
  setNodes,
  undo,
  redo,
};

export function useWorkflowEditorActions() {
  const actions = useActions(actionCreators);

  return actions;
}

function useWorkflowEditor() {
  const orgId = useSelector(selectCurrentOrg, shallowEqual);
  const workflow = useSelector(selectWorkflow, shallowEqual);
  const selectedNode = useSelector(selectSelectedNode, shallowEqual);
  const selectedPath = useSelector(selectSelectedPath, shallowEqual);
  const mode = useSelector(selectEditorMode, shallowEqual);
  const view = useSelector(selectEditorView, shallowEqual);
  const executions = useSelector(selectWorkflowExecutions, shallowEqual);
  const currentExecution = useSelector(selectCurrentExecution, shallowEqual);
  const details = useSelector(selectDetails, shallowEqual);
  const actions = useWorkflowEditorActions();
  const undoHistory = useSelector(selectUndoHistory, shallowEqual);

  return {
    orgId,
    workflow,
    selectedNode,
    selectedPath,
    mode,
    view,
    executions,
    currentExecution,
    details,
    actions,
    undoHistory,
  };
}

export default useWorkflowEditor;
