import React from 'react';
import PropTypes from 'prop-types';
import Panel from '@x-functions/freyja/lib/components/panel';
import {
  Field,
  Label,
  Control,
  Input,
} from '@x-functions/freyja/lib/components/form';
import { workflowNodeType } from '@x-functions/x-react-workflow/lib/components/workflow/types';
import TimeInput from './timeInput';

function WaitInspector({
  groupId,
  node,
  updateNode,
}) {
  const handleValueChange = React.useCallback(e => {
    const { name, value } = e.target;
    updateNode({ groupId, id: node.id, changes: { [name]: value } });
  }, [node.id, updateNode, groupId]);

  return (
    <>
      <Panel.Block>
        <Field fullwidth>
          <Label>Step Name</Label>
          <Control fullwidth>
            <Input fullwidth name="name" id="name" value={node.name} onChange={handleValueChange} />
          </Control>
        </Field>
      </Panel.Block>
      <Panel.Header>
        Wait for
      </Panel.Header>
      <Panel.Block>
        <div>
          <Label>Time</Label>
          <TimeInput name="seconds" value={node.seconds} onChange={handleValueChange} />
        </div>
      </Panel.Block>
    </>
  );
}

WaitInspector.propTypes = {
  groupId: PropTypes.string,
  node: workflowNodeType.isRequired,
  updateNode: PropTypes.func.isRequired,
};

WaitInspector.defaultProps = {
  groupId: undefined,
};

export default WaitInspector;
