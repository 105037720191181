import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@x-functions/freyja/lib/components/button';
import Icon from '@x-functions/freyja/lib/components/icon';
import styles from './styles.module.scss';

const ZOOM_SPEED = 4;
const WHEEL_ZOOM_FACTOR = 0.05;
const WHEEL_MOVE_FACTOR = 0.0005;

function Controls({
  className,
  panzoom,
  undoHistory,
  onUndoClick,
  onRedoClick,
}) {
  const onZoomIn = React.useCallback(() => {
    if (!panzoom.current) return;

    panzoom.current.zoomIn(ZOOM_SPEED);
  }, [panzoom]);

  const onZoomOut = React.useCallback(() => {
    if (!panzoom.current) return;

    panzoom.current.zoomOut(ZOOM_SPEED);
  }, [panzoom]);

  const onReset = React.useCallback(() => {
    if (!panzoom.current) return;

    panzoom.current.reset();
    panzoom.current.autoCenter();
  }, [panzoom]);

  React.useEffect(() => {
    const el = panzoom.current.container.current;
    const handleWheelZoom = event => {
      const {
        deltaY,
        deltaX,
        metaKey,
        ctrlKey,
      } = event;
      if (ctrlKey || metaKey) {
        event.stopPropagation();
        event.preventDefault();

        const fn = deltaY < 0 ? panzoom.current.zoomIn : panzoom.current.zoomOut;
        const speed = Math.abs(deltaY) * WHEEL_ZOOM_FACTOR;
        fn(speed);
      } else {
        event.stopPropagation();
        event.preventDefault();

        panzoom.current.moveByRatio(deltaX * -1, deltaY * -1, WHEEL_MOVE_FACTOR);
      }
    };

    el.addEventListener('wheel', handleWheelZoom);

    return () => el.removeEventListener('wheel', handleWheelZoom);
  }, [panzoom]);

  return (
    <div className={classNames(styles.pad, className)}>
      <div className={styles.row}>
        <Button
          onClick={onUndoClick}
          disabled={undoHistory.index <= 0}
        >
          <Icon><i className="material-icons md-24">undo</i></Icon>
        </Button>
        <div className={styles.spacer} />
        <Button
          onClick={onRedoClick}
          disabled={undoHistory.index >= (undoHistory.limit - 1)}
        >
          <Icon><i className="material-icons md-24">redo</i></Icon>
        </Button>
      </div>
      <div className={styles.row}>
        <Button onClick={onZoomIn}><Icon><span className="fa fa-plus-square" /></Icon></Button>
        <Button onClick={onReset}><Icon><span className="fa fa-dot-circle" /></Icon></Button>
        <Button onClick={onZoomOut}><Icon><span className="fa fa-minus-square" /></Icon></Button>
      </div>
    </div>
  );
}

Controls.propTypes = {
  className: PropTypes.string,
  panzoom: PropTypes.shape({
    current: PropTypes.shape({
      zoomIn: PropTypes.func,
      zoomOut: PropTypes.func,
      moveByRatio: PropTypes.func,
      reset: PropTypes.func,
      autoCenter: PropTypes.func,
      container: PropTypes.shape({
        current: PropTypes.shape({
          addEventListener: PropTypes.func,
          removeEventListener: PropTypes.func,
        }),
      }),
    }),
  }).isRequired,
  undoHistory: PropTypes.shape({
    limit: PropTypes.number,
    index: PropTypes.number,
  }).isRequired,
  onUndoClick: PropTypes.func.isRequired,
  onRedoClick: PropTypes.func.isRequired,
};

Controls.defaultProps = {
  className: undefined,
};

export default Controls;
