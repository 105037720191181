import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { PanZoom } from 'react-easy-panzoom';
import { Canvas } from '@x-functions/x-react-workflow';
import NodeInspector from './nodeInspector';
import NodeSelectionPanel from './nodeSelectionPanel';
import WorkflowHeader from './header';
import Monitor from './monitor';
import Controls from './controls';
import WorkflowTextEditor from './textEditor';
import useWorkflowEditor from '../../../hooks/useWorkflowEditor';
import ConfirmContext from '../../../components/confirm/context';
import styles from './styles.module.scss';

function WorkflowPage({
  workflowId,
}) {
  const panzoom = React.useRef();
  const [modifyCount, setModifyCount] = React.useState(undefined);

  const {
    orgId: currentOrg,
    actions,
    workflow,
    selectedNode,
    mode,
    view,
    undoHistory,
    ...other
  } = useWorkflowEditor();

  const { confirm } = React.useContext(ConfirmContext);

  const [nodePickerState, setNodePickerState] = React.useState({ visible: false });
  const [nodeInspectorState, setNodeInspectorState] = React.useState({ visible: false });

  React.useEffect(() => {
    if (!selectedNode) {
      setNodePickerState(ps => {
        if (ps?.selectedNode && ps.selectedNode !== selectedNode && !selectedNode) {
          return { ...ps, visible: false };
        }

        return ps;
      });
    }
  }, [selectedNode]);

  React.useEffect(() => {
    if (modifyCount === 0) {
      setModifyCount(undefined);
      actions.saveWorkflow({ orgId: currentOrg, deploy: false });
    }
  }, [currentOrg, modifyCount, actions]);

  const recordChange = React.useCallback(() => {
    setModifyCount(v => (v || 0) + 1);
    setTimeout(() => setModifyCount(v => v - 1), 2000);
  }, []);

  const createAddNodeHandler = React.useCallback(type => async (id, opts) => {
    setNodeInspectorState({ visible: false });
    const pickNode = async () => new Promise(resolve => {
      setNodePickerState(s => {
        try {
          if (s?.visible && s?.onClose) s.onClose();
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log('Failed to close hanging promise:', err);
        }

        return {
          visible: true,
          addNode: (...args) => {
            setNodePickerState({ visible: false });
            resolve(...args);
          },
          onClose: () => {
            setNodePickerState({ visible: false });
            resolve({});
          },
        };
      });
    });

    const { node } = await pickNode();

    if (id && node) {
      actions.addNode({
        id,
        node,
        ...(opts || {}),
        type,
      });
      setModifyCount(v => (v || 0) + 1);
      setTimeout(() => setModifyCount(v => v - 1), 2000);
    }
  }, [actions]);

  const handleNodeClick = React.useCallback(id => {
    actions.selectNode({ id });
    setNodePickerState(s => {
      if (s.visible && s.onClose) s.onClose();

      return { ...s, visible: false };
    });
    setNodeInspectorState({ visible: true, selectedNode: id });
  }, [actions]);

  const handleNodeRemoveClick = React.useCallback(async id => {
    if (await confirm('Remove node from workflow', 'Are you sure you want to remove this node?')) {
      actions.selectNode({ id: '' });
      setNodeInspectorState({ visible: false, selectNode: '' });
      actions.removeNode({ id });
      setModifyCount(v => (v || 0) + 1);
      setTimeout(() => setModifyCount(v => v - 1), 2000);
    }
  }, [confirm, actions]);

  const handleInspectorClose = React.useCallback(() => {
    actions.selectNode({ id: '' });
    setNodeInspectorState({ visible: false, selectedNode: '' });
  }, [actions]);

  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    if (!currentOrg || !workflowId) return;

    actions.loadWorkflow({ orgId: currentOrg, workflowId }).then(() => setLoaded(true)).catch(() => setLoaded(true));
  }, [currentOrg, workflowId, actions]);

  const handleNodeUpdated = React.useCallback(({ id, changes }) => {
    actions.updateNode({ id, changes });
    setModifyCount(v => (v || 0) + 1);
    setTimeout(() => setModifyCount(v => v - 1), 2000);
  }, [actions]);

  const handleUndo = React.useCallback(() => {
    actions.undo();
    setModifyCount(v => (v || 0) + 1);
    setTimeout(() => setModifyCount(v => v - 1), 2000);
  }, [actions]);

  const handleRedo = React.useCallback(() => {
    actions.redo();
    setModifyCount(v => (v || 0) + 1);
    setTimeout(() => setModifyCount(v => v - 1), 2000);
  }, [actions]);

  if (!loaded) {
    return 'Loading...';
  }

  const blockNameUpdates = !!(workflowId && workflowId !== 'new');

  return (
    <div className={styles.root}>
      <WorkflowHeader blockNameUpdates={blockNameUpdates} mode={mode} view={view} recordChange={recordChange} />
      <div className={styles.body}>
        {nodePickerState?.visible ? (
          <NodeSelectionPanel {...nodePickerState} className={classNames(styles.rightCol, styles.expanded)} />
        ) : null}
        {nodeInspectorState.visible ? (
          <NodeInspector
            id={nodeInspectorState.selectedNode}
            mode={mode}
            {...other}
            {...actions}
            updateNode={handleNodeUpdated}
            workflow={workflow}
            className={classNames(styles.rightCol, styles.expanded)}
            onClose={handleInspectorClose}
          />
        ) : null}
        {mode === 'monitor' ? (
          <Monitor mode={mode} blockNameUpdates={blockNameUpdates} {...other} className={classNames(styles.leftCol, styles.expanded)} />
        ) : null}
        {view === 'tree' ? (
          <Controls
            panzoom={panzoom}
            className={classNames(styles.controls, styles.rightCol, styles.expanded)}
            undoHistory={undoHistory}
            onUndoClick={handleUndo}
            onRedoClick={handleRedo}
          />
        ) : null}
        {view === 'tree' ? (
          <div className={styles.workflow}>
            <PanZoom
              ref={panzoom}
              autoCenter
              disableScrollZoom
              boundaryRatioVertical={0.8}
              boundaryRatioHorizontal={0.8}
              enableBoundingBox
              style={{
                outline: 'none',
              }}
            >
              <Canvas
                addNode={actions.addNode}
                onInEndpointClick={createAddNodeHandler('before')}
                onOutEndpointClick={createAddNodeHandler('after')}
                onFirstNodeEndpointClick={createAddNodeHandler('firstNode')}
                readonly={mode === 'monitor'}
                {...other}
                {...actions}
                onNodeClick={handleNodeClick}
                onNodeRemoveClick={handleNodeRemoveClick}
                workflow={workflow}
                onNodeUpdated={handleNodeUpdated}
                selectedNode={selectedNode}
              />
            </PanZoom>
          </div>
        ) : (
          <div className={styles.jsonEditor}>
            <WorkflowTextEditor workflow={workflow} onChange={actions.setNodes} />
          </div>
        )}
      </div>
    </div>
  );
}

WorkflowPage.propTypes = {
  currentOrg: PropTypes.string.isRequired,
  workflowId: PropTypes.string.isRequired,
  loadWorkflow: PropTypes.func.isRequired,
  addNode: PropTypes.func.isRequired,
  updateNode: PropTypes.func.isRequired,
};

export default WorkflowPage;
