import React from 'react';
import classNames from 'classnames';
import Heading from '@x-functions/freyja/lib/components/heading';
import Tile from './tile';
import loadingStyles from '../loadingStyles/styles.module.scss';

function LoadingTile() {
  return (
    <Tile className={loadingStyles.loading}>
      <Heading className={classNames(loadingStyles.loading, loadingStyles.bar)}>Loading...</Heading>
      <Heading subtitle className={classNames(loadingStyles.loading, loadingStyles.bar)}>Loading content...</Heading>
    </Tile>
  );
}

export default LoadingTile;
