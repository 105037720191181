import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import BNavbar from '@x-functions/freyja/lib/components/navbar';
import Container from '@x-functions/freyja/lib/components/container';
import Progress from '@x-functions/freyja/lib/components/progress';
import logo from './header-logo.png';
import useAxiosLoadingInterceptor from '../../hooks/useAxiosLoadingInterceptor';
import styles from './styles.module.scss';

function Navbar() {
  const [active, setActive] = React.useState(false);
  const isAxiosLoading = useAxiosLoadingInterceptor();

  return (
    <BNavbar fixed="top" color="black" active={active}>
      <Container fluid>
        <BNavbar.Brand>
          <BNavbar.Item renderAs={Link} to="/">
            <img src={logo} alt="X" />
          </BNavbar.Item>
          <BNavbar.Burger onClick={() => setActive(!active)} />
        </BNavbar.Brand>
        <BNavbar.Menu>
          <BNavbar.Container position="start">
            <BNavbar.Item renderAs={Link} to="/organization" className={styles.link}>
              Organization
            </BNavbar.Item>
            <BNavbar.Item renderAs={Link} to="/analytics" className={styles.link}>
              Analytics
            </BNavbar.Item>
            <BNavbar.Item renderAs={Link} to="/query" className={styles.link}>
              Query
            </BNavbar.Item>
            <BNavbar.Item renderAs={Link} to="/functions" className={styles.link}>
              Functions
            </BNavbar.Item>
            <BNavbar.Item renderAs={Link} to="/workflows" className={styles.link}>
              Workflows
            </BNavbar.Item>
            <BNavbar.Item renderAs={Link} to="/audience" className={styles.link}>
              Audience
            </BNavbar.Item>
            <BNavbar.Item renderAs={Link} to="/content" className={styles.link}>
              Content
            </BNavbar.Item>
            <BNavbar.Item renderAs={Link} to="/dashboards" className={styles.link}>
              Dashboards
            </BNavbar.Item>
          </BNavbar.Container>
        </BNavbar.Menu>
      </Container>
      { isAxiosLoading && <Progress size="small" className={styles.globalAxiosProgress} /> }
    </BNavbar>
  );
}

Navbar.propTypes = {
  roles: PropTypes.shape({}).isRequired,
};

export default Navbar;
