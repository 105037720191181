import React from 'react';
import PropTypes from 'prop-types';
import Text from '../jsonEditor/text';

function Json({
  schema,
  values,
  setValues,
}) {
  const [strValue, setStrValue] = React.useState('');

  React.useEffect(() => {
    setStrValue(v => {
      if (v) return v;

      return JSON.stringify(values || {}, null, 2);
    });
  }, [values]);

  React.useEffect(() => {
    if (!setValues) return;

    try {
      const jsonValue = JSON.parse(strValue);
      const modified = JSON.stringify(jsonValue) !== JSON.stringify(values);
      if (modified) setValues(jsonValue);
    } catch (err) {
      // Value has errors - just ignore
    }
  }, [strValue, setValues, values]);

  return (
    <Text
      theme="vs-dark"
      value={strValue}
      onChange={setStrValue}
      schema={schema}
      options={{ minimap: { enabled: false } }}
      height="300px"
    />
  );
}

Json.propTypes = {
  schema: PropTypes.shape({
    properties: PropTypes.shape({}),
  }),
  values: PropTypes.shape({}).isRequired,
  setValues: PropTypes.func,
};

Json.defaultProps = {
  schema: undefined,
  setValues: undefined,
};

export default Json;
