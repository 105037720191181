import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@x-functions/freyja/lib/components/modal';
import Button from '@x-functions/freyja/lib/components/button';
import Fields from './fields';

function FieldPickerModal({ show, onClose, nodes }) {
  const handleFieldClick = React.useCallback(fieldPath => {
    onClose(fieldPath);
  }, [onClose]);

  const handleCancel = React.useCallback(() => {
    onClose();
  }, [onClose]);

  const [node, ...ancestors] = nodes;

  return (
    <Modal show={show}>
      <Modal.Card>
        <Modal.Card.Head onClose={handleCancel}>
          <Modal.Card.Title>Field Selector (Current node: {node.name})</Modal.Card.Title>
        </Modal.Card.Head>
        <Modal.Card.Body>
          {ancestors.length < 1 && (
            <p>No ancestors found. Is this node connected to the workflow?</p>
          )}
          {ancestors.map(n => (
            <Fields key={n.id} node={n} onClick={handleFieldClick} />
          ))}
        </Modal.Card.Body>
        <Modal.Card.Foot>
          <Button onClick={handleCancel}>Cancel</Button>
        </Modal.Card.Foot>
      </Modal.Card>
    </Modal>
  );
}

FieldPickerModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  nodes: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })),
};

FieldPickerModal.defaultProps = {
  nodes: [],
};

export default FieldPickerModal;
