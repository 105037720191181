import React from 'react';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';
import Panel from '@x-functions/freyja/lib/components/panel';
import Tabs from '@x-functions/freyja/lib/components/tabs';
import FunctionList from './myFunctions';
import Catalog from './catalog';

const mainTabs = [{
  key: 'functions',
  label: 'My functions',
  Component: FunctionList,
}, {
  key: 'marketplace',
  label: 'Marketplace',
  Component: Catalog,
}];


function FunctionCatalog({
  currentOrg,
  fetchOrganizationFunctions,
  className,
  children,
  ...rest
}) {
  const [tabIndex, setTabIndex] = React.useState(0);

  React.useEffect(() => {
    if (!currentOrg) return;
    if (tabIndex !== 0) return;

    fetchOrganizationFunctions({ orgId: currentOrg, scope: 'private,public' });
  }, [currentOrg, fetchOrganizationFunctions, tabIndex]);

  const handleDragStart = React.useCallback((f, e) => {
    const id = v4();
    e.dataTransfer.setData('text/node', JSON.stringify({
      id,
      name: f.functionName,
      type: 'function',
      function: `${f.orgId}/${f.functionName}`,
      input: {},
      output: `$.result.${id}`,
    }));
  }, []);

  const CatalogBody = mainTabs[tabIndex].Component;

  return (
    <Panel className={className}>
      {children}
      <Panel.Header>
        Function Catalog
      </Panel.Header>
      <Panel.Block>
        <Tabs type="toggle">
          {mainTabs.map((t, i) => (
            <Tabs.Tab
              key={t.key}
              active={i === tabIndex}
              onClick={() => setTabIndex(i)}
            >
              {t.label}
            </Tabs.Tab>
          ))}
        </Tabs>
      </Panel.Block>
      <CatalogBody {...rest} onDragStart={handleDragStart} />
    </Panel>
  );
}

FunctionCatalog.propTypes = {
  currentOrg: PropTypes.string.isRequired,
  fetchOrganizationFunctions: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
};

FunctionCatalog.defaultProps = {
  className: null,
  children: null,
};

export default FunctionCatalog;
