import React from 'react';
import PropTypes from 'prop-types';
import Panel from '@x-functions/freyja/lib/components/panel';
import Content from '@x-functions/freyja/lib/components/content';
import Button from '@x-functions/freyja/lib/components/button';
import {
  Field,
  Label,
  Control,
  Input,
  Textarea,
} from '@x-functions/freyja/lib/components/form';
import { workflowNodeType } from '@x-functions/x-react-workflow/lib/components/workflow/types';
import FieldPickerInput from '../fieldPicker';
import FormFromSchema from '../../../../../components/formFromSchema';
import { getSchemaTypeDescription, getNewObjectWithDefaults } from '../../../../../utils/jsonSchemas';
import useFunctionDetails from '../../../../../hooks/useFunctionDetails';
import styles from './styles.module.scss';

function FunctionInspector({
  groupId,
  node,
  updateNode,
}) {
  const [orgId, functionName] = node && node.function && node.function.split('/');
  const [schema, setSchema] = React.useState({});
  const [showAdvanced, setShowAdvanced] = React.useState(false);

  const {
    functionDetails,
    prodVersion,
    actions,
  } = useFunctionDetails(functionName, orgId);

  React.useEffect(() => {
    if (!orgId || !functionName) return;

    actions.fetchFunctionDetails({ orgId, functionName });
  }, [actions, orgId, functionName]);

  const inputSchema = prodVersion?.inputSchema;
  React.useEffect(() => {
    if (!inputSchema) return;

    setSchema(JSON.parse(inputSchema));
  }, [inputSchema]);

  const input = node?.input;
  React.useEffect(() => {
    if (!schema) return;
    if (input && Object.keys(input).length) return;

    const newInputWithDefaults = getNewObjectWithDefaults(schema);
    if (Object.keys(newInputWithDefaults).length) {
      updateNode({
        groupId,
        id: node.id,
        changes: {
          input: {
            ...input,
            ...newInputWithDefaults,
          },
        },
      });
    }
  }, [schema, groupId, node.id, input, updateNode]);

  const handleValueChange = React.useCallback(e => {
    const { name, value } = e.target;
    updateNode({ groupId, id: node.id, changes: { [name]: value } });
  }, [node.id, updateNode, groupId]);

  const handleInputValueChange = React.useCallback(e => {
    const { name, value } = e.target;
    updateNode({
      groupId,
      id: node.id,
      changes: {
        input: {
          ...node.input,
          [name]: value,
        },
      },
    });
  }, [node.id, node.input, updateNode, groupId]);

  return (
    <>
      <Panel.Block>
        <Field className={styles.field}>
          <Label>Step Name</Label>
          <Control fullwidth>
            <Input fullwidth name="name" id="name" value={node.name} onChange={handleValueChange} />
          </Control>
        </Field>
      </Panel.Block>
      <Panel.Block>
        <Content>
          <p>
            <strong>Function</strong><br />
            <small>{`${orgId}/${functionDetails?.displayName}`}</small>
          </p>
        </Content>
      </Panel.Block>
      <Panel.Block style={{ flexDirection: 'column', alignItems: 'unset' }}>
        <FormFromSchema
          longForm
          noJson
          schema={JSON.parse(prodVersion?.inputSchema || '{}')}
          values={node.input}
          setValue={handleInputValueChange}
          fieldClassName={styles.field}
          InputComponent={FieldPickerInput}
          inputProps={{ currentNodeId: node.id }}
          ignoreJsonPaths
          forwardInputSchema
        />
      </Panel.Block>
      <Panel.Block style={{ flexDirection: 'column' }}>
        <div>
          <Content>
            <p><strong>Output</strong></p>
          </Content>
        </div>
        <Field className={styles.field}>
          <Label>Content</Label>
          <Control fullwidth>
            <Textarea
              fullwidth
              name="output"
              id="output"
              value={getSchemaTypeDescription(JSON.parse(prodVersion?.outputSchema || '{}'))}
              readOnly
            />
          </Control>
        </Field>
        {showAdvanced ? (
          <Field className={styles.field}>
            <Label>Path</Label>
            <Control fullwidth>
              <Input fullwidth name="output" id="output" value={node.output} onChange={handleValueChange} />
            </Control>
          </Field>
        ) : null}
        <Button className="is-ghost" onClick={() => setShowAdvanced(a => !a)}>{showAdvanced ? 'Hide' : 'Show'} advanced</Button>
      </Panel.Block>
    </>
  );
}

FunctionInspector.propTypes = {
  groupId: PropTypes.string,
  node: workflowNodeType.isRequired,
  updateNode: PropTypes.func.isRequired,
};

FunctionInspector.defaultProps = {
  groupId: undefined,
};

export default FunctionInspector;
