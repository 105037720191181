import React from 'react';
import PropTypes from 'prop-types';
import WebhookFields from './webhook';
import ScheduleFields from './schedule';
import PinpointEventFields from './pinpointEvent';

function EventNodeFields({ node, onClick }) {
  if (!node.eventType) return <p>No event type defined - please configure it</p>;

  switch (node.eventType) {
    case 'webhook':
      return <WebhookFields node={node} webhookId={node.webhookId} onClick={onClick} />;
    case 'schedule':
      return <ScheduleFields node={node} onClick={onClick} />;
    case 'event':
      return <PinpointEventFields node={node} onClick={onClick} />;
    default:
      return <p>Event Node Fields</p>;
  }
}

EventNodeFields.propTypes = {
  node: PropTypes.shape({
    eventType: PropTypes.string,
    webhookId: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default EventNodeFields;
