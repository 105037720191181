import React from 'react';
import PropTypes from 'prop-types';
import Panel from '@x-functions/freyja/lib/components/panel';
import Button from '@x-functions/freyja/lib/components/button';
import {
  Field,
  Label,
  Control,
  Input,
  Select,
} from '@x-functions/freyja/lib/components/form';
import DatePicker from '../../../../../components/datepicker';
import styles from './filter.module.scss';

const filterDataTypes = [{
  value: 'number',
  label: 'Number',
}, {
  value: 'string',
  label: 'Text',
}, {
  value: 'date',
  label: 'Date',
}];

const conditions = {
  default: {
    equalTo: '=',
    notEqualTo: '<>',
    greaterThan: '>',
    greaterOrEqualTo: '≥',
    lessThan: '<',
    lessOrEqualTo: '≤',
    notSet: 'empty',
  },
  string: {
    regexMatch: 'match regex',
  },
};

const getConditionsForDataType = dataType => {
  const all = {
    ...conditions.default,
    ...(conditions[dataType] || {}),
  };

  return Object.keys(all).map(k => {
    const label = all[k];
    return { value: k, label };
  });
};

function Filter({
  filter,
  onFilterChange,
  onFilterDelete,
  showMergeOption,
}) {
  return (
    <Panel.Block
      key={filter.id}
      className={styles.root}
    >
      <Button remove className={styles.remove} onClick={onFilterDelete} />
      <Label>Field path</Label>
      <Input
        fullwidth
        name="variable"
        id="variable"
        value={filter.variable}
        onChange={onFilterChange}
      />
      <Label>Comparison</Label>
      <Field fullwidth kind="addons">
        <Control>
          <Select name="dataType" id="dataType" value={filter.dataType} onChange={onFilterChange}>
            {filterDataTypes.map(dataType => (
              <option key={dataType.value} value={dataType.value}>{dataType.label}</option>
            ))}
          </Select>
        </Control>
        <Control>
          <Select name="condition" id="condition" value={filter.condition} onChange={onFilterChange}>
            {getConditionsForDataType(filter.dataType).map(c => (
              <option key={c.value} value={c.value}>{c.label}</option>
            ))}
          </Select>
        </Control>
      </Field>
      <Field>
        <Label>Value</Label>
        <Control>
          {filter.dataType === 'Date' && (
            <DatePicker value={filter.value} onChange={onFilterChange} name="value" id="value" />
          )}
          {filter.dataType !== 'Date' && (
            <Input
              fullwidth
              type={filter.dataType === 'Numeric' ? 'number' : 'text'}
              name="value"
              id="value"
              value={filter.value}
              onChange={onFilterChange}
            />
          )}
        </Control>
      </Field>
      {showMergeOption && (
        <Field>
          <Control>
            <Select name="mergeLogic" id="mergeLogic" value={filter.mergeLogic} onChange={onFilterChange}>
              <option value="and">And</option>
              <option value="or">Or</option>
            </Select>
          </Control>
        </Field>
      )}
    </Panel.Block>
  );
}

Filter.propTypes = {
  filter: PropTypes.shape({
    id: PropTypes.string.isRequired,
    variable: PropTypes.string,
    dataType: PropTypes.string,
    condition: PropTypes.string,
    mergeLogic: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onFilterDelete: PropTypes.func.isRequired,
  showMergeOption: PropTypes.bool,
};

Filter.defaultProps = {
  showMergeOption: false,
};

export default Filter;
