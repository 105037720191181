import React from 'react';
import Panel from '@x-functions/freyja/lib/components/panel';
import { workflowNodeType } from '@x-functions/x-react-workflow/lib/components/workflow/types';
import WebhookSelector from './webhookSelector';
import WebhookDetails from '../../../../../components/webhookDetails';
import styles from './styles.module.scss';

function WebhookInspector({ node, ...otherProps }) {
  return (
    <Panel.Block>
      {!node.webhookId && (
        <WebhookSelector node={node} {...otherProps} />
      )}
      {node.webhookId && (
        <WebhookDetails className={styles.webhookDetails} node={node} webhookId={node.webhookId} {...otherProps} />
      )}
    </Panel.Block>
  );
}

WebhookInspector.propTypes = {
  node: workflowNodeType.isRequired,
};

export default WebhookInspector;
