import React from 'react';
import PropTypes from 'prop-types';
import Element from '@x-functions/freyja/lib/components/element';
import classNames from 'classnames';

function SearchInput({
  searchResults,
  ...props
}) {
  const [focused, setFocused] = React.useState(false);

  const handleFocus = React.useCallback(() => {
    setFocused(true);
  }, []);

  const handleBlur = React.useCallback(() => {
    setTimeout(() => setFocused(false), 300);
  }, []);

  return (
    <div className={classNames('dropdown', { 'is-active': focused })}>
      <div className="dropdown-trigger">
        <p className="control is-expanded has-icons-right">
          <input className="input" type="search" {...props} onFocus={handleFocus} onBlur={handleBlur} />
          <span className="icon is-small is-right"><i className="fas fa-search" /></span>
        </p>
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className="dropdown-content">
          {searchResults ? searchResults.map(r => (
            <Element
              renderAs={r.renderAs || (r.divider ? 'hr' : 'a')}
              className={classNames({ 'dropdown-item': !r.divider, 'dropdown-divider': !!r.divider }, r.className)}
              key={r.id}
              onClick={r.onClick}
            >
              {r.label}
            </Element>
          )) : (
            <p className="dropdown-item">No results</p>
          )}
        </div>
      </div>
    </div>
  );
}

SearchInput.propTypes = {
  searchResults: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    renderAs: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    className: PropTypes.string,
    onClick: PropTypes.func,
    label: PropTypes.node,
    divider: PropTypes.bool,
  })),
};

SearchInput.defaultProps = {
  searchResults: undefined,
};

export default SearchInput;
