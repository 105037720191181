import React from 'react';
import PropTypes from 'prop-types';
import AuthLayout from '../auth/layout';
import { setAuthData, getAuthData } from '../../utils/auth';
import useUserRoles from '../../hooks/useUserRoles';

function OrgGuard({ children }) {
  const [loading, setLoading] = React.useState(true);
  const [hasRoles, setHasRoles] = React.useState(false);
  const { roles, actions } = useUserRoles();

  const handleRequestToken = React.useCallback(() => new Promise(resolve => {
    const handleResponse = evt => {
      if (evt?.data?.type !== 'xf-auth-response') return;

      window.removeEventListener('message', handleResponse);
      return resolve(evt.data.payload);
    };

    if (window.addEventListener) {
      window.addEventListener("message", handleResponse, false);
    }
    else {
      window.attachEvent("onmessage", handleResponse);
    }

    // eslint-disable-next-line no-restricted-globals
    parent.postMessage({ type: 'xf-auth' }, '*');
  }), []);

  React.useEffect(() => {
    setHasRoles(!!(Object.values(roles || {}).length));
  }, [roles]);

  React.useEffect(() => {
    let timer;
    if (!hasRoles) {
      const checkRoles = async () => {
        const authData = await handleRequestToken();
        setAuthData(authData);
        if (!(await getAuthData())) {
          timer = setTimeout(checkRoles, 3000);
        }
      }
      checkRoles();
    } else {
      setLoading(false);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [hasRoles, actions, handleRequestToken]);

  if (!hasRoles) {
    return (
      <AuthLayout
        title={loading ? 'Authenticating' : 'Invalid authentication'}
        subtitle={loading ? 'Fetching credentials' : 'Could not fetch credentials'}
        hideSeparator
      />
    );
  }

  return (
    <>
      {children}
    </>
  );
}

OrgGuard.propTypes = {
  children: PropTypes.node,
};

OrgGuard.defaultProps = {
  children: null,
};

export default OrgGuard;
