import React from 'react';
import PropTypes from 'prop-types';
import Menu from '@x-functions/freyja/lib/components/menu';
import useWebhook from '../../../../../../../../hooks/useWebhook';
import { getSchemaTypeDefinitions } from '../../../../../../../../utils/jsonSchemas';

function WebhookFields({ webhookId, onClick }) {
  const { webhook } = useWebhook(webhookId);

  if (!webhookId) {
    return <p>Webhook not configured</p>;
  }

  if (!webhook) {
    return <p>Loading webhook...</p>;
  }

  const schema = JSON.parse(webhook.schema);
  const properties = getSchemaTypeDefinitions(schema);

  return (
    <Menu>
      <Menu.List title="Event - webhook">
        {properties.map(p => (
          <Menu.List.Item key={p.name} onClick={() => onClick(`$.${p.name}`)}>
            {`${p.name}: ${p.type}${p.required ? ' (required)' : ''}`}
          </Menu.List.Item>
        ))}
      </Menu.List>
    </Menu>
  );
}

WebhookFields.propTypes = {
  webhookId: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

WebhookFields.defaultProps = {
  webhookId: '',
};

export default WebhookFields;
