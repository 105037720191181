import React from 'react';
import PropTypes from 'prop-types';
import Panel from '@x-functions/freyja/lib/components/panel';
import {
  Field,
  Label,
  Control,
  Input,
  Select,
} from '@x-functions/freyja/lib/components/form';
import { workflowNodeType } from '@x-functions/x-react-workflow/lib/components/workflow/types';
import RateEditor from './rateEditor';
import styles from './styles.module.scss';

function ScheduleInspector({ node, updateNode }) {
  const scheduleExpression = node.scheduleExpression || '';
  const scheduleType = node.scheduleType || 'rate';

  const handleValueChange = React.useCallback(e => {
    const { target: { name, value } } = e;
    updateNode({ id: node.id, changes: { [name]: value } });
  }, [node.id, updateNode]);

  const handleExpressionChange = React.useCallback(value => {
    updateNode({ id: node.id, changes: { scheduleExpression: value } });
  }, [node.id, updateNode]);

  React.useEffect(() => {
    // If no schedule type selected, default to rate
    if (!node.scheduleType) {
      updateNode({ id: node.id, changes: { scheduleType: 'rate' } });
      return;
    }

    // Check if we switched between schedule types and ended up with an invalid expression
    const wrongRate = node.scheduleType === 'rate' && !scheduleExpression.includes('rate(');
    const wrongCron = node.scheduleType === 'cron' && scheduleExpression.includes('rate(');
    if (wrongRate || wrongCron) {
      handleExpressionChange('');
    }
  }, [node.scheduleType, scheduleExpression, handleExpressionChange, node.id, updateNode]);

  return (
    <Panel.Block className={styles.column}>
      <Field className={styles.field}>
        <Label>Schedule Type</Label>
        <Control>
          <Select value={scheduleType || 'rate'} name="scheduleType" onChange={handleValueChange}>
            <option value="rate">Rate</option>
            <option value="cron">Cron Expression</option>
          </Select>
        </Control>
      </Field>
      {scheduleType === 'rate' && <RateEditor value={scheduleExpression} onChange={handleExpressionChange} />}
      {scheduleType !== 'rate' && (
        <Field className={styles.field}>
          <Control>
            <Input
              type="text"
              placeholder="0/10 * ? * MON-FRI *"
              name="scheduleExpression"
              value={scheduleExpression}
              onChange={handleValueChange}
            />
          </Control>
        </Field>
      )}
    </Panel.Block>
  );
}

ScheduleInspector.propTypes = {
  node: workflowNodeType.isRequired,
  updateNode: PropTypes.func.isRequired,
};

export default ScheduleInspector;
