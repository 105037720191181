import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Heading from '@x-functions/freyja/lib/components/heading';
import Header from './components/header';
import RichEditor from './components/richEditor';
import TextEditor from './components/textEditor';
import TemplateTypePicker from './components/templateTypePicker';
import useTemplateDetails from '../../../hooks/useTemplateDetails';
import styles from './styles.module.scss';

const initialValues = {
  templateId: '',
  template: '',
  type: '',
};

function EditTemplate({
  templateId = '',
  history,
}) {
  const {
    orgId: currentOrg,
    template,
    actions,
  } = useTemplateDetails(templateId);

  const [loading, setLoading] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [values, setValues] = React.useState(initialValues);

  React.useEffect(() => {
    if (!templateId || !currentOrg) return;
    if (templateId === 'new') return;

    (async () => {
      try {
        setLoading(true);
        await actions.fetchTemplate({ orgId: currentOrg, templateId });
      } finally {
        setLoading(false);
      }
    })();
  }, [currentOrg, templateId, actions]);

  React.useEffect(() => {
    if (template && template.templateId) {
      setValues({
        ...template,
        jsonTemplate: typeof template.jsonTemplate === 'string' ? JSON.parse(template.jsonTemplate) : undefined,
      });
    }
  }, [template]);

  const handleValueChange = React.useCallback(({ target: { name, value } }) => {
    setValues(val => ({
      ...val,
      [name]: value,
    }));
  }, []);

  const handleTemplateTypeSelected = React.useCallback(type => {
    setValues(val => ({
      ...val,
      type,
    }));
  }, []);

  const handleCancel = React.useCallback(() => {
    history.push('/content');
  }, [history]);

  const handleSave = React.useCallback(async (jsonTemplate, htmlTemplate) => {
    if (!values.templateId) {
      return window.alert('Template ID is mandatory');
    }

    setSaving(true);
    try {
      const type = template.type || values.type;
      const newValues = {
        ...values,
        ...(type !== 'pureText'
          ? { jsonTemplate, template: htmlTemplate }
          : {}),
        orgId: currentOrg,
      };
      await actions.saveTemplate(newValues);
      history.push('/content');
    } catch (err) {
      window.alert(`Failed to save template: ${err.message}`);
    } finally {
      setSaving(false);
    }
    return undefined;
  }, [currentOrg, values, actions, history, template.type]);

  const isNew = !templateId || (templateId === 'new');
  const isValid = !!(
    values.templateId
    && (values.type !== 'pureText' || values.template)
  );

  const handleDelete = React.useCallback(async () => {
    if (isNew) {
      return;
    }
    if (!window.confirm('Are you sure you want to delete this template? If it is being used by any workflows, executions will fail.')) {
      return;
    }

    setSaving(true);
    try {
      await actions.deleteTemplate({ orgId: currentOrg, templateId });
      history.push('/content');
    } catch (err) {
      window.alert(`Failed to delete template: ${err.message}`);
    } finally {
      setSaving(false);
    }
  }, [currentOrg, templateId, actions, history, isNew]);

  if (loading) {
    return <Heading subtitle>Loading...</Heading>;
  }

  if (!(template && template.type) && !values.type) {
    return (
      <TemplateTypePicker onSelect={handleTemplateTypeSelected} />
    );
  }

  return (
    <div className={styles.root}>
      <Header isNew={isNew} values={values} setValues={setValues} onChange={handleValueChange} />
      <div className={styles.editor}>
        {values.type === 'pureText' && (
          <TextEditor
            templateId={templateId}
            value={values.template}
            onChange={handleValueChange}
            className={styles.bee}
            saving={saving}
            isValid={isValid}
            onSave={handleSave}
            onCancel={handleCancel}
            onDelete={handleDelete}
          />
        )}
        {values.type && values.type !== 'pureText' && (
          <RichEditor
            editorType={values.type}
            templateId={templateId}
            jsonTemplate={values.jsonTemplate}
            className={styles.bee}
            saving={saving}
            isValid={isValid}
            onSave={handleSave}
            onCancel={handleCancel}
            onDelete={handleDelete}
          />
        )}
      </div>
    </div>
  );
}

EditTemplate.propTypes = {
  templateId: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

EditTemplate.defaultProps = {
  templateId: '',
};

export default withRouter(EditTemplate);
