import React from 'react';
import PropTypes from 'prop-types';
import Section from '@x-functions/freyja/lib/components/section';
import Heading from '@x-functions/freyja/lib/components/heading';
import {
  MainActionTile,
  TileContainer,
} from '../../../../components/tiles';

function TemplateTypePicker({
  onSelect,
}) {
  return (
    <Section>
      <Heading>Choose a template type</Heading>
      <TileContainer>
        <MainActionTile title="Text" icon="comments" onClick={() => onSelect('pureText')} />
        <MainActionTile title="Email" icon="envelope" onClick={() => onSelect('email')} />
        <MainActionTile title="Page" icon="file-alt" onClick={() => onSelect('page')} />
      </TileContainer>
    </Section>
  );
}

TemplateTypePicker.propTypes = {
  onSelect: PropTypes.func.isRequired,
};

TemplateTypePicker.defaultProps = {};

export default TemplateTypePicker;
