import React from 'react';
import { Switch, Route } from 'react-router-dom';
import List from './list';
import Editor from './editor';
import useWorkflowsSubscription from '../../hooks/useOrgWorkflowsSubscription';

function Workflows() {
  useWorkflowsSubscription();

  return (
    <div>
      <Switch>
        <Route path="/workflows/new">
          <Editor workflowId="new" />
        </Route>
        <Route
          path="/workflows/:workflowId"
          render={({ match }) => (
            <Editor workflowId={match.params.workflowId} />
          )}
        />
        <Route path="/workflows">
          <List />
        </Route>
      </Switch>
    </div>
  );
}

export default Workflows;
