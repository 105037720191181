import { shallowEqual, useSelector } from 'react-redux';
import { selectCurrentOrg } from '../reducers/user';
import useActions from './useActions';
import {
  fetchOrgWorkflows,
  deleteWorkflow,
  selectCurrentOrgWorkflows,
} from '../reducers/orgWorkflows';

const actionCreators = {
  fetchOrgWorkflows,
  deleteWorkflow,
};

export function useOrgWorkflowsActions() {
  const actions = useActions(actionCreators);

  return actions;
}

function useOrgWorkflows() {
  const orgId = useSelector(selectCurrentOrg, shallowEqual);
  const { data: workflows, nextToken } = useSelector(selectCurrentOrgWorkflows, shallowEqual);
  const actions = useOrgWorkflowsActions();

  return {
    orgId,
    workflows,
    nextToken,
    actions,
  };
}

export default useOrgWorkflows;
