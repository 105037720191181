import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Heading from '@x-functions/freyja/lib/components/heading';
import Button from '@x-functions/freyja/lib/components/button';
import Icon from '@x-functions/freyja/lib/components/icon';
import styles from './styles.module.scss';

function Sidebar({
  className,
  open,
  onClose,
  title,
  subtitle,
  buttonIcon,
  onButtonClick,
  children,
}) {
  if (!open) {
    return null;
  }

  return (
    <div className={classNames(styles.drawer, className)} onClose={onClose}>
      <div className={styles.drawerBody}>
        <div>
          <div className="pt-1 pb-1 pl-4 pr-4 is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center">
            <Heading size={6} className="mb-0">{title}</Heading>
            <Button color="white" size="small" onClick={onButtonClick}>
              <Icon><i className="material-icons md-24">{buttonIcon}</i></Icon>
            </Button>
          </div>
          {subtitle}
        </div>
        {children}
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.node,
  children: PropTypes.node,
  buttonIcon: PropTypes.string,
  onButtonClick: PropTypes.func.isRequired,
};

Sidebar.defaultProps = {
  className: undefined,
  subtitle: null,
  children: null,
  buttonIcon: 'add',
};

export default Sidebar;
