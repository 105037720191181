import { shallowEqual, useSelector } from 'react-redux';
import { selectCurrentOrg } from '../reducers/user';
import useActions from './useActions';
import {
  fetchOrganizationFunctions,
  createOrganizationFunction,
  updateOrganizationFunction,
  updateOrganizationFolders,
  updateOrganizationFunctionFolder,
  deleteOrganizationFunction,
  expandFolder,
  collapseFolder,
  expandFunctionPath,
  setExpandedFolders,
  addExpandedFolders,
  selectOrganizationFunctions,
} from '../reducers/orgFunctions';

const builderActionCreators = {
  fetchOrganizationFunctions,
  createOrganizationFunction,
  updateOrganizationFunction,
  deleteOrganizationFunction,
  updateOrganizationFolders,
  setExpandedFolders,
  addExpandedFolders,
  expandFolder,
  collapseFolder,
  expandFunctionPath,
  updateOrganizationFunctionFolder,
};

export function useOrgFunctionsActions() {
  const actions = useActions(builderActionCreators);

  return actions;
}

const emptyArray = [];

function useOrgFunctions() {
  const orgId = useSelector(selectCurrentOrg, shallowEqual);
  const {
    data: functions,
    folders,
    expandedFolders,
    nextToken,
  } = useSelector(state => selectOrganizationFunctions(state, orgId), shallowEqual);
  const actions = useOrgFunctionsActions();

  return {
    orgId,
    functions: functions || emptyArray,
    folders: folders || emptyArray,
    expandedFolders: expandedFolders || emptyArray,
    nextToken,
    actions,
  };
}

export default useOrgFunctions;
