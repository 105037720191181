import React from 'react';
import PropTypes from 'prop-types';
import Panel from '@x-functions/freyja/lib/components/panel';
import {
  Field,
  Label,
  Control,
  Input,
} from '@x-functions/freyja/lib/components/form';
import { workflowNodeType } from '@x-functions/x-react-workflow/lib/components/workflow/types';
import FieldPickerInput from '../fieldPicker';
import JsonForm from '../../../../../components/formFromSchema';
import schema from './input.schema.json';
import styles from './styles.module.scss';

function ApprovalInspector({
  node,
  updateNode,
}) {
  const handleValueChange = React.useCallback(e => {
    const { name, value } = e.target;
    updateNode({ id: node.id, changes: { [name]: value } });
  }, [node.id, updateNode]);

  return (
    <>
      <Panel.Block>
        <Field fullwidth>
          <Label>Step Name</Label>
          <Control fullwidth>
            <Input fullwidth name="name" id="name" value={node.name} onChange={handleValueChange} />
          </Control>
        </Field>
      </Panel.Block>
      <Panel.Header>
        Manual Approval
      </Panel.Header>
      <Panel.Block>
        <JsonForm
          longForm
          values={node}
          schema={schema}
          setValue={handleValueChange}
          InputComponent={FieldPickerInput}
          inputProps={{ currentNodeId: node.id }}
          fieldClassName={styles.field}
          ignoreJsonPaths
          forwardInputSchema
          noJson
        />
      </Panel.Block>
    </>
  );
}

ApprovalInspector.propTypes = {
  node: workflowNodeType.isRequired,
  updateNode: PropTypes.func.isRequired,
};

ApprovalInspector.defaultProps = {
};

export default ApprovalInspector;
