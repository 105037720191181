import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import Button from '@x-functions/freyja/lib/components/button';
import Icon from '@x-functions/freyja/lib/components/icon';
import {
  Field,
  Control,
} from '@x-functions/freyja/lib/components/form';
import Input from './input';
import FieldPickerModal from './modal';
import GooglePicker from './googlePicker';
import VaultPicker from './vaultPicker';
import { selectNodeAndAncestors } from '../../../../../reducers/workflowEditor';
import styles from './styles.module.scss';

function FieldPickerInput({
  name,
  value,
  currentNodeId,
  onChange,
  inputSchema,
  payload,
  ...rest
}) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const nodes = useSelector(state => selectNodeAndAncestors(state, { nodeId: currentNodeId }), shallowEqual);

  const handleGooglePick = React.useCallback(doc => {
    onChange({ target: { name, value: doc?.id } });
  }, [name, onChange]);

  const handleVaultPick = React.useCallback(item => {
    onChange({ target: { name, value: item?.key } });
  }, [name, onChange]);

  const setPickerMode = React.useCallback(() => {
    setModalOpen(true);
  }, []);

  const handlePickerClosed = React.useCallback(fieldPath => {
    if (fieldPath) {
      onChange({ target: { name, value: fieldPath } });
    }
    setModalOpen(false);
  }, [onChange, name]);

  const renderInputOptions = React.useCallback(schema => {
    switch (schema?.input?.type) {
      case 'googledrive-file-picker':
        return (
          <GooglePicker config={schema.input} onPick={handleGooglePick} payload={payload} />
        );
      case 'vault-key':
        return (
          <VaultPicker config={schema.input} onPick={handleVaultPick} payload={payload} />
        );

      default:
        return (
          <Control>
            <Button onClick={setPickerMode}><Icon><span className="fa fa-hand-pointer" /></Icon></Button>
          </Control>
        );
    }
  }, [setPickerMode, handleGooglePick, handleVaultPick, payload]);

  return (
    <Field kind="addons">
      <Control className={styles.control}>
        <Input
          name={name}
          value={value}
          onChange={onChange}
          nodes={nodes}
          {...rest}
        />
      </Control>
      {renderInputOptions(inputSchema)}
      {modalOpen && (<FieldPickerModal currentNodeId={currentNodeId} show={modalOpen} onClose={handlePickerClosed} nodes={nodes} />)}
    </Field>
  );
}

FieldPickerInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  currentNodeId: PropTypes.string.isRequired,
  inputSchema: PropTypes.shape({
    input: PropTypes.shape({
      type: PropTypes.string.isRequired,
      mimeTypes: PropTypes.string,
      selectFolders: PropTypes.bool,
      vaultKey: PropTypes.string,
    }),
  }),
  payload: PropTypes.shape({}),
};

FieldPickerInput.defaultProps = {
  name: undefined,
  value: undefined,
  inputSchema: undefined,
  payload: {},
};

export default FieldPickerInput;
