import React from 'react';
import PropTypes from 'prop-types';
import Panel from '@x-functions/freyja/lib/components/panel';
import Heading from '@x-functions/freyja/lib/components/heading';
import moment from 'moment';
import Content from '@x-functions/freyja/lib/components/content';
import JsonEditor from '../../../../../components/jsonEditor';
import styles from './monitor.module.scss';

function EventMonitor({ execution, node }) {
  if (!execution || !execution.events) {
    return <Panel.Block>Loading...</Panel.Block>;
  }

  const nodeEvents = execution.events[node.id] || [];

  return (
    <>
      {nodeEvents.length < 1 && (
        <Panel.Block>
          No events
        </Panel.Block>
      )}
      {nodeEvents.map(evt => (
        <React.Fragment key={evt.id}>
          <Panel.Header>
            <Heading size={6}>{evt.type}</Heading>
            <Heading size={6} subtitle>{moment(evt.timestamp).format('DD/MM/YYYY hh:mm:ss a')}</Heading>
          </Panel.Header>
          <Panel.Block key={evt.id}>
            <Content className={styles.content}>
              {evt.input && (
                <>
                  <strong>Input</strong><br />
                  <JsonEditor
                    value={evt.input}
                    autoGrow
                  />
                </>
              )}
              {evt.output && (
                <>
                  <strong>Output</strong><br />
                  <JsonEditor
                    value={evt.output}
                    autoGrow
                  />
                </>
              )}
              {evt.error && <p><strong>Error: {evt.error}</strong></p>}
              {evt.cause && <p><strong>Cause: {evt.cause}</strong></p>}
            </Content>
          </Panel.Block>
        </React.Fragment>
      ))}
    </>
  );
}

EventMonitor.propTypes = {
  execution: PropTypes.arrayOf(PropTypes.shape({
    events: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
    })),
  })),
  node: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

EventMonitor.defaultProps = {
  execution: undefined,
};

export default EventMonitor;
