import React from 'react';
import Bee from '@mailupinc/bee-plugin';
import equal from 'fast-deep-equal';
import { EventEmitter } from 'fbemitter';
import { useSelector, useDispatch } from 'react-redux';
import { getBeeToken } from '../reducers/htmlEditor';
import { selectCurrentOrg } from '../reducers/user';
import baseTemplate from '../utils/baseTemplate.json';

function useBeeInstance(editorType) {
  const dispatch = useDispatch();
  const [type] = React.useState(editorType); // cache it so that it doesn't change
  const [bee, setBee] = React.useState(undefined);
  const [emitter, setEmitter] = React.useState(undefined);

  React.useEffect(() => {
    dispatch(getBeeToken(type)).then(token => {
      setBee(new Bee(token));
      setEmitter(new EventEmitter());
    });
  }, [dispatch, type]);

  return [bee, emitter];
}

function useBeePlugin({
  editorType,
  containerId,
  jsonTemplate,
  onSave,
  onStart,
  onError,
}) {
  const orgId = useSelector(selectCurrentOrg, equal);
  const [type] = React.useState(editorType);
  const [bee, emitter] = useBeeInstance(type);
  const [loadedTemplate, setLoadedTemplate] = React.useState(undefined);
  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {
    if (!emitter || !onSave) return undefined;

    const token = emitter.addListener('save', onSave);
    return () => token.remove();
  }, [emitter, onSave]);

  React.useEffect(() => {
    if (!emitter || !onStart) return undefined;

    const token = emitter.addListener('start', onStart);
    return () => token.remove();
  }, [emitter, onStart]);

  React.useEffect(() => {
    if (!emitter || !onError) return undefined;

    const token = emitter.addListener('error', onError);
    return () => token.remove();
  }, [emitter, onError]);

  const handleStart = React.useCallback(() => {
    setReady(true);
  }, []);
  React.useEffect(() => {
    if (!emitter || !handleStart) return undefined;

    const token = emitter.addListener('start', handleStart);
    return () => token.remove();
  }, [emitter, handleStart]);

  React.useEffect(() => {
    if (!orgId || !bee || !emitter) return;

    bee.start({
      uid: orgId,
      container: containerId,
      language: 'en-AU',
      onSave: (...args) => emitter.emit('save', ...args),
      onStart: (...args) => emitter.emit('start', ...args),
      onError: (...args) => emitter.emit('error', ...args),
    }, baseTemplate[type]);
  }, [
    bee,
    type,
    orgId,
    containerId,
    emitter,
  ]);

  React.useEffect(() => {
    if (!bee || !ready || !jsonTemplate) {
      return;
    }

    if (!equal(jsonTemplate, loadedTemplate)) {
      setLoadedTemplate(jsonTemplate);
    }
  }, [bee, ready, jsonTemplate, loadedTemplate]);

  React.useEffect(() => {
    if (!bee || !ready || !loadedTemplate) return;

    bee.load(loadedTemplate);
  }, [bee, ready, loadedTemplate]);

  return [bee, ready];
}

export default useBeePlugin;
