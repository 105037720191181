export const createParser = parser => value => {
  let newValue = value;
  try {
    newValue = parser(value);
    if (Number.isNaN(newValue) || newValue === undefined) throw new Error('Failed!');
  } catch (e) {
    newValue = value;
  }

  return newValue;
};

const fieldNameRegex = /^\.([0-9a-z])+\b/g;
export const getFieldName = dataPath => {
  const match = dataPath?.match(fieldNameRegex);
  if (match && match[0]) return match[0].slice(1);
  return dataPath;
};
