import { createAction, createReducer } from 'redux-act';
import { createSelector } from 'reselect';
import { selectCurrentOrg } from './user';
import axios from '../utils/api';

const setSegmentUsers = createAction('/organizations/audience/segmentUsers/set');

export const fetchSegmentUsers = ({ orgId, segmentId }) => async dispatch => {
  const { data } = await axios.get(`/audience/orgs/${orgId}/segments/${segmentId}/users`);
  dispatch(setSegmentUsers({ orgId, segmentId, data }));
  return data;
};

export default createReducer({
  [setSegmentUsers]: (state, { orgId, segmentId, data }) => ({
    ...state,
    [`${orgId}/${segmentId}`]: data,
  }),
}, {});

const selectState = state => state.segmentUsers;

const selectSegmentId = (_, { segmentId }) => segmentId;

export const selectSegmentUsers = createSelector(
  [selectCurrentOrg, selectState, selectSegmentId],
  (currentOrg, state, segmentId) => state[`${currentOrg}/${segmentId}`],
);
