import React from 'react';
import PropTypes from 'prop-types';
import Heading from '@x-functions/freyja/lib/components/heading';
import Icon from '@x-functions/freyja/lib/components/icon';
import Tile from './tile';

function MainAction({ title, icon, ...otherProps }) {
  return (
    <Tile color="primary" textAlignment="centered" {...otherProps}>
      <Heading>{title}</Heading>
      <Heading subtitle>
        <Icon size="large"><span className={`fa fa-2x fa-${icon}`} /></Icon>
      </Heading>
    </Tile>
  );
}

MainAction.propTypes = {
  title: PropTypes.node.isRequired,
  icon: PropTypes.string.isRequired,
};

export default MainAction;
