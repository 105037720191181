import React from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  Label,
  Control,
  Help,
} from '@x-functions/freyja/lib/components/form';
import { camelToSencenceCase } from '../../utils/text';
import styles from './styles.module.scss';

function LongForm({
  fieldClassName,
  fields,
  errors,
  renderInput,
}) {
  return (
    <div className={styles.longForm}>
      {fields.map(f => (
        <Field className={fieldClassName} key={`field_${f.name}`}>
          <Label>{camelToSencenceCase(f.name || '')}</Label>
          <Control fullwidth>
            {renderInput(f, errors && errors[f.name])}
          </Control>
          {errors && errors[f.name] ? (
            <Help color="danger">{errors[f.name]}</Help>
          ) : null}
        </Field>
      ))}
    </div>
  );
}

LongForm.propTypes = {
  fieldClassName: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  errors: PropTypes.shape({}),
  renderInput: PropTypes.func.isRequired,
};

LongForm.defaultProps = {
  fieldClassName: undefined,
  fields: [],
  errors: {},
};

export default LongForm;
