import { init } from '@x-functions/website-analytics-sdk';
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastProvider } from 'react-toast-notifications';
import { store } from './store';
import PageTracker from './components/pageTracker';
import OrgGuard from './components/orgGuard';
import RoleGuard from './components/roleGuard';
import Navbar from './components/navbar';
import GlobalSearch from './components/globalSearch';
import ConfirmProvider from './components/confirm/provider';
import GooglePickerProvider from './components/googlePicker/provider';
import Workflows from './pages/workflows';
import Content from './pages/content';
import Vault from './pages/vault';
import './App.scss';

init(
  'x-functions',
  process.env.REACT_APP_ANALYTICS_APP_ID || '1608682938136',
  {
    isSPA: true,
    disablePageTracker: true,
    apiDomain: process.env.REACT_APP_ANALYTICS_API_DOMAIN,
  },
);

function App() {
  return (
    <Router>
      <PageTracker />
      <ToastProvider autoDismiss autoDismissTimeout={4000} placement="bottom-center">
        <Provider store={store}>
          <ConfirmProvider>
            <OrgGuard>
              <RoleGuard>
                <GooglePickerProvider>
                  <Switch>
                    <Route path="/workflows">
                      <Workflows />
                    </Route>
                    <Route path="/content">
                      <Content />
                    </Route>
                    <Route path="/vault/auth/:platform">
                      <Vault />
                    </Route>
                    <Redirect from="/" to="/workflows" exact />
                  </Switch>
                  <GlobalSearch />
                </GooglePickerProvider>
              </RoleGuard>
            </OrgGuard>
          </ConfirmProvider>
        </Provider>
      </ToastProvider>
    </Router>
  );
}

export default App;
