import React from 'react';
import PropTypes from 'prop-types';
import Panel from '@x-functions/freyja/lib/components/panel';
import {
  Field,
  Label,
  Control,
  Select,
} from '@x-functions/freyja/lib/components/form';
import { json } from 'generate-schema';
import { flatten } from 'flat';
import { workflowNodeType } from '@x-functions/x-react-workflow/lib/components/workflow/types';
import useEventData from '../../../../../hooks/useEventData';
import SearchInput from '../../../../../components/searchInput';
import styles from './styles.module.scss';

function PinpointEventInspector({ node, updateNode }) {
  const {
    orgId,
    apps,
    eventTypes,
    eventSamples,
    actions,
  } = useEventData({
    appId: node.eventSource,
    eventType: node.eventName,
  });

  const eventSource = node?.eventSource;
  const eventName = node?.eventName;

  React.useEffect(() => {
    if (!orgId || !actions) return;

    actions.fetchEventTypes({ orgId, appId: eventSource });
  }, [actions, orgId, eventSource]);

  React.useEffect(() => {
    if (!orgId || !actions || !eventName) return;

    actions.fetchEventSamples({ orgId, appId: eventSource, eventType: eventName });
  }, [actions, orgId, eventSource, eventName]);

  React.useEffect(() => {
    if (!orgId || !actions) return;

    actions.fetchApps({ orgId });
  }, [actions, orgId]);

  React.useEffect(() => {
    // all possible attributes
    const attr = eventSamples?.map(e => e?.attributes || {}).filter(a => Object.keys(a).length > 0).reduce((acc, curr) => ({
      ...acc,
      ...curr,
    }), {});

    // all possible metrics
    const metr = eventSamples?.map(e => e?.metrics || {}).filter(a => Object.keys(a).length > 0).reduce((acc, curr) => ({
      ...acc,
      ...curr,
    }), {});

    // flatten email facets
    const event = eventSamples?.length > 0 ? eventSamples[0] : undefined;
    let facets = event?.facets;
    if (facets) facets = flatten(facets);

    // Now use the first event as base structure, inserting the attributes and metrics
    const sampleEvent = {
      ...event,
      ...(Object.keys(attr || {}).length > 0 ? ({
        attributes: attr,
      }) : {}),
      ...(Object.keys(metr || {}).length > 0 ? ({
        metrics: metr,
      }) : {}),
      ...(facets ? { facets } : {}),
    };

    const schema = json('Event', sampleEvent);
    schema.$schema = 'http://json-schema.org/draft-07/schema#';

    updateNode({
      id: node.id,
      changes: {
        schema,
      },
    });
  }, [eventSamples, updateNode, node.id]);

  const handleValueChange = React.useCallback(e => {
    const { target: { name, value } } = e;
    updateNode({ id: node.id, changes: { [name]: value } });
  }, [node.id, updateNode]);

  const setEventName = React.useCallback(name => e => {
    if (e?.stopPropagation) e.stopPropagation();
    if (e?.preventDefault) e.preventDefault();

    updateNode({ id: node.id, changes: { eventName: name } });
  }, [node.id, updateNode]);

  const attributes = Object.keys(node?.schema?.properties?.attributes?.properties || {});
  const metrics = Object.keys(node?.schema?.properties?.metrics?.properties || {});
  const facets = Object.keys(node?.schema?.properties?.facets?.properties || {});

  return (
    <>
      <Panel.Block>
        <Field className={styles.field}>
          <Label>Source</Label>
          <Control>
            <Select value={node.eventSource} onChange={handleValueChange} name="eventSource" id="eventSource">
              <option value="">Any source</option>
              {apps?.length > 0 ? apps.map(app => (
                <option key={app.appId} value={app.appId}>{app.name}</option>
              )) : null}
            </Select>
          </Control>
          <Label>Event</Label>
          <Control>
            <SearchInput
              placeholder="your_custom_event"
              name="eventName"
              value={node.eventName}
              onChange={handleValueChange}
              searchResults={eventTypes?.map(t => ({
                id: t.key,
                renderAs: 'a',
                label: t.key,
                onClick: setEventName(t.key),
              }))}
            />
          </Control>
        </Field>
      </Panel.Block>
      <Panel.Block style={{ display: 'block' }}>
        <Field className={styles.field}>
          <Label>Attributes</Label>
        </Field>
        <div className={styles.field}>
          {attributes.length > 0 ? attributes.map(a => (
            <p key={a}>{a}</p>
          )) : 'None'}
        </div>
      </Panel.Block>
      <Panel.Block style={{ display: 'block' }}>
        <Field className={styles.field}>
          <Label>Metrics</Label>
        </Field>
        <div className={styles.field}>
          {metrics.length > 0 ? metrics.map(m => (
            <p key={m}>{m}</p>
          )) : 'None'}
        </div>
      </Panel.Block>
      {facets?.length > 0 ? (
        <Panel.Block style={{ display: 'block' }}>
          <Field className={styles.field}>
            <Label>Facets</Label>
          </Field>
          <div className={styles.field}>
            {facets.map(f => (
              <p key={f}>{f}</p>
            ))}
          </div>
        </Panel.Block>
      ) : null}
    </>
  );
}

PinpointEventInspector.propTypes = {
  node: workflowNodeType.isRequired,
  updateNode: PropTypes.func.isRequired,
};

export default PinpointEventInspector;
