import React from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash/fp';
import { useHistory } from 'react-router-dom';
import Button from '@x-functions/freyja/lib/components/button';
import Tabs from '@x-functions/freyja/lib/components/tabs';
import Icon from '@x-functions/freyja/lib/components/icon';
import InlineInputEditor from '../../../../components/inlineEditor/input';
import useWorkflowEditor from '../../../../hooks/useWorkflowEditor';
import styles from './styles.module.scss';

function WorkflowHeader({ recordChange }) {
  const {
    orgId: currentOrg,
    workflow,
    mode,
    view,
    details,
    actions,
  } = useWorkflowEditor();

  const history = useHistory();

  const [savingState, setSavingState] = React.useState({
    saving: false,
    statusCount: 0,
  });
  const [lastStatus, setLastStatus] = React.useState(undefined);
  const [publishedWorkflow, setPublishedWorkflow] = React.useState(undefined);
  const [hasChanges, setHasChanges] = React.useState(true);

  React.useEffect(() => {
    if (details && details.statuses && details.statuses.length) {
      const latest = details.statuses[details.statuses.length - 1];
      setLastStatus(latest);
      if (savingState?.saving && savingState?.statusCount < details.statuses.length && latest.status !== 'UPDATING') {
        setSavingState({ saving: false, statusCount: details.statuses.length });
      }
    }
  }, [details, savingState]);

  React.useEffect(() => {
    if (!details?.currentVersion) return;

    const publishedVersion = details.versions?.find(v => v.version === details.currentVersion);
    if (!publishedVersion) return;

    setPublishedWorkflow(JSON.parse(publishedVersion.definition || '{}'));
  }, [details]);

  React.useEffect(() => {
    if (!workflow || !publishedWorkflow) return;

    setHasChanges(!isEqual(
      { nodes: workflow.nodes, name: workflow.name },
      { nodes: publishedWorkflow.nodes, name: publishedWorkflow.name },
    ));
  }, [workflow, publishedWorkflow]);

  const handleBackClick = React.useCallback(() => {
    history.push('/workflows');
  }, [history]);

  const handleEditorModeSwitch = React.useCallback(() => {
    switch (mode) {
      case 'edit':
        actions.setEditorMode({ mode: 'monitor' });
        break;
      default:
        actions.setEditorMode({ mode: 'edit', view: 'tree' });
        break;
    }
  }, [actions, mode]);

  const handleDeploy = React.useCallback(async e => {
    e.preventDefault();
    e.stopPropagation();

    setSavingState({ saving: true, statusCount: details.statuses.length });
    try {
      await actions.saveWorkflow({ orgId: currentOrg, deploy: true });
    } catch (err) {
      setSavingState({ saving: false, statusCount: details.statuses.length });
      window.alert(`Failed! ${err.message}`);
    }
  }, [details, currentOrg, actions]);

  const handleWorkflowNameChange = React.useCallback(e => {
    const { target: { value } } = e;
    actions.updateWorkflowName({ name: value });
  }, [actions]);

  const handleViewClick = React.useCallback(() => {
    actions.setEditorMode({ mode, view: view === 'tree' ? 'code' : 'tree' });
  }, [actions, mode, view]);

  return (
    <div className={styles.header}>
      <div className="is-flex is-flex-direction-row is-justify-content-center is-align-items-center">
        <Button onClick={handleBackClick} className="mr-2">
          <Icon><i className="material-icons md-24">chevron_left</i></Icon>
        </Button>
        <InlineInputEditor
          name="name"
          value={details?.workflowName}
          headingSize={6}
          showButtons={false}
          onChange={handleWorkflowNameChange}
          onSave={recordChange}
          onCancel={() => true}
        />
      </div>
      <div>
        {workflow && workflow.workflowId && (
          <Tabs className={styles.tabs}>
            <Tabs.Tab active={mode === 'edit'} onClick={handleEditorModeSwitch}>Edit</Tabs.Tab>
            <Tabs.Tab active={mode === 'monitor'} onClick={handleEditorModeSwitch}>Monitor</Tabs.Tab>
          </Tabs>
        )}
      </div>
      <Button.Group className={styles.buttons}>
        {savingState?.saving ? (<Button rounded isStatic>Publishing</Button>) : null}
        {!savingState?.saving && hasChanges ? (<Button rounded isStatic>Draft</Button>) : null}
        {!savingState?.saving && !hasChanges && details?.currentVersion
          ? (<Button rounded isStatic>Version {details.currentVersion}</Button>)
          : null}
        {mode === 'edit' ? (
          <Button onClick={handleViewClick} title="Toggle code view">
            {view === 'tree'
              ? (<Icon><i className="material-icons md-24">code</i></Icon>)
              : (<Icon><i className="material-icons md-24">account_tree</i></Icon>)}
          </Button>
        ) : null}
        <Button
          color="primary"
          onClick={handleDeploy}
          fullwidth
          loading={savingState?.saving || lastStatus?.status === 'UPDATING'}
          disabled={!hasChanges || savingState?.saving || lastStatus?.status === 'UPDATING'}
        >
          Publish
        </Button>
      </Button.Group>
    </div>
  );
}

WorkflowHeader.propTypes = {
  details: PropTypes.shape({
    stateMachineArn: PropTypes.string,
    statuses: PropTypes.arrayOf(PropTypes.shape({
      status: PropTypes.string.isRequired,
      timestamp: PropTypes.number.isRequired,
    })),
  }),
  recordChange: PropTypes.func.isRequired,
};

WorkflowHeader.defaultProps = {
  details: {},
};

export default WorkflowHeader;
