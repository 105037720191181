import { v4 } from 'uuid';
import { createAction, createReducer } from 'redux-act';
import { createSelector } from 'reselect';
import { selectCurrentOrg } from './user';
import axios from '../utils/api';

const setOrgAudienceSegments = createAction('/organizations/audience/segments/set');
const addOrgAudienceSegment = createAction('/organizations/audience/segments/add');
const updateOrgAudienceSegment = createAction('/organizations/audience/segments/update');
const deleteOrgAudienceSegment = createAction('/organizations/audience/segments/delete');

export const fetchOrganizationAudienceSegments = ({ orgId, token }) => async dispatch => {
  const { data: { data, nextToken } } = await axios.get(`/audience/orgs/${orgId}/segments`, { params: { token } });
  dispatch(setOrgAudienceSegments({
    orgId,
    data,
    nextToken,
    token,
  }));
  return data;
};

const cleanUpFilter = filter => {
  const obj = filter.attributeFilter || filter.eventFilter;
  if (obj) {
    // eslint-disable-next-line no-param-reassign
    obj.path = obj.path || '';
  }
  return filter;
};

const cleanUpDefinition = ({ type, ...rest }) => {
  const { userIds, ...dynamicDef } = rest;
  if (type === 'DYNAMIC') {
    return {
      ...dynamicDef,
      type,
      groups: dynamicDef.groups.map(({ id, ...group }) => ({
        ...group,
        // eslint-disable-next-line no-shadow
        filters: group.filters.map(({ id, ...filter }) => cleanUpFilter(filter)),
      })),
    };
  }

  return { type, userIds };
};

// Clean up the segment values, removing any additional properties that the front-end might have included
const cleanUpSegmentValues = ({ entity, aspect, ...segment }) => ({
  ...segment,
  definition: cleanUpDefinition(segment.definition),
});

export const createSegment = ({ orgId, segment }) => async dispatch => {
  const { data } = await axios.post(`/audience/orgs/${orgId}/segments`, cleanUpSegmentValues(segment));
  dispatch(addOrgAudienceSegment({ orgId, data }));
  return data;
};

export const updateSegment = ({ orgId, segmentId, segment }) => async dispatch => {
  const { data } = await axios.put(`/audience/orgs/${orgId}/segments/${segmentId}`, cleanUpSegmentValues(segment));
  dispatch(updateOrgAudienceSegment({ orgId, segmentId, data }));
  return data;
};

export const deleteSegment = ({ orgId, segmentId }) => async dispatch => {
  await axios.delete(`/audience/orgs/${orgId}/segments/${segmentId}`);
  dispatch(deleteOrgAudienceSegment({ orgId, segmentId }));
  return true;
};

export default createReducer({
  [setOrgAudienceSegments]: (state, {
    orgId,
    data,
    nextToken,
    token,
  }) => {
    const orgState = state[orgId] || {};
    const oldItems = orgState.data || [];
    const newItems = token ? [...oldItems, ...data] : data;
    return {
      ...state,
      [orgId]: {
        ...orgState,
        data: newItems,
        nextToken,
      },
    };
  },
  [addOrgAudienceSegment]: (state, { orgId, data }) => ({
    ...state,
    [orgId]: {
      ...(state[orgId] || {}),
      data: [data, ...((state[orgId] || {}).data || [])],
    },
  }),
  [updateOrgAudienceSegment]: (state, { orgId, segmentId, data }) => ({
    ...state,
    [orgId]: {
      ...(state[orgId] || {}),
      data: [data, ...((state[orgId] || {}).data || []).filter(s => s.Id !== segmentId)],
    },
  }),
  [deleteOrgAudienceSegment]: (state, { orgId, segmentId }) => ({
    ...state,
    [orgId]: {
      ...(state[orgId] || {}),
      data: ((state[orgId] || {}).data || []).filter(s => s.Id !== segmentId),
    },
  }),
}, {});

const selectOrgAudienceSegments = state => state.orgAudienceSegments;

const selectSegmentId = (_, { segmentId }) => segmentId;

export const selectOrgSegments = (state, orgId) => {
  const { data } = selectOrgAudienceSegments(state)[orgId] || {};
  return data;
};

export const selectNextToken = (state, orgId) => {
  const { nextToken } = selectOrgAudienceSegments(state)[orgId] || {};
  return nextToken;
};

export const selectCurrentOrgSegmentMap = createSelector(
  [selectCurrentOrg, selectOrgAudienceSegments],
  (currentOrg, segments) => {
    const orgSegments = (segments[currentOrg] || {}).data;
    return (orgSegments || []).reduce((acc, curr) => ({
      ...acc,
      [curr.Id]: curr,
    }), {});
  },
);

export const selectSegmentDetails = createSelector(
  [selectCurrentOrg, selectOrgAudienceSegments, selectSegmentId],
  (currentOrg, segments, segmentId) => {
    if (!segmentId) return undefined;

    const orgSegments = (segments[currentOrg] || {}).data;
    const segment = (orgSegments || []).find(s => s.segmentId === segmentId);

    if (segment && segment.definition.type === 'DYNAMIC') {
      return {
        ...segment,
        definition: {
          ...segment.definition,
          groups: segment.definition.groups.map(g => ({
            ...g,
            id: g.id || v4(),
            filters: g.filters.map(f => ({ ...f, id: f.id || v4() })),
          })),
        },
      };
    }

    return segment;
  },
);
