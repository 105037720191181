import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@x-functions/freyja/lib/components/modal';
import {
  Field,
  Control,
  Input,
} from '@x-functions/freyja/lib/components/form';
import Menu from '@x-functions/freyja/lib/components/menu';
import useGlobalSearch from '../../hooks/useGlobalSearch';
import MenuItem from './menuItem';

function SearchModal({ onClose }) {
  const {
    orgId,
    results,
    actions,
  } = useGlobalSearch();

  const [query, setQuery] = React.useState('');
  const [waitingKeystrokes, setWaitingKeystrokes] = React.useState(0);

  const handleQueryChanged = React.useCallback(e => {
    const { target: { value } } = e;
    setQuery(value);

    setWaitingKeystrokes(v => v + 1);
    setTimeout(() => setWaitingKeystrokes(v => v - 1), 200);
  }, []);

  React.useEffect(() => {
    if (!query) return;

    if (waitingKeystrokes === 0) {
      actions.search({ orgId, query });
    }
  }, [query, waitingKeystrokes, orgId, actions]);

  React.useEffect(() => {
    const focusInput = () => {
      const input = document.querySelector('#globalQuery');
      if (input) {
        input.focus();
      } else {
        setTimeout(focusInput, 200);
      }
    };

    focusInput();

    return () => {
      actions.clearResults({ orgId });
    };
  }, [actions, orgId]);

  return (
    <Modal show onClose={onClose}>
      <Modal.Card>
        <Modal.Card.Head onClose={onClose}>
          <Modal.Card.Title>Search</Modal.Card.Title>
        </Modal.Card.Head>
        <Modal.Card.Body>
          <Menu>
            <Menu.List>
              <Menu.List.Item>
                <Field>
                  <Control>
                    <Input
                      type="text"
                      id="globalQuery"
                      key="globalQuery"
                      name="globalQuery"
                      value={query}
                      placeholder="Search..."
                      onChange={handleQueryChanged}
                    />
                  </Control>
                </Field>
              </Menu.List.Item>
              {Object.keys(results).map(type => (
                <Menu.List title={type} key={type}>
                  {results[type].map(result => (
                    <MenuItem key={`${result.entity}-${result.aspect}`} item={result} type={type} onNavigate={onClose} />
                  ))}
                </Menu.List>
              ))}
            </Menu.List>
          </Menu>
        </Modal.Card.Body>
      </Modal.Card>
    </Modal>
  );
}

SearchModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default SearchModal;
