import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CodeMirror from 'codemirror';
import { validateJSON } from '../../utils/jsonSchemas';
import './text.scss';

const createErrorMarker = error => {
  const marker = document.createElement('div');
  marker.classList.add('error-marker');
  marker.innerHTML = '&nbsp;';

  const err = document.createElement('div');
  err.innerHTML = error;
  err.classList.add('error-message');
  marker.appendChild(err);

  return marker;
};

const makeProcessor = () => {
  let marks = [];

  return (editor, schema, onChange) => {
    const value = editor.getValue();
    const errors = validateJSON(value, schema, true);
    editor.clearGutter('errors');
    marks.forEach(m => m.clear());
    let newMarks = [];
    newMarks = errors.map(error => {
      editor.setGutterMarker(error.from.line, 'errors', createErrorMarker(error.message));
      return editor.markText(error.from, error.to, { className: 'CodeMirror-highlighted' });
    });
    marks = newMarks;

    return onChange(value);
  };
};

function JsonEditor({
  value,
  onChange,
  schema,
  className,
  autoGrow,
}) {
  const domRef = React.useRef();
  const [editor, setEditor] = React.useState();

  const processChange = React.useCallback(makeProcessor(), []);

  const setRef = React.useCallback(elem => {
    if (!elem) return undefined;

    domRef.current = elem;
    const e = CodeMirror.fromTextArea(domRef.current, {
      mode: 'application/json',
      gutters: ['errors'],
      tabSize: 2,
      indentUnit: ' ',
      indentWithTabs: false,
      styleActiveLine: true,
      lineNumbers: true,
      line: true,
      extraKeys: {
        Tab: cm => {
          cm.replaceSelection('  ', 'end');
        },
      },
      viewportMargin: autoGrow ? Infinity : 10,
    });
    setEditor(e);

    return () => {
      e.toTextArea();
      setEditor(undefined);
    };
  }, [autoGrow]);

  const setEditorValue = React.useCallback(val => {
    const cursor = editor.getCursor();
    const scrollInfo = editor.getScrollInfo();

    let newVal = val || value;
    if (typeof newVal !== 'string') newVal = JSON.stringify(newVal || {}, null, 2);

    const oldVal = (value && value !== 'string') ? JSON.stringify(value) : value;
    if (newVal !== oldVal) {
      editor.setValue(newVal);
      editor.setCursor(cursor);
      editor.scrollTo(scrollInfo.left, scrollInfo.top);
    }
  }, [editor, value]);

  React.useEffect(() => {
    if (editor) {
      editor.setOption('readOnly', !onChange);
    }
  }, [editor, onChange]);

  React.useEffect(() => {
    if (!editor) return undefined;

    const handler = onChange ? () => processChange(editor, schema, onChange) : undefined;
    if (handler) editor.on('change', handler);

    return () => {
      if (handler) editor.off('change', handler);
    };
  }, [editor, processChange, onChange, schema]);

  React.useEffect(() => {
    if (editor) {
      setEditorValue();
    }
  }, [editor, setEditorValue]);

  return (
    <div
      className={classNames({
        autoGrow,
      }, className)}
    >
      <textarea ref={setRef} />
    </div>
  );
}

JsonEditor.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  schema: PropTypes.shape({}),
  className: PropTypes.string,
  autoGrow: PropTypes.bool,
};

JsonEditor.defaultProps = {
  schema: undefined,
  className: undefined,
  onChange: undefined,
  autoGrow: false,
};

export default JsonEditor;
