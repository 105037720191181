import React from 'react';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';
import Panel from '@x-functions/freyja/lib/components/panel';
import Button from '@x-functions/freyja/lib/components/button';
import {
  Field,
  Label,
  Control,
  Input,
} from '@x-functions/freyja/lib/components/form';
import { workflowNodeType } from '@x-functions/x-react-workflow/lib/components/workflow/types';
import Filter from './filter';

function WaitForEventInspector({
  groupId,
  node,
  updateNode,
}) {
  const handleValueChange = React.useCallback(e => {
    const { name, value } = e.target;
    updateNode({ groupId, id: node.id, changes: { [name]: value } });
  }, [node.id, updateNode, groupId]);

  const handleAddFilterClick = React.useCallback(() => {
    updateNode({
      groupId,
      id: node.id,
      changes: {
        filter: [
          ...node.filter,
          {
            id: v4(),
            variable: '',
            dataType: 'string',
            condition: 'equalTo',
            value: '',
            mergeLogic: 'and',
          },
        ],
      },
    });
  }, [node, groupId, updateNode]);

  const handleFilterChange = React.useCallback(filterId => e => {
    const filterIndex = node.filter.findIndex(f => f.id === filterId);
    if (!~filterIndex) return;

    updateNode({
      groupId,
      id: node.id,
      changes: {
        filter: [
          ...node.filter.slice(0, filterIndex),
          {
            ...node.filter[filterIndex],
            [e.target.name]: e.target.value,
          },
          ...node.filter.slice(filterIndex + 1),
        ],
      },
    });
  }, [updateNode, node, groupId]);

  const handleFilterDelete = React.useCallback(filterId => () => {
    updateNode({
      groupId,
      id: node.id,
      changes: {
        filter: node.filter.filter(f => f.id !== filterId),
      },
    });
  }, [updateNode, groupId, node]);

  return (
    <>
      <Panel.Block>
        <Field fullwidth>
          <Label>Step Name</Label>
          <Control fullwidth>
            <Input fullwidth name="name" id="name" value={node.name} onChange={handleValueChange} />
          </Control>
        </Field>
      </Panel.Block>
      <Panel.Header>
        Wait for event
      </Panel.Header>
      <Panel.Block>
        <Field fullwidth>
          <Label>Event Name</Label>
          <Control fullwidth>
            <Input fullwidth name="eventName" id="eventName" value={node.eventName} onChange={handleValueChange} />
          </Control>
        </Field>
      </Panel.Block>
      {node.filter.map((f, i) => (
        <Filter
          filter={f}
          onFilterChange={handleFilterChange(f.id)}
          onFilterDelete={handleFilterDelete(f.id)}
          showMergeOption={i < node.filter.length - 1}
        />
      ))}
      <Panel.Block>
        <Button onClick={handleAddFilterClick}>Add filter</Button>
      </Panel.Block>
    </>
  );
}

WaitForEventInspector.propTypes = {
  groupId: PropTypes.string,
  node: workflowNodeType.isRequired,
  updateNode: PropTypes.func.isRequired,
};

WaitForEventInspector.defaultProps = {
  groupId: undefined,
};

export default WaitForEventInspector;
