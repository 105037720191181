import React from 'react';
import PropTypes from 'prop-types';
import Menu from '@x-functions/freyja/lib/components/menu';
import { getSchemaTypeDefinitions } from '../../../../../../../utils/jsonSchemas';

function PinpointEventFields({ node, onClick }) {
  if (!node.schema || !node.schema.properties) {
    return <p>Event schema not defined.</p>;
  }

  return (
    <Menu>
      <Menu.List title={`Event - ${node.eventName}`}>
        {getSchemaTypeDefinitions(node.schema, undefined, { extended: true }).map(p => (
          <>
            <Menu.List.Item key={p.name} onClick={() => onClick(`$.${p.name}`)}>
              {`${p.name}: ${p.type}${p.required ? ' (required)' : ''}`}
            </Menu.List.Item>
            {p.properties?.length > 0 ? p.properties.map(childprop => (
              <Menu.List.Item key={`${p.name}.${childprop.name}`} onClick={() => onClick(`$.${p.name}.${childprop.name}`)}>
                {`${p.name}.${childprop.name}: ${childprop.type}${childprop.required ? ' (required)' : ''}`}
              </Menu.List.Item>
            )) : null}
          </>
        ))}
      </Menu.List>
    </Menu>
  );
}

PinpointEventFields.propTypes = {
  node: PropTypes.shape({
    eventName: PropTypes.string,
    schema: PropTypes.shape({
      properties: PropTypes.objectOf({
        name: PropTypes.string,
        type: PropTypes.string,
        required: PropTypes.bool,
      }),
    }),
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

PinpointEventFields.defaultProps = {
};

export default PinpointEventFields;
