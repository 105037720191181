import React from 'react';
import PropTypes from 'prop-types';
import JsonEditor from '../../../../components/jsonEditor/text';
import workflowSchema from './workflow.schema.json';

function WorkflowTextEditor({
  workflow,
  onChange,
}) {
  const [value, setValue] = React.useState(workflow?.nodes ? JSON.stringify(workflow.nodes, null, 2) : undefined);

  const handleJsonChanged = React.useCallback(val => {
    try {
      const nodes = JSON.parse(val);
      onChange(nodes);
    } finally {
      setValue(val);
    }
  }, [onChange]);

  React.useEffect(() => {
    if (value) return;

    if (workflow?.nodes) setValue(JSON.stringify(workflow.nodes, null, 2));
  }, [workflow, value]);

  if (value) {
    return (
      <JsonEditor
        value={value}
        onChange={handleJsonChanged}
        autoGrow
        schema={workflowSchema}
      />
    );
  }

  return null;
}

WorkflowTextEditor.propTypes = {
  workflow: PropTypes.shape({
    nodes: PropTypes.shape({}),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default WorkflowTextEditor;
