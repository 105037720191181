import React from 'react';
import PropTypes from 'prop-types';
import Heading from '@x-functions/freyja/lib/components/heading';
import styles from './display.module.scss';

function Display({ children, size, ...rest }) {
  const Elem = size ? props => <Heading size={size} {...props}>{children}</Heading> : 'span';
  return (
    <Elem className={styles.root} {...rest}>{children}</Elem>
  );
}

Display.propTypes = {
  children: PropTypes.node,
  size: PropTypes.number,
};

Display.defaultProps = {
  children: null,
  size: undefined,
};

export default Display;
