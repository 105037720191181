import React from 'react';
import PropTypes from 'prop-types';
import ConfirmContext from './context';
import ConfirmModal from './modal';

const emptyState = {
  open: false,
  title: 'Please Confirm',
  body: 'Do you want to proceed?',
  confirmText: 'Yes',
  cancelText: 'No',
  onClose: () => false,
};

function ConfirmProvider({ children }) {
  const [modalState, setModalState] = React.useState(emptyState);

  const confirm = React.useCallback((title, body, confirmText, cancelText) => new Promise(resolve => {
    setModalState({
      open: true,
      title: title || emptyState.title,
      body: body || emptyState.body,
      confirmText: confirmText || emptyState.confirmText,
      cancelText: cancelText || emptyState.cancelText,
      onClose: result => {
        setModalState(emptyState);
        resolve(!!result);
      },
    });
  }), []);

  const providerState = React.useMemo(() => ({ confirm }), [confirm]);

  return (
    <ConfirmContext.Provider value={providerState}>
      <ConfirmModal {...modalState} />
      {children}
    </ConfirmContext.Provider>
  );
}

ConfirmProvider.propTypes = {
  children: PropTypes.node,
};

ConfirmProvider.defaultProps = {
  children: null,
};

export default ConfirmProvider;
