import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@x-functions/freyja/lib/components/button';
import {
  Input as BulmaInput,
} from '@x-functions/freyja/lib/components/form';
import { selectNode as selectNodeAction } from '../../../../../reducers/workflowEditor';
import useActions from '../../../../../hooks/useActions';
import styles from './styles.module.scss';

const actionCreators = { selectNode: selectNodeAction };

export function useSelectNode() {
  const actions = useActions(actionCreators);

  return actions;
}

function Input({
  name,
  value,
  onChange,
  nodes,
  color,
  ...rest
}) {
  const [tag, setTag] = React.useState('');
  const [inputVal, setInputVal] = React.useState('');
  const [toKeep, setToKeep] = React.useState('');
  const [inputNode, setInputNode] = React.useState(undefined);
  const { selectNode } = useSelectNode();

  React.useEffect(() => {
    if (value?.toString().startsWith('$.result')) {
      const node = nodes.find(n => n.output && value.toString().startsWith(n.output));
      if (node) {
        setTag(node.name);
        setInputVal(value.toString().substr(node.output.length));
        setToKeep(node.output);
        setInputNode(node);
        return;
      }
    } else if (value?.toString().startsWith('$.mapItem')) {
      if (value.startsWith('$.mapItemIndex')) {
        setTag('Item Index');
        setInputVal(value.replace('$.mapItemIndex', ''));
        setToKeep('$.mapItemIndex');
        setInputNode(undefined);
      } else {
        setTag('Item Value');
        setInputVal(value.replace('$.mapItemValue', ''));
        setToKeep('$.mapItemValue');
        setInputNode(undefined);
      }
      return;
    } else if (value?.toString().startsWith('$')) {
      setTag('Event');
      setInputVal(value.toString().substr(1));
      setToKeep('$');
      setInputNode(nodes[1]);
      return;
    }


    setTag('');
    setToKeep('');
    setInputVal(value?.toString());
    setInputNode(undefined);
  }, [value, nodes]);

  const handleKeydown = React.useCallback(e => {
    if (e.key === 'Backspace' && e.target.value?.length === 0) {
      e.persist();

      setToKeep('');
      setTag(() => {
        onChange({ ...e, target: { ...e.target, name, value: '' } });
        return '';
      });
    }
  }, [onChange, name]);

  const handleChange = React.useCallback(e => {
    e.persist();
    const { target: { name: n, value: v } } = e;
    const newVal = `${toKeep}${v}`;

    return onChange({
      ...e,
      target: { name: n, value: newVal },
    });
  }, [onChange, toKeep]);

  const handleNodeClick = React.useCallback(() => {
    if (!inputNode?.id) return;

    selectNode(inputNode);
  }, [selectNode, inputNode]);

  return (
    <div className={classNames('input', styles.input, color ? `is-${color}` : undefined)}>
      {tag ? (
        <Button
          size="small"
          color="light"
          title={`${tag} (click to navigate)`}
          className={styles.btn}
          onClick={handleNodeClick}
        >
          <span>{tag}</span>
        </Button>
      ) : null}
      <BulmaInput
        name={name}
        value={inputVal}
        onChange={handleChange}
        onKeyDown={handleKeydown}
        {...rest}
        className={styles.internalInput}
      />
    </div>
  );
}

Input.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  color: PropTypes.string,
  nodes: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })),
  selectNode: PropTypes.func.isRequired,
};

Input.defaultProps = {
  name: undefined,
  value: undefined,
  nodes: [],
  color: undefined,
};

export default Input;
