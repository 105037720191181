import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@x-functions/freyja/lib/components/icon';
import Button from '@x-functions/freyja/lib/components/button';
import Panel from '@x-functions/freyja/lib/components/panel';
import { Field, Control, Input } from '@x-functions/freyja/lib/components/form';
import Popover from 'react-popover';
import styles from './styles.module.scss';

function SearchInput({
  inputProps,
  buttonProps,
  buttonLabel,
  searchResults,
  popoverTarget,
  onOuterAction,
}) {
  return (
    <Popover
      isOpen={!!searchResults}
      preferPlace="below"
      tipSize={0.01}
      onOuterAction={onOuterAction}
      target={popoverTarget}
      body={(
        <Panel className={styles.popoverPanel}>
          {searchResults}
        </Panel>
      )}
    >
      <Field kind="addons">
        <Control iconLeft fullwidth>
          <Input
            {...inputProps}
          />
          <Icon align="left" size="small">
            <span className="fa fa-search" />
          </Icon>
        </Control>
        <Control>
          <Button color="primary" {...buttonProps}>{buttonLabel}</Button>
        </Control>
      </Field>
    </Popover>
  );
}

SearchInput.propTypes = {
  inputProps: PropTypes.shape({}),
  buttonProps: PropTypes.shape({}),
  buttonLabel: PropTypes.string.isRequired,
  searchResults: PropTypes.node,
  popoverTarget: PropTypes.node,
  onOuterAction: PropTypes.func.isRequired,
};

SearchInput.defaultProps = {
  inputProps: {},
  buttonProps: {},
  searchResults: null,
  popoverTarget: undefined,
};

export default SearchInput;
