import React from 'react';
import Sockette from 'sockette';
import { v4 } from 'uuid';
import { shallowEqual, useSelector } from 'react-redux';
import { getAuthData } from '../utils/auth';
import { selectCurrentOrg } from '../reducers/user';

export const useWebSocketSubscription = channel => {
  const orgId = useSelector(selectCurrentOrg, shallowEqual);
  const [socket, setSocket] = React.useState(undefined);
  const [connected, setConnected] = React.useState(false);
  const [messages, setMessages] = React.useState([]);
  const [reconnectCount, setReconnectCount] = React.useState(0);

  React.useEffect(() => {
    if (!orgId || !channel) return undefined;

    let ws;
    (async () => {
      const session = await getAuthData();
      const token = session.token;
      ws = new Sockette(`${process.env.REACT_APP_WS_API_URL}/pubsub?token=${token}`, {
        onopen: () => {
          ws.json({
            message: 'subscribe',
            orgId,
            channel,
          });

          setSocket(ws);
          setConnected(true);
        },
        onmessage: ({ data }) => {
          const message = JSON.parse(data);
          setMessages(m => [...m, { ...message, id: message.id ? message.id : v4() }]);
        },
        onclose: () => {
          setConnected(false);
          setReconnectCount(v => v + 1);
        },
      });
    })();

    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, [orgId, channel, reconnectCount]);

  return {
    messages,
    socket,
    connected,
  };
};
