import React from 'react';
import PropTypes from 'prop-types';
import Panel from '@x-functions/freyja/lib/components/panel';
import Icon from '@x-functions/freyja/lib/components/icon';
import Tag from '@x-functions/freyja/lib/components/tag';
import useOrgFunctions from '../../../hooks/useOrgFunctions';

function MyFunctions({
  onClick,
  onDragStart,
}) {
  const {
    orgId,
    functions,
    nextToken,
    actions,
  } = useOrgFunctions();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (!orgId) return;

    actions.fetchOrganizationFunctions({ orgId, scope: 'public,private' });
  }, [orgId, actions]);

  const handleLoadMoreClick = React.useCallback(async () => {
    if (!nextToken) return;
    try {
      setLoading(true);
      await actions.fetchOrganizationFunctions({ orgId, token: nextToken });
    } finally {
      setLoading(false);
    }
  }, [nextToken, actions, orgId]);

  return (
    <>
      {functions.map(f => (
        <Panel.Block
          key={f.functionName}
          renderAs="a"
          onClick={() => onClick(f)}
          onDragStart={e => onDragStart(f, e)}
          draggable="true"
        >
          <Panel.Icon renderAs={Icon}>
            <span className="fa fa-cogs" />
          </Panel.Icon>
          <span style={{ marginRight: '10px' }}>
            {f.displayName}
          </span>
          <Tag.Group>
            <Tag rounded size="small" color={f.scope === 'public' ? 'primary' : ''}>{f.scope}</Tag>
          </Tag.Group>
        </Panel.Block>
      ))}
      {!loading && nextToken && (
        <Panel.Block
          renderAs="a"
          onClick={handleLoadMoreClick}
          style={{ backgroundColor: '#eaeaea' }}
        >
          <span style={{ marginRight: '10px' }}>
            Load more...
          </span>
        </Panel.Block>
      )}
    </>
  );
}

MyFunctions.propTypes = {
  onClick: PropTypes.func.isRequired,
  onDragStart: PropTypes.func.isRequired,
};

export default MyFunctions;
