import { shallowEqual, useSelector } from 'react-redux';
import { selectCurrentOrg } from '../reducers/user';
import useActions from './useActions';
import {
  fetchTemplate,
  saveTemplate,
  deleteTemplate,
  selectTemplate,
} from '../reducers/content';

const actionCreators = {
  fetchTemplate,
  saveTemplate,
  deleteTemplate,
};

export function useTemplateDetailsActions() {
  const actions = useActions(actionCreators);

  return actions;
}

function useTemplateDetails(templateId) {
  const orgId = useSelector(selectCurrentOrg, shallowEqual);
  const template = useSelector(state => selectTemplate(state, { orgId, templateId }), shallowEqual);
  const actions = useTemplateDetailsActions();

  return {
    orgId,
    template,
    actions,
  };
}

export default useTemplateDetails;
