import { shallowEqual, useSelector } from 'react-redux';
import useActions from './useActions';
import { selectCurrentOrg } from '../reducers/user';
import { selectOrgWebhook, fetchOrganizationWebhook } from '../reducers/orgWebhooks';

const builderActionCreators = {
  fetchOrganizationWebhook,
};

export function useWebhookActions() {
  const actions = useActions(builderActionCreators);

  return actions;
}

function useWebhook(webhookId) {
  const orgId = useSelector(selectCurrentOrg, shallowEqual);
  const webhook = useSelector(state => selectOrgWebhook(state, orgId, webhookId), shallowEqual);
  const actions = useWebhookActions();

  return {
    orgId,
    webhook,
    actions,
  };
}

export default useWebhook;
