import React from 'react';
import PropTypes from 'prop-types';
import Popover from 'react-popover';
import Icon from '@x-functions/freyja/lib/components/icon';
import Button from '@x-functions/freyja/lib/components/button';
import Panel from '@x-functions/freyja/lib/components/panel';
import Progress from '@x-functions/freyja/lib/components/progress';
import styles from './styles.module.scss';

function ContextMenu({ orgId, workflowId, deleteWorkflow }) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);

  const handleContextMenuClick = React.useCallback(e => {
    e.preventDefault();
    e.stopPropagation();

    setMenuOpen(!menuOpen);
  }, [menuOpen]);

  const handleDeleteWorkflowClick = React.useCallback(async e => {
    e.preventDefault();
    e.stopPropagation();

    setDeleting(true);
    try {
      await deleteWorkflow({ orgId, workflowId });
      setDeleting(false);
    } catch (err) {
      setDeleting(false);
      window.alert(`Failed to delete workflow: ${err.message}`);
    }
  }, [orgId, workflowId, deleteWorkflow]);
  return (
    <>
      <Popover
        isOpen={menuOpen}
        onOuterAction={handleContextMenuClick}
        body={(
          <Panel className={styles.contextMenuBody}>
            {deleting && (
              <Panel.Block>
                <Progress size="medium" value={undefined} style={{ width: '120px' }} />
              </Panel.Block>
            )}
            {!deleting && (
              <Panel.Block renderAs="a" onClick={!deleting && handleDeleteWorkflowClick}>
                <Panel.Icon renderAs={Icon}>
                  <span className="fa fa-trash-alt" />
                </Panel.Icon>
                Delete workflow
              </Panel.Block>
            )}
          </Panel>
        )}
      >
        <Button
          onClick={handleContextMenuClick}
          className={styles.contextMenuButton}
          outlined
        >
          <Icon><span className="fa fa-ellipsis-v" /></Icon>
        </Button>
      </Popover>
    </>
  );
}

ContextMenu.propTypes = {
  orgId: PropTypes.string.isRequired,
  workflowId: PropTypes.string.isRequired,
  deleteWorkflow: PropTypes.func.isRequired,
};

export default ContextMenu;
