import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@x-functions/freyja/lib/components/tabs';
import { raiseEvent } from '@x-functions/website-analytics-sdk';
import Form from './form';
import Json from './json';
import styles from './styles.module.scss';

function FormFromSchema({
  schema,
  typeName = '',
  InputComponent,
  fieldClassName,
  ignoreJsonPaths,
  forwardInputSchema,
  noJson,
  values,
  setValue,
  setValues,
  inputProps,
  ...other
}) {
  const [mode, setMode] = React.useState('kv');

  return (
    <>
      {noJson ? null : (
        <Tabs className={styles.tabs}>
          <Tabs.Tab
            active={mode === 'kv'}
            onClick={() => {
              setMode('kv');
              raiseEvent('toggletestinputtype', {
                page: 'functions',
                mode: 'kv',
                component: 'FormFromSchema',
              });
            }}
          >
            Key Value
          </Tabs.Tab>
          <Tabs.Tab
            active={mode === 'json'}
            onClick={() => {
              setMode('json');
              raiseEvent('toggletestinputtype', {
                page: 'functions',
                mode: 'json',
                component: 'FormFromSchema',
              });
            }}
          >
            JSON
          </Tabs.Tab>
        </Tabs>
      )}
      {mode === 'kv' ? (
        <Form
          schema={schema}
          typeName={typeName}
          fieldClassName={fieldClassName}
          values={values}
          setValue={setValue}
          InputComponent={InputComponent}
          inputProps={inputProps}
          ignoreJsonPaths={ignoreJsonPaths}
          forwardInputSchema={forwardInputSchema}
          {...other}
        />
      ) : (
        <Json
          schema={schema}
          values={values}
          setValues={setValues}
        />
      )}
    </>
  );
}

FormFromSchema.propTypes = {
  schema: PropTypes.shape({
    properties: PropTypes.shape({}),
  }),
  typeName: PropTypes.string,
  fieldClassName: PropTypes.string,
  values: PropTypes.shape({}).isRequired,
  ignoreJsonPaths: PropTypes.bool,
  forwardInputSchema: PropTypes.bool,
  noJson: PropTypes.bool,
  setValue: PropTypes.func,
  setValues: PropTypes.func,
  inputProps: PropTypes.shape({}),
  InputComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

FormFromSchema.defaultProps = {
  schema: undefined,
  typeName: '',
  fieldClassName: undefined,
  setValue: undefined,
  setValues: undefined,
  ignoreJsonPaths: false,
  forwardInputSchema: false,
  noJson: false,
  inputProps: {},
  InputComponent: 'input',
};

export default FormFromSchema;
