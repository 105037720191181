import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Heading from '@x-functions/freyja/lib/components/heading';
import { withRouter } from 'react-router-dom';
import { workflowType } from '@x-functions/x-react-workflow/lib/components/workflow/types';
import { Tile } from '../../../../components/tiles';
import ContextMenu from './contextMenu';
import styles from './styles.module.scss';

function ListItem({
  workflow,
  hideContextMenu,
  history,
  ...rest
}) {
  const handleTileClick = React.useCallback(e => {
    e.preventDefault();

    history.push(`/workflows/${workflow.workflowId}`);
  }, [workflow, history]);

  return (
    <Tile onClick={handleTileClick} className={styles.tile}>
      {!hideContextMenu && <ContextMenu {...rest} />}
      <Heading>{workflow.workflowName}</Heading>
      <Heading subtitle>{moment(workflow.createdAt).fromNow()}</Heading>
    </Tile>
  );
}

ListItem.propTypes = {
  workflow: workflowType.isRequired,
  hideContextMenu: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

ListItem.defaultProps = {
  hideContextMenu: false,
};

export default withRouter(ListItem);
