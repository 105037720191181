import { shallowEqual, useSelector } from 'react-redux';
import { selectCurrentOrg } from '../reducers/user';
import useActions from './useActions';
import {
  selectNode,
  selectGroupId,
  selectSelectedNode,
} from '../reducers/workflowEditor';
import {
  fetchOrganizationWebhooks,
  setOrgWebhooksFilter,
  createOrganizationWebhook,
  selectOrgWebhooksFilter,
  selectFilteredOrgWebhooks,
} from '../reducers/orgWebhooks';

const actionCreators = {
  selectNode,
  fetchOrganizationWebhooks,
  setOrgWebhooksFilter,
  createOrganizationWebhook,
};

export function useOrgWebhooksActions() {
  const actions = useActions(actionCreators);

  return actions;
}

function useOrgWebhooks() {
  const orgId = useSelector(selectCurrentOrg, shallowEqual);
  const selectedNode = useSelector(selectSelectedNode, shallowEqual);
  const groupId = useSelector(selectGroupId, shallowEqual);
  const filter = useSelector(state => selectOrgWebhooksFilter(state, orgId), shallowEqual);
  const filteredWebhooks = useSelector(selectFilteredOrgWebhooks, shallowEqual);
  const actions = useOrgWebhooksActions();

  return {
    orgId,
    selectedNode,
    groupId,
    filter,
    filteredWebhooks,
    actions,
  };
}

export default useOrgWebhooks;
