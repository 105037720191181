import React from 'react';
import PropTypes from 'prop-types';
import Panel from '@x-functions/freyja/lib/components/panel';
import {
  Field,
  Label,
  Control,
  Input,
  Select,
} from '@x-functions/freyja/lib/components/form';
import { workflowNodeType } from '@x-functions/x-react-workflow/lib/components/workflow/types';
import FieldPickerInput from '../fieldPicker';

function MapInspector({
  node,
  updateNode,
}) {
  const handleValueChange = React.useCallback(e => {
    const { name, value } = e.target;
    updateNode({ id: node.id, changes: { [name]: value } });
  }, [node.id, updateNode]);

  return (
    <>
      <Panel.Block>
        <Field fullwidth>
          <Label>Step Name</Label>
          <Control fullwidth>
            <Input fullwidth name="name" id="name" value={node.name} onChange={handleValueChange} />
          </Control>
        </Field>
      </Panel.Block>
      <Panel.Header>
        For Each Element in List
      </Panel.Header>
      <Panel.Block>
        <Field fullwidth>
          <Label>First node</Label>
          <Control fullwidth>
            <Select name="firstNode" id="firstNode" value={node.firstNode} onChange={handleValueChange}>
              {Object.values(node.nodes || {}).map(n => (
                <option key={n.id} value={n.id}>{n.name}</option>
              ))}
            </Select>
          </Control>
        </Field>
      </Panel.Block>
      <Panel.Block>
        <Field fullwidth>
          <Label>List</Label>
          <Control fullwidth>
            <FieldPickerInput fullwidth name="list" id="list" type value={node.list} onChange={handleValueChange} currentNodeId={node.id} />
          </Control>
        </Field>
      </Panel.Block>
      <Panel.Block>
        <Field fullwidth>
          <Label>Concurrency</Label>
          <Control fullwidth>
            <Input fullwidth name="concurrency" id="concurrency" type="number" value={node.concurrency} onChange={handleValueChange} />
          </Control>
        </Field>
      </Panel.Block>

    </>
  );
}

MapInspector.propTypes = {
  node: workflowNodeType.isRequired,
  updateNode: PropTypes.func.isRequired,
};

MapInspector.defaultProps = {
};

export default MapInspector;
