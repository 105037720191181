import React from 'react';
import PropTypes from 'prop-types';
import Menu from '@x-functions/freyja/lib/components/menu';

function MapFields({ node, onClick }) {
  const makeItemClickHandler = propertyName => () => {
    onClick(propertyName);
  };

  return (
    <Menu>
      <Menu.List title={node.name}>
        <Menu.List.Item onClick={makeItemClickHandler('$.mapItemIndex')}>
          Item Index
        </Menu.List.Item>
        <Menu.List.Item onClick={makeItemClickHandler('$.mapItemValue')}>
          Item Value
        </Menu.List.Item>
      </Menu.List>
    </Menu>
  );
}

MapFields.propTypes = {
  node: PropTypes.shape({
    name: PropTypes.string,
    function: PropTypes.string,
    output: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

MapFields.defaultProps = {};


export default MapFields;
