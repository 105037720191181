import React from 'react';
import PropTypes from 'prop-types';
import DateTimePicker from 'react-datetime';
import moment from 'moment';
import 'moment/locale/en-au';
import 'react-datetime/css/react-datetime.css';

moment.locale('en-au');

function DatePicker({
  id,
  name,
  value,
  onChange,
  ...rest
}) {
  const handleDateChange = React.useCallback(newDate => {
    onChange({
      target: {
        name,
        value: (newDate && newDate.isValid() && newDate.toISOString()) || null,
      },
    });
  }, [onChange, name]);

  return (
    <DateTimePicker
      value={value && moment(value)}
      onChange={handleDateChange}
      locale="en-au"
      inputProps={{
        className: 'input',
      }}
      id={id}
      {...rest}
    />
  );
}

DatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

DatePicker.defaultProps = {
  name: '',
  value: '',
  onChange: undefined,
};

export default DatePicker;
