import React from 'react';
import PropTypes from 'prop-types';
import Button from '@x-functions/freyja/lib/components/button';
import {
  Field,
  Control,
  Input,
  Select,
} from '@x-functions/freyja/lib/components/form';
import styles from './styles.module.scss';

const pluralize = (number, singular, plural) => {
  if (Number(number) === 1) return singular;

  return plural;
};

const removeLastChar = (fromStr, toRemove) => {
  if (fromStr.charAt(fromStr.length - 1) === toRemove) {
    return fromStr.substr(0, fromStr.length - 1);
  }

  return fromStr;
};

const defaultValue = { scheduleValue: '1', valueType: 'minute' };

const parseValue = value => {
  const match = (value || '').match(/rate\((\d+) (\w+)\)/);
  if (match && match.length >= 3) {
    // eslint-disable-next-line prefer-const
    let [scheduleValue, valueType] = match.slice(1);
    valueType = removeLastChar(valueType, 's');
    return ({ scheduleValue, valueType });
  }
  return defaultValue;
};

function RateEditor({ value, onChange }) {
  const [redactedValue, setRedactedValue] = React.useState(value);

  const [values, setValues] = React.useState(parseValue(value));

  const handleValueChange = React.useCallback(e => {
    const { target: { name, value: val } } = e;
    setValues(v => ({ ...v, [name]: val }));
  }, []);

  React.useEffect(() => {
    const scheduleValue = Number(values.scheduleValue);
    const type = pluralize(scheduleValue, values.valueType, `${values.valueType}s`);
    const calculated = `rate(${scheduleValue} ${type})`;
    setRedactedValue(calculated);
  }, [values.scheduleValue, values.valueType, onChange]);

  React.useEffect(() => {
    setValues(parseValue(value));
  }, [value]);

  React.useEffect(() => {
    onChange(redactedValue);
  }, [redactedValue, onChange]);

  return (
    <Field kind="addons" className={styles.field}>
      <Control>
        <Button isStatic>Every</Button>
      </Control>
      <Control fullwidth>
        <Input type="number" name="scheduleValue" min={1} max={999999} value={values.scheduleValue} onChange={handleValueChange} />
      </Control>
      <Control>
        <Select name="valueType" value={values.valueType} onChange={handleValueChange}>
          <option value="minute">{pluralize(values.scheduleValue, 'Minute', 'Minutes')}</option>
          <option value="hour">{pluralize(values.scheduleValue, 'Hour', 'Hours')}</option>
          <option value="day">{pluralize(values.scheduleValue, 'Day', 'Days')}</option>
        </Select>
      </Control>
    </Field>
  );
}

RateEditor.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RateEditor;
