import axios from 'axios';
import { getAuthData } from './auth';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(async config => {
  const authData = await getAuthData();
  const idToken = authData.token;
  // eslint-disable-next-line no-param-reassign
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${idToken}`,
  };

  return config;
});

export default axios;
