import React from 'react';
import PropTypes from 'prop-types';
import Tag from '@x-functions/freyja/lib/components/tag';
import Button from '@x-functions/freyja/lib/components/button';
import {
  Field,
  Label,
  Control,
  Input,
} from '@x-functions/freyja/lib/components/form';
import Drawer from '../../../../components/drawer';
import styles from './header.module.scss';

function TemplateHeader({
  isNew,
  values,
  setValues,
  onChange,
}) {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [newTag, setNewTag] = React.useState('');

  const handleNewTagChanged = React.useCallback(e => {
    const { target: { value } } = e;
    setNewTag(value);
  }, []);

  const handleNewTagAdded = React.useCallback(() => {
    if (newTag === '') return;

    setValues(val => ({
      ...val,
      tags: (val.tags || []).filter(t => t !== newTag).concat(newTag),
    }));
    setNewTag('');
  }, [newTag, setValues]);

  const removeTag = tag => () => {
    setValues(val => ({
      ...val,
      tags: (val.tags || []).filter(t => t !== tag),
    }));
  };

  const handleTagInputKeyPress = React.useCallback(e => {
    if (e.key === 'Enter') {
      handleNewTagAdded();
    }
  }, [handleNewTagAdded]);

  return (
    <div className={styles.root}>
      {!isNew && (
        <span>{values && values.templateId}</span>
      )}
      {isNew && (
        <Field>
          <Control>
            <Input
              name="templateId"
              value={values && values.templateId}
              onChange={onChange}
              placeholder="Template ID"
            />
          </Control>
        </Field>
      )}
      <Button onClick={() => setDrawerOpen(true)}>Tags</Button>
      <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <Label htmlFor="newTag">Tags</Label>
        <Field kind="addons">
          <Control>
            <Input
              placeholder="new_tag"
              name="newTag"
              value={newTag}
              onChange={handleNewTagChanged}
              onKeyPress={handleTagInputKeyPress}
            />
          </Control>
          <Control>
            <Button onClick={handleNewTagAdded} disabled={!newTag}>Add</Button>
          </Control>
        </Field>
        <Tag.Group>
          {values.tags && values.tags.map(tag => (
            <Tag key={tag} size="medium" rounded>{tag} <Button remove size="small" onClick={removeTag(tag)} /></Tag>
          ))}
        </Tag.Group>
      </Drawer>
    </div>
  );
}

TemplateHeader.propTypes = {
  isNew: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    templateId: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
};

export default TemplateHeader;
