import React from 'react';
import PropTypes from 'prop-types';
import Popover from 'react-popover';
import Button from '@x-functions/freyja/lib/components/button';
import Panel from '@x-functions/freyja/lib/components/panel';
import Icon from '@x-functions/freyja/lib/components/icon';
import { Control } from '@x-functions/freyja/lib/components/form';
import useCurrentOrg from '../../../../../../hooks/useCurrentOrg';
import useOrgIntegrations from '../../../../../../hooks/useOrgIntegrations';

function VaultPicker({
  config,
  // payload,
  onPick,
}) {
  const [orgId] = useCurrentOrg();
  const [contextMenuOpen, setContextMenuOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [integrations, actions] = useOrgIntegrations();

  const { appName } = config || {};

  const createOnClickHandler = React.useCallback((handler, item) => () => {
    handler(item);
    setContextMenuOpen(false);
  }, []);

  const handleContextMenuClick = React.useCallback(e => {
    e.preventDefault();
    e.stopPropagation();

    setContextMenuOpen(v => !v);
  }, []);

  React.useEffect(() => {
    if (!appName) {
      setLoading(false);
      return;
    }


    actions.fetchOrganizationIntegrations({
      orgId,
      appName,
    }).then(() => setLoading(false));
  }, [appName, actions, orgId]);

  return (
    <Control>
      <Popover
        isOpen={contextMenuOpen}
        onOuterAction={handleContextMenuClick}
        preferPlace="below"
        enterExitTransitionDurationMs={0}
        tipSize={0.01}
        className="above-all"
        body={(
          <Panel className="has-background-white">
            {integrations?.data?.map(int => (
              <Panel.Block
                renderAs="a"
                key={int.key}
                onClick={createOnClickHandler(onPick, int)}
              >
                {int.description || int.key}
              </Panel.Block>
            )) || null}
            <Panel.Block>
              <Button color="primary" fullwidth>Create new</Button>
            </Panel.Block>
          </Panel>
        )}
      >
        <Button
          onClick={handleContextMenuClick}
          disabled={loading}
        >
          <Icon><i className="material-icons md-16">lock</i></Icon>
        </Button>
      </Popover>
    </Control>
  );
}

VaultPicker.propTypes = {
  config: PropTypes.shape({
    type: PropTypes.string.isRequired,
    appName: PropTypes.string,
  }),
  payload: PropTypes.shape({}),
  onPick: PropTypes.func.isRequired,
};

VaultPicker.defaultProps = {
  config: undefined,
  payload: {},
};

export default VaultPicker;
