export const normalize = (arr, idField = 'id') => {
  const ids = arr.map(el => el[idField]);
  const normalized = arr.reduce((acc, curr) => ({
    ...acc,
    [curr[idField]]: curr,
  }), {});

  return [normalized, ids];
};

export const denormalize = (normalized, ids = []) => {
  if (!ids || !ids.length) {
    return Object.values(normalized);
  }

  return ids.map(id => normalized[id]);
};
