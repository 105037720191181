import React from 'react';
import Section from '@x-functions/freyja/lib/components/section';
import Heading from '@x-functions/freyja/lib/components/heading';
import { TileContainer, LoadingTile, Tile } from '../../../components/tiles';
import ListItem from './listItem';
import CreateNew from './createNew';
import useOrgWorkflows from '../../../hooks/useOrgWorkflows';

function WorkflowList() {
  const {
    orgId,
    workflows,
    nextToken,
    actions,
  } = useOrgWorkflows();

  const [loading, setLoading] = React.useState(false);

  const handleLoadMoreClick = React.useCallback(async () => {
    if (!nextToken) return;
    try {
      setLoading(true);
      await actions.fetchOrgWorkflows({ orgId, token: nextToken });
    } finally {
      setLoading(false);
    }
  }, [nextToken, actions, orgId]);

  React.useEffect(() => {
    if (!orgId) return;

    (async () => {
      setLoading(true);
      try {
        await actions.fetchOrgWorkflows({ orgId });
      } finally {
        setLoading(false);
      }
    })();
  }, [orgId, actions]);

  return (
    <Section>
      <Heading>Workflows</Heading>
      <TileContainer>
        <CreateNew />
        {workflows && workflows.map(w => (
          <ListItem
            key={w.workflowId}
            workflow={w}
            workflowId={w.workflowId}
            orgId={orgId}
            deleteWorkflow={actions.deleteWorkflow}
          />
        ))}
        {loading && (
          <LoadingTile />
        )}
        {!loading && nextToken && (
          <Tile onClick={handleLoadMoreClick} color="info">
            <Heading>Load More</Heading>
          </Tile>
        )}
      </TileContainer>
    </Section>
  );
}

export default WorkflowList;
