import React from 'react';
import PropTypes from 'prop-types';
import Panel from '@x-functions/freyja/lib/components/panel';
import Content from '@x-functions/freyja/lib/components/content';
import { workflowNodeType } from '@x-functions/x-react-workflow/lib/components/workflow/types';
import SearchInput from '../../../../../components/searchInput/component';
import WebhookCreator from './webhookCreator/component';
import styles from './styles.module.scss';

function WebhookSelector({
  node,
  updateNode,
  currentOrg,
  filter,
  filteredWebhooks,
  setOrgWebhooksFilter,
  createOrganizationWebhook,
}) {
  const [showModal, setShowModal] = React.useState(false);
  const [showResults, setShowResults] = React.useState(false);

  const handleFilterChange = React.useCallback(e => {
    const { value } = e.target;
    setOrgWebhooksFilter({ orgId: currentOrg, filter: value });
    setShowResults(true);
  }, [currentOrg, setOrgWebhooksFilter]);

  const handleEnter = React.useCallback(() => {
    setShowResults(true);
  }, []);

  const handleOuterAction = React.useCallback(() => {
    setShowResults(false);
  }, []);

  const handleCreateWebhook = React.useCallback(async webhook => {
    await createOrganizationWebhook({ orgId: currentOrg, webhook });
    updateNode({ id: node.id, changes: { webhookId: webhook.webhookId } });
  }, [currentOrg, node.id, updateNode, createOrganizationWebhook]);

  const getWebhookClickHandler = webhook => () => {
    updateNode({ id: node.id, changes: { webhookId: webhook.webhookId } });
    setShowResults(false);
  };

  const handleCloseModal = React.useCallback(e => {
    e.preventDefault();
    e.stopPropagation();

    setShowModal(false);
  }, []);

  return (
    <Panel.Block>
      <SearchInput
        buttonLabel="Create new"
        onOuterAction={handleOuterAction}
        inputProps={{
          placeholder: 'Search webhook...',
          name: 'webhookSearch',
          id: 'webhookSearch',
          size: 'fullwidth',
          value: filter,
          onChange: handleFilterChange,
          onFocus: handleEnter,
        }}
        buttonProps={{
          onClick: () => setShowModal(true),
        }}
        searchResults={showResults && filteredWebhooks && filteredWebhooks.length && filteredWebhooks.map(webhook => (
          <Panel.Block renderAs="a" key={webhook.webhookId} onClick={getWebhookClickHandler(webhook)}>
            <Content>
              <h6 className={styles.title}>{webhook.name}</h6>
              <p>{webhook.description}</p>
            </Content>
          </Panel.Block>
        ))}
      />
      <WebhookCreator show={showModal} onClose={handleCloseModal} onSave={handleCreateWebhook} />
    </Panel.Block>
  );
}

WebhookSelector.propTypes = {
  node: workflowNodeType.isRequired,
  currentOrg: PropTypes.string.isRequired,
  filter: PropTypes.string.isRequired,
  updateNode: PropTypes.func.isRequired,
  filteredWebhooks: PropTypes.func.isRequired,
  setOrgWebhooksFilter: PropTypes.func.isRequired,
  createOrganizationWebhook: PropTypes.func.isRequired,
};

export default WebhookSelector;
