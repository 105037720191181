import React from 'react';
import PropTypes from 'prop-types';
import Button from '@x-functions/freyja/lib/components/button';
import Icon from '@x-functions/freyja/lib/components/icon';
import { get } from 'lodash/fp';
import { Control } from '@x-functions/freyja/lib/components/form';
import GooglePickerContext from '../../../../../../components/googlePicker/context';
import { fetchOrganizationIntegration } from '../../../../../../reducers/orgIntegrations';
import useCurrentOrg from '../../../../../../hooks/useCurrentOrg';

function GooglePicker({
  config,
  payload,
  onPick,
}) {
  const googlePicker = React.useContext(GooglePickerContext);
  const [orgId] = useCurrentOrg();
  const [vaultKey, setVaultKey] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (!config?.vaultKey) return;

    setVaultKey(get(config.vaultKey.split('.'), payload));
  }, [config, payload]);

  const handleGooglePickClick = React.useCallback(async () => {
    if (!googlePicker.initialized) return;
    if (!vaultKey) return;

    setLoading(true);
    try {
      const vaultItem = await fetchOrganizationIntegration({ orgId, key: vaultKey });

      const result = await googlePicker.pick({
        authToken: vaultItem.value,
        selectFolders: config.selectFolders,
        mimeTypes: config.mimeTypes,
      });

      if (result?.action === 'picked') {
        onPick(result.docs[0]);
      }
    } finally {
      setLoading(false);
    }
  }, [googlePicker, vaultKey, orgId, onPick, config]);

  return (
    <Control>
      <Button
        onClick={handleGooglePickClick}
        disabled={!vaultKey || !googlePicker.initialized || loading}
      >
        <Icon><i className="material-icons md-16">folder</i></Icon>
      </Button>
    </Control>
  );
}

GooglePicker.propTypes = {
  config: PropTypes.shape({
    type: PropTypes.string.isRequired,
    mimeTypes: PropTypes.string,
    selectFolders: PropTypes.bool,
    vaultKey: PropTypes.string,
  }),
  payload: PropTypes.shape({}),
  onPick: PropTypes.func.isRequired,
};

GooglePicker.defaultProps = {
  config: undefined,
  payload: {},
};

export default GooglePicker;
