import { createAction, createReducer } from 'redux-act';
import axios from '../utils/api';

const setUserDetails = createAction('/users/details/set');
const setUserObject = createAction('/users/details/object/set');
const setUserAttributeAction = createAction('/users/attribute/set');
export const addUserAttribute = createAction('/users/attribute/add');
const deleteUserAttributeAction = createAction('/users/attribute/delete');
const setUserTouchpointAction = createAction('/users/touchpoint/set');
const deleteUserTouchpointAction = createAction('/users/touchpoint/delete');
/** Selectors */

const selectState = state => state.userDetails;

export const selectUserDetails = (state, { orgId, userId }) => selectState(state)[`${orgId}/${userId}`] || {};

/** Action dispatchers */

export const fetchUserDetails = ({ orgId, userId }) => async dispatch => {
  const { data } = await axios.get(`/audience/orgs/${orgId}/users/${userId}`);
  dispatch(setUserDetails({ orgId, userId, data }));
  return data;
};

export const createUser = ({ orgId, userId, name }) => async dispatch => {
  const { data } = await axios.post(`/audience/orgs/${orgId}/users`, { orgId, userId, name });
  dispatch(setUserObject({ orgId, userId, data }));
  return data;
};

export const updateUserObject = ({ orgId, userId, changes }) => async dispatch => {
  const { data } = await axios.put(`/audience/orgs/${orgId}/users/${userId}`, changes);
  dispatch(setUserObject({ orgId, userId, data }));
  return data;
};

export const deleteUserAttribute = ({ orgId, userId, attributeName }) => async (dispatch, getState) => {
  const state = getState();
  const oldAttribute = (selectUserDetails(state, { orgId, userId }).attributes || []).find(a => a.attributeName === attributeName);

  // Optimistic delete
  dispatch(deleteUserAttributeAction({ orgId, userId, attributeName }));

  try {
    await axios.post(`audience/orgs/${orgId}/users/${userId}/attributes/${attributeName}`, { attributeValues: [] });
  } catch (err) {
    // revert optimistic update
    dispatch(setUserAttributeAction({
      orgId,
      userId,
      attributeName,
      data: oldAttribute,
    }));
    throw err;
  }
};

export const setUserAttribute = ({
  orgId,
  userId,
  attributeName,
  attributeValues,
}) => async (dispatch, getState) => {
  const state = getState();
  const oldAttribute = (selectUserDetails(state, { orgId, userId }).attributes || []).find(a => a.attributeName === attributeName);
  const newAttribute = {
    orgId,
    userId,
    attributeName,
    attributeValues,
  };

  // Optimistic update
  dispatch(setUserAttributeAction({
    orgId,
    userId,
    attributeName,
    data: newAttribute,
  }));

  try {
    const { data } = await axios.post(`audience/orgs/${orgId}/users/${userId}/attributes/${attributeName}`, { attributeValues });
    dispatch(setUserAttributeAction({
      orgId,
      userId,
      attributeName,
      data,
    }));
  } catch (err) {
    // revert optimistic update
    dispatch(setUserAttributeAction({
      orgId,
      userId,
      attributeName,
      data: oldAttribute,
    }));
    throw err;
  }
};

export const setUserTouchpoint = ({
  orgId,
  userId,
  touchpointId,
  touchpointData,
}) => async (dispatch, getState) => {
  const state = getState();
  const oldTouchpoint = (selectUserDetails(state, { orgId, userId }).touchpoints || []).find(a => a.touchpointId === touchpointId);
  const newTouchpoint = {
    orgId,
    userId,
    touchpointId,
    ...touchpointData,
  };

  // Optimistic update
  dispatch(setUserTouchpointAction({
    orgId,
    userId,
    touchpointId,
    data: newTouchpoint,
  }));

  try {
    const { data } = await axios.post(`audience/orgs/${orgId}/users/${userId}/touchpoints/${touchpointId}`, newTouchpoint);
    dispatch(setUserTouchpointAction({
      orgId,
      userId,
      touchpointId,
      data,
    }));
  } catch (err) {
    // revert optimistic update
    dispatch(setUserTouchpointAction({
      orgId,
      userId,
      touchpointId,
      data: oldTouchpoint,
    }));
    throw err;
  }
};

export const deleteUserTouchpoint = ({ orgId, userId, touchpointId }) => async (dispatch, getState) => {
  const state = getState();
  const oldTouchpoint = (selectUserDetails(state, { orgId, userId }).touchpoints || []).find(a => a.touchpointId === touchpointId);

  // Optimistic delete
  dispatch(deleteUserTouchpointAction({ orgId, userId, touchpointId }));

  try {
    await axios.delete(`audience/orgs/${orgId}/users/${userId}/touchpoints/${touchpointId}`);
  } catch (err) {
    // revert optimistic update
    dispatch(setUserTouchpointAction({
      orgId,
      userId,
      touchpointId,
      data: oldTouchpoint,
    }));
    throw err;
  }
};


export default createReducer({
  [setUserDetails]: (state, { orgId, userId, data }) => ({
    ...state,
    [`${orgId}/${userId}`]: data,
  }),
  [setUserObject]: (state, { orgId, userId, data }) => {
    const key = `${orgId}/${userId}`;
    const details = state[key] || {};

    return {
      ...state,
      [key]: {
        ...details,
        user: data,
      },
    };
  },
  [addUserAttribute]: (state, { orgId, userId, attributeName }) => {
    const key = `${orgId}/${userId}`;
    const details = state[key] || {};
    const attributes = details.attributes || [];
    const existingValues = (attributes.find(a => a.attributeName === attributeName) || {}).attributeValues;
    const attribute = {
      orgId,
      userId,
      attributeName,
      attributeValues: existingValues || [],
    };
    return {
      ...state,
      [key]: {
        ...details,
        attributes: [
          attribute,
          ...attributes.filter(a => a.attributeName !== attributeName),
        ],
      },
    };
  },
  [setUserAttributeAction]: (state, {
    orgId,
    userId,
    attributeName,
    data,
  }) => {
    const key = `${orgId}/${userId}`;
    const details = state[key] || {};
    const attributes = details.attributes || [];
    const index = attributes.findIndex(attr => attr.attributeName === attributeName);

    return {
      ...state,
      [key]: {
        ...details,
        attributes: [
          ...attributes.slice(0, index),
          data,
          ...attributes.slice(index + 1),
        ],
      },
    };
  },
  [deleteUserAttributeAction]: (state, { orgId, userId, attributeName }) => {
    const key = `${orgId}/${userId}`;
    const details = state[key] || {};
    const attributes = details.attributes || [];

    return {
      ...state,
      [key]: {
        ...details,
        attributes: [...attributes.filter(a => a.attributeName !== attributeName)],
      },
    };
  },
  [setUserTouchpointAction]: (state, {
    orgId,
    userId,
    touchpointId,
    data,
  }) => {
    const key = `${orgId}/${userId}`;
    const details = state[key] || {};
    const touchpoints = details.touchpoints || [];
    const index = touchpoints.findIndex(ep => ep.touchpointId === touchpointId);

    return {
      ...state,
      [key]: {
        ...details,
        touchpoints: [
          ...touchpoints.slice(0, index),
          data,
          ...touchpoints.slice(index + 1),
        ],
      },
    };
  },
  [deleteUserTouchpointAction]: (state, { orgId, userId, touchpointId }) => {
    const key = `${orgId}/${userId}`;
    const details = state[key] || {};
    const touchpoints = details.touchpoints || [];

    return {
      ...state,
      [key]: {
        ...details,
        touchpoints: [...touchpoints.filter(a => a.touchpointId !== touchpointId)],
      },
    };
  },
}, {});
