import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useBeePlugin from '../../hooks/useBeePlugin';
import styles from './styles.module.scss';

function HtmlEditor({
  id,
  className,
  editorType,
  jsonTemplate,
  renderButtons,
  onSave,
  onSend,
  onError,
}) {
  const [bee, ready] = useBeePlugin({
    editorType,
    containerId: id,
    jsonTemplate,
    onSave,
    onSend,
    onError,
  });

  return (
    <div className={classNames(styles.root, className)}>
      <div id={id} className={styles.editor} />
      <div className={styles.buttons}>
        {renderButtons(bee, ready)}
      </div>
    </div>
  );
}

HtmlEditor.propTypes = {
  id: PropTypes.string,
  editorType: PropTypes.oneOf(['email', 'page']).isRequired,
  jsonTemplate: PropTypes.shape({}).isRequired,
  className: PropTypes.string,
  renderButtons: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

HtmlEditor.defaultProps = {
  id: 'html-editor-container',
  className: undefined,
};

export default HtmlEditor;
