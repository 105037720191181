import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Ajv from 'ajv';
import { Select } from '@x-functions/freyja/lib/components/form';
import { getSchemaTypeDefinitions, getPropertyFromSchema } from '../../utils/jsonSchemas';
import KeyValue from './keyValue';
import LongForm from './longForm';
import { createParser, getFieldName } from './utils';

const ajv = new Ajv();

function Form({
  longForm,
  schema,
  typeName = '',
  values,
  setValue,
  inputProps,
  ignoreJsonPaths,
  InputComponent,
  forwardInputSchema,
  ...other
}) {
  const [fields, setFields] = React.useState([]);
  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    if (!schema) return;

    const f = getSchemaTypeDefinitions(schema, typeName);
    setFields(f);
  }, [schema, typeName]);

  const validateField = React.useCallback((name, value) => {
    if (ignoreJsonPaths && value?.startsWith && value?.startsWith('$.')) return undefined;

    const field = fields.find(f => f.name === name);
    if (!value && !field.required) return undefined;

    const prop = getPropertyFromSchema(schema, name);

    if (!prop || ajv.validate(prop, value)) return undefined;
    return ajv.errors.map(e => {
      if (e?.keyword === 'pattern' && prop.errorMessage) {
        return prop.errorMessage;
      }

      if (e?.params?.allowedValues) {
        return `Allowed values: [${e.params.allowedValues.join(', ')}]`;
      }

      if (e.dataPath) {
        return `${e.dataPath}: ${e.message}`;
      }

      return e.message;
    });
  }, [schema, fields, ignoreJsonPaths]);

  const parseFieldValue = React.useCallback((name, value) => {
    const field = fields.find(f => f.name === name);
    let transformer = v => v;
    switch (field?.type) {
      case 'number':
        transformer = Number;
        break;
      case 'boolean':
        transformer = v => {
          const normalized = v?.toLowerCase();
          switch (normalized) {
            case 'true': return true;
            case 'false': return false;
            default: return v;
          }
        };
        break;
      case 'object':
      case 'array':
        transformer = JSON.parse.bind(JSON);
        break;
      default:
        transformer = v => v;
    }

    return createParser(transformer)(value);
  }, [fields]);

  const handleValueChanged = React.useCallback(e => {
    const { target: { name, value } } = e;
    const fieldErrors = errors[name];

    if (fieldErrors) {
      const parsedValue = parseFieldValue(name, value);
      setErrors(err => ({
        ...err,
        [getFieldName(name)]: validateField(name, parsedValue),
      }));
    }

    return setValue(e);
  }, [errors, setValue, validateField, parseFieldValue]);

  const handleFieldBlur = React.useCallback(e => {
    if (!e?.target?.name) return;

    const { target: { name } } = e;
    const parsedValue = parseFieldValue(name, values[name]);
    setErrors(err => ({
      ...err,
      [name]: validateField(name, parsedValue),
    }));
    setValue({
      ...e,
      target: {
        ...e.target,
        name,
        value: parsedValue,
      },
    });
  }, [validateField, values, parseFieldValue, setValue]);

  const valueToString = React.useCallback((vals, field) => {
    if (field?.name in vals) {
      const val = vals[field?.name];
      if (val === undefined || val === null) return '';
      if (typeof val === 'string') return val || '';
      return JSON.stringify(val);
    }

    return '';
  }, []);


  const { className } = inputProps || {};

  const renderInput = (field, error = false) => {
    if (field.enum?.length > 0) {
      return (
        <Select
          {...inputProps}
          className={classNames('is-fullwidth', className)}
          type="text"
          name={field.name}
          value={valueToString(values, field)}
          onChange={handleValueChanged}
          onBlur={handleFieldBlur}
          placeholder={`${field.type}${field.required ? ' (required)' : ''}`}
          color={error ? 'danger' : undefined}
        >
          <option value="">(Select one)</option>
          {field.enum.map(e => (
            <option key={e} value={e}>{e}</option>
          ))}
        </Select>
      );
    }

    return (
      <InputComponent
        {...inputProps}
        className={classNames('input', className)}
        type="text"
        name={field.name}
        value={valueToString(values, field)}
        payload={values}
        onChange={handleValueChanged}
        onBlur={handleFieldBlur}
        placeholder={`${field.type}${field.required ? ' (required)' : ''}`}
        inputSchema={forwardInputSchema ? field : undefined}
        color={error ? 'danger' : undefined}
      />
    );
  };

  const Component = longForm ? LongForm : KeyValue;

  return (
    <Component
      {...other}
      fields={fields}
      errors={errors}
      renderInput={renderInput}
    />
  );
}

Form.propTypes = {
  longForm: PropTypes.bool,
  schema: PropTypes.shape({
    properties: PropTypes.shape({}),
  }),
  typeName: PropTypes.string,
  values: PropTypes.shape({ c: PropTypes.string }).isRequired,
  setValue: PropTypes.func,
  ignoreJsonPaths: PropTypes.bool,
  inputProps: PropTypes.shape({}),
  InputComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  forwardInputSchema: PropTypes.bool,
};

Form.defaultProps = {
  longForm: false,
  schema: undefined,
  typeName: '',
  setValue: undefined,
  inputProps: {},
  ignoreJsonPaths: false,
  forwardInputSchema: false,
};

export default Form;
