import { shallowEqual, useSelector } from 'react-redux';
import { selectCurrentOrg } from '../reducers/user';
import useActions from './useActions';
import {
  fetchContent,
  selectContent,
  selectNextToken,
} from '../reducers/content';

const builderActionCreators = {
  fetchContent,
};

export function useTemplatesActions() {
  const actions = useActions(builderActionCreators);

  return actions;
}

function useTemplates() {
  const orgId = useSelector(selectCurrentOrg, shallowEqual);
  const content = useSelector(state => selectContent(state, orgId), shallowEqual);
  const nextToken = useSelector(state => selectNextToken(state, orgId), shallowEqual);
  const actions = useTemplatesActions();

  return {
    orgId,
    content,
    nextToken,
    actions,
  };
}

export default useTemplates;
