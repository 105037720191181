import React from 'react';
import PropTypes from 'prop-types';
import Button from '@x-functions/freyja/lib/components/button';
import Icon from '@x-functions/freyja/lib/components/icon';
import HtmlEditor from '../../../../components/htmlEditor';
import styles from './richEditor.module.scss';

function RichEditor({
  className,
  templateId,
  editorType,
  jsonTemplate,
  saving,
  isValid,
  onSave,
  onCancel,
  onDelete,
}) {
  return (
    <HtmlEditor
      id="template"
      name="template"
      editorType={editorType}
      templateId={templateId}
      jsonTemplate={jsonTemplate}
      className={className}
      onSave={onSave}
      renderButtons={bee => (
        <div className={styles.buttons}>
          <Button.Group position="right" className={styles.buttonsLeft}>
            <Button onClick={() => bee.preview()}>Preview</Button>
          </Button.Group>
          <Button.Group position="right">
            <Button color="primary" disabled={saving || !isValid} loading={saving} onClick={() => bee.save()}>Save</Button>
            <Button onClick={onCancel} disabled={saving}>Cancel</Button>
            <Button
              color="danger"
              disabled={saving}
              onClick={onDelete}
            >
              <Icon><span className="fa fa-trash" /></Icon>&nbsp; Delete
            </Button>
          </Button.Group>
        </div>
      )}
    />

  );
}

RichEditor.propTypes = {
  templateId: PropTypes.string.isRequired,
  editorType: PropTypes.oneOf(['email', 'page']).isRequired,
  jsonTemplate: PropTypes.string,
  className: PropTypes.string,
  saving: PropTypes.bool,
  isValid: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

RichEditor.defaultProps = {
  jsonTemplate: '',
  className: null,
  saving: false,
  isValid: false,
};

export default RichEditor;
