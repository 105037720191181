import React from 'react';
import PropTypes from 'prop-types';
import Panel from '@x-functions/freyja/lib/components/panel';
import {
  Field,
  Label,
  Control,
  Select,
} from '@x-functions/freyja/lib/components/form';
import { workflowNodeType } from '@x-functions/x-react-workflow/lib/components/workflow/types';
import WebhookInspector from './webhookInspector';
import ScheduleInspector from './scheduleInspector';
import PinpointEventInspector from './pinpointEventInspector';
import NoEventTypeSelected from './noEventTypeSelected';

function EventInspector({ node, updateNode, ...otherProps }) {
  const handleValueChange = React.useCallback(evt => {
    const { name, value } = evt.target;
    if (!name) throw new Error('Could not find field name');

    updateNode({ id: node.id, changes: { [name]: value } });
  }, [node, updateNode]);

  let Inspector;
  switch (node.eventType) {
    case 'webhook':
      Inspector = WebhookInspector;
      break;
    case 'schedule':
      Inspector = ScheduleInspector;
      break;
    case 'event':
      Inspector = PinpointEventInspector;
      break;
    default:
      Inspector = NoEventTypeSelected;
  }

  return (
    <>
      <Panel.Block>
        <Field>
          <Label>Type</Label>
          <Control>
            <Select value={node.eventType} onChange={handleValueChange} name="eventType" id="eventType">
              <option value="">Choose one...</option>
              <option value="webhook">Webhook</option>
              <option value="schedule">Schedule</option>
              <option value="event">Event</option>
            </Select>
          </Control>
        </Field>
      </Panel.Block>
      <Inspector node={node} updateNode={updateNode} {...otherProps} />
    </>
  );
}

EventInspector.propTypes = {
  node: workflowNodeType.isRequired,
  updateNode: PropTypes.func.isRequired,
};

export default EventInspector;
