import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@x-functions/freyja/lib/components/modal';
import Button from '@x-functions/freyja/lib/components/button';

function ConfirmModal({
  open,
  title,
  body,
  confirmText,
  cancelText,
  onClose,
}) {
  const onCancel = React.useCallback(() => {
    onClose(false);
  }, [onClose]);

  const onConfirm = React.useCallback(() => {
    onClose(true);
  }, [onClose]);

  return (
    <Modal show={open} onClose={onCancel}>
      <Modal.Card>
        <Modal.Card.Head onClose={onCancel}><div className="is-flex is-flex-grow-1">{title}</div></Modal.Card.Head>
        <Modal.Card.Body>{body}</Modal.Card.Body>
        <Modal.Card.Foot>
          <Button.Group>
            <Button onClick={onCancel}>{cancelText}</Button>
            <Button color="dark" onClick={onConfirm}>{confirmText}</Button>
          </Button.Group>
        </Modal.Card.Foot>
      </Modal.Card>
    </Modal>
  );
}

ConfirmModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.node,
  body: PropTypes.node.isRequired,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

ConfirmModal.defaultProps = {
  open: false,
  title: 'Please Confirm',
  confirmText: 'Confirm',
  cancelText: 'Cancel',
};

export default ConfirmModal;
