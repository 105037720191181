import React from 'react';
import PropTypes from 'prop-types';
import Panel from '@x-functions/freyja/lib/components/panel';
import Button from '@x-functions/freyja/lib/components/button';
import {
  Field,
  Label,
  Control,
  Input,
  Select,
} from '@x-functions/freyja/lib/components/form';
import { dataTypes, getConditionsForDataType } from '@x-functions/x-react-workflow/lib/components/workflow/conditions';
import FieldPickerInput from '../fieldPicker';
import DatePicker from '../../../../../components/datepicker';
import styles from './choice.module.scss';

function Choice({
  nodeId,
  choice,
  onChoiceChange,
  onChoiceDelete,
  onConditionChange,
}) {
  return (
    <Panel.Block
      key={choice.id}
      className={styles.root}
      style={{ borderTop: `3px solid ${choice.colour}` }}
    >
      <Button remove className={styles.remove} onClick={onChoiceDelete} />
      <Label>Field name</Label>
      <FieldPickerInput
        fullwidth
        name="variable"
        id="variable"
        currentNodeId={nodeId}
        value={choice.condition.variable}
        onChange={onConditionChange}
      />
      <Label>Comparison</Label>
      <Field fullwidth kind="addons">
        <Control>
          <Select name="dataType" id="dataType" value={choice.condition.dataType} onChange={onConditionChange}>
            {dataTypes.map(dataType => (
              <option key={dataType.value} value={dataType.value}>{dataType.label}</option>
            ))}
          </Select>
        </Control>
        <Control>
          <Select name="operator" id="operator" value={choice.operator} onChange={onChoiceChange}>
            <option value="">is</option>
            <option value="Not">is not</option>
          </Select>
        </Control>
        <Control>
          <Select name="comparison" id="comparison" value={choice.condition.comparison} onChange={onConditionChange}>
            {getConditionsForDataType(choice.condition.dataType).map(c => (
              <option key={c.value} value={c.value}>{c.label}</option>
            ))}
          </Select>
        </Control>
      </Field>
      <Field>
        <Label>Value</Label>
        <Control>
          {choice.condition.dataType === 'Timestamp' && (
            <DatePicker value={choice.condition.value} onChange={onConditionChange} name="value" id="value" />
          )}
          {choice.condition.dataType === 'Boolean' && (
            <Select name="value" id="value" value={choice.condition.value || 'false'} onChange={onConditionChange}>
              <option value="false">False</option>
              <option value="true">True</option>
            </Select>
          )}
          {!['Timestamp', 'Boolean'].includes(choice.condition.dataType) && (
            <Input
              fullwidth
              type={choice.condition.dataType === 'Numeric' ? 'number' : 'text'}
              name="value"
              id="value"
              value={choice.condition.value}
              onChange={onConditionChange}
            />
          )}
        </Control>
      </Field>
    </Panel.Block>

  );
}

Choice.propTypes = {
  nodeId: PropTypes.string.isRequired,
  choice: PropTypes.shape({
    id: PropTypes.string.isRequired,
    colour: PropTypes.string,
    operator: PropTypes.string,
    condition: PropTypes.shape({
      variable: PropTypes.string,
      dataType: PropTypes.string,
      value: PropTypes.string,
      comparison: PropTypes.string,
    }),
  }).isRequired,
  onChoiceChange: PropTypes.func.isRequired,
  onChoiceDelete: PropTypes.func.isRequired,
  onConditionChange: PropTypes.func.isRequired,
};

Choice.defaultProps = {
};

export default Choice;
