import React from 'react';
import Menu from '@x-functions/freyja/lib/components/menu';

function ScheduleFields() {
  return (
    <Menu>
      <Menu.List title="Event - schedule (no fields)" />
    </Menu>
  );
}

export default ScheduleFields;
