import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import Section from '@x-functions/freyja/lib/components/section';
import Heading from '@x-functions/freyja/lib/components/heading';
import {
  MainActionTile,
  LoadingTile,
  Tile,
  TileContainer,
} from '../../../components/tiles';
import useTemplates from '../../../hooks/useTemplates';

function ListTemplates({
  history,
}) {
  const {
    orgId: currentOrg,
    content,
    nextToken,
    actions,
  } = useTemplates();

  const [loading, setLoading] = React.useState(false);

  const fetchContent = React.useCallback(async () => {
    try {
      setLoading(true);
      await actions.fetchContent({ orgId: currentOrg });
    } finally {
      setLoading(false);
    }
  }, [currentOrg, actions]);

  const handleTemplateClick = templateId => () => {
    history.push(`/content/${templateId}`);
  };

  React.useEffect(() => {
    if (!currentOrg) return;

    fetchContent();
  }, [currentOrg, fetchContent]);

  const loadMore = React.useCallback(async () => {
    if (nextToken) {
      try {
        setLoading(true);
        await actions.fetchContent({ orgId: currentOrg, token: nextToken });
      } finally {
        setLoading(false);
      }
    }
  }, [currentOrg, nextToken, actions]);

  return (
    <Section>
      <Heading>Templates</Heading>
      <TileContainer>
        <MainActionTile title="Create template" icon="plus-square" onClick={handleTemplateClick('new')} />
        {(content || []).map(c => (
          <Tile key={c.templateId} onClick={handleTemplateClick(c.templateId)}>
            <Heading>{c.templateId}</Heading>
            <Heading subtitle>Modified {moment(c.lastUpdate).fromNow()}</Heading>
          </Tile>
        ))}
        {loading && (
          <LoadingTile />
        )}
        {!loading && nextToken && (
          <Tile onClick={loadMore} color="info">
            <Heading>Load More</Heading>
          </Tile>
        )}
      </TileContainer>
    </Section>
  );
}

ListTemplates.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(ListTemplates);
