import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useWebSocketSubscription } from './useWebsocketSubscription';
import { updateWorkflowDetails } from '../reducers/actions';

function useWorkflowsSubscription() {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { messages } = useWebSocketSubscription('workflows');
  const [processed, setProcessed] = useState([]);

  useEffect(() => {
    if (!messages) return;

    const unprocessed = messages.filter(m => !processed.includes(m.id));
    if (unprocessed.length) {
      const newlyProcessed = unprocessed.map(message => {
        dispatch(updateWorkflowDetails(message.payload));
        if (message.message === 'REMOVE') {
          addToast(`Workflow ${message.payload.workflowId} has been deleted`, {
            appearance: 'warning',
          });
        } else if (message.payload.statuses) {
          const status = message.payload.statuses[message.payload.statuses.length - 1];
          const oneMinuteAgo = moment().subtract(1, 'minute');
          if (moment(status.timestamp).isAfter(oneMinuteAgo)) {
            addToast(`Workflow ${message.payload.workflowId} has a new status: ${status.status}`, {
              appearance: status.status === 'FAILED' ? 'error' : 'info',
            });
          }
        }
        return message.id;
      });
      setProcessed(v => [...v, ...newlyProcessed]);
    }
  }, [dispatch, addToast, messages, processed]);

  return { messages };
}

export default useWorkflowsSubscription;
