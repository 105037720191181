import { shallowEqual, useSelector } from 'react-redux';
import { selectCurrentOrg } from '../reducers/user';
import useActions from './useActions';
import {
  createOrganizationFunction,
  updateOrganizationFunction,
  selectIsNewFunction,
} from '../reducers/orgFunctions';
import {
  deleteFunction,
  fetchFunctionDetails,
  selectFunctionDetails,
  updateFunctionProps,
  unsetFunctionDetails,
  publishFunctionVersion,
  unpublishFunction,
} from '../reducers/functionDetails';
import {
  publishFunction,
} from '../reducers/functionCatalog';
import {
  updateVersionInfo,
} from '../reducers/versionInfo';

const builderActionCreators = {
  createOrganizationFunction,
  publishFunctionVersion,
  unsetFunctionDetails,
  updateOrganizationFunction: ({
    orgId,
    functionName,
    source,
    ...rest
  }) => async dispatch => {
    const { newVersion, ...fn } = await dispatch(updateOrganizationFunction({
      orgId,
      functionName,
      source,
      ...rest,
    }));
    dispatch(updateFunctionProps({ orgId, functionName, props: { ...rest, draft: source, newVersion } }));
    return { ...fn, newVersion };
  },
  fetchFunctionDetails,
  publishFunction,
  unpublishFunction,
  deleteFunction,
  updateVersionInfo,
};

export function useFunctionDetailsActions() {
  const actions = useActions(builderActionCreators);

  return actions;
}

function useFunctionDetails(functionName, orgId = undefined) {
  const currentOrgId = useSelector(selectCurrentOrg, shallowEqual);
  const functionDetails = useSelector(state => functionName && selectFunctionDetails(state, {
    orgId: orgId || currentOrgId,
    functionName,
  }), shallowEqual);
  const isNewFunction = useSelector(state => functionName && selectIsNewFunction(state, { orgId: orgId || currentOrgId, functionName }));
  const actions = useFunctionDetailsActions();
  const prodVersion = functionDetails?.prodVersion
    ? functionDetails?.versions?.find(v => v.version === functionDetails.prodVersion)
    : undefined;

  return {
    orgId: orgId || currentOrgId,
    functionDetails,
    prodVersion,
    isNewFunction,
    actions,
  };
}

export default useFunctionDetails;
