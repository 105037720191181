import jwt from 'jsonwebtoken';
import { updateUserRoles } from '../reducers/user';
import { store } from '../store';

let currentAuthData = undefined;

export const setAuthData = (data) => {
  if (data?.token && !data?.decoded) {
    const decoded = jwt.decode(data.token);
    currentAuthData = { ...data, decoded };
  }
  store.dispatch(updateUserRoles());
}

export const getAuthData = async () => {
  if (currentAuthData?.decoded?.exp) {
    if (currentAuthData.decoded.exp * 100 >= Date.now()) return undefined;
  }

  return currentAuthData;
};
