import React from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { selectEventNode } from '../reducers/workflowEditor';
import { selectCurrentOrg } from '../reducers/user';
import { fetchOrganizationWebhook, selectOrgWebhook } from '../reducers/orgWebhooks';

function useWebhookSchema(orgId, webhookId) {
  const [refreshing, setRefreshing] = React.useState(true);
  const [schema, setSchema] = React.useState(undefined);
  const webhook = useSelector(state => selectOrgWebhook(state, orgId, webhookId), shallowEqual);
  const dispatch = useDispatch();

  React.useEffect(() => {
    (async () => {
      if (!orgId || !webhookId) return;

      try {
        setRefreshing(true);
        await dispatch(fetchOrganizationWebhook({ orgId, webhookId }));
      } finally {
        setRefreshing(false);
      }
    })();
  }, [orgId, webhookId, dispatch]);

  React.useEffect(() => {
    if (webhook && webhook.schema) {
      setSchema(JSON.parse(webhook.schema));
    }
  }, [webhook]);

  return [schema, refreshing];
}

function useEventSchema() {
  const orgId = useSelector(selectCurrentOrg, shallowEqual);
  const event = useSelector(selectEventNode, shallowEqual);
  const [webhookSchema, refreshing] = useWebhookSchema(orgId, event && event.webhookId);
  const pinpointEventSchema = event && event.schema;

  switch (event.eventType) {
    case 'webhook':
      return [webhookSchema, refreshing];
    case 'event':
      return [pinpointEventSchema, false];
    default:
      return [undefined, false];
  }
}

export default useEventSchema;
