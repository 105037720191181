import React from 'react';
import PropTypes from 'prop-types';
import Menu from '@x-functions/freyja/lib/components/menu';
import { getSchemaTypeDefinitions } from '../../../../../../../utils/jsonSchemas';
import useFunctionDetails from '../../../../../../../hooks/useFunctionDetails';

function FunctionOutputFields({
  node,
  onClick,
}) {
  const [org, functionName] = (node && node.function && node.function.split('/')) || [];
  const {
    functionDetails,
    actions,
  } = useFunctionDetails(functionName, org);

  React.useEffect(() => {
    if (!node.function) return;

    const [orgId, fn] = node.function.split('/');
    actions.fetchFunctionDetails({ orgId, functionName: fn });
  }, [actions, node.function]);

  if (!functionDetails || !functionDetails.versions || !functionDetails.prodVersion) {
    return <p>Loading function output schema...</p>;
  }

  const makeItemClickHandler = propertyName => () => {
    onClick(`${node.output}.${propertyName}`);
  };

  const schema = JSON.parse(functionDetails.versions.find(f => f.version === functionDetails.prodVersion)?.outputSchema || '{}');
  return (
    <Menu>
      <Menu.List title={node.name}>
        {getSchemaTypeDefinitions(schema).map(p => (
          <Menu.List.Item key={p.name} onClick={makeItemClickHandler(p.name)}>
            {`${p.name}: ${p.type}${p.required ? ' (required)' : ''}`}
          </Menu.List.Item>
        ))}
      </Menu.List>
    </Menu>
  );
}

FunctionOutputFields.propTypes = {
  node: PropTypes.shape({
    name: PropTypes.string,
    function: PropTypes.string,
    output: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default FunctionOutputFields;
