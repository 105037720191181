import { identify, raiseEvent } from '@x-functions/website-analytics-sdk';
import { createAction, createReducer } from 'redux-act';
import jwt from 'jsonwebtoken';
import { fetchUserOrgs } from './organizations';
import { getAuthData } from '../utils/auth';

const setCurrentOrgAction = createAction('/user/currentOrg/set');
const setUserRolesAction = createAction('/user/roles/set');
const setUserId = createAction('/user/id/set');
const setUserEmail = createAction('/user/email/set');

export const setCurrentOrg = ({ orgId }) => async (dispatch, getState) => {
  const state = getState();
  const { roles } = state.user;

  if (orgId && !roles[orgId]) {
    throw new Error(`User not member of organization "${orgId}"`);
  }

  return dispatch(setCurrentOrgAction(orgId || ''));
};

export const updateUserRoles = () => async (dispatch, getState) => {
  const session = await getAuthData();
  const token = session?.token;
  let userId;
  let userAttributes;
  let roles = {};
  let payload;
  if (token) {
    payload = jwt.decode(token);
    userId = payload.sub;
    roles = JSON.parse(payload.orgRoles);
    userAttributes = {
      orgs: Object.keys(roles),
    };
  }

  if (!Object.keys(roles).length) {
    dispatch(setCurrentOrg({ orgId: '' }));
  } else {
    identify(userId, {
      userId,
      name: payload?.email, // not storing user name, just email
    }, userAttributes);
    raiseEvent('add-touchpoint', {
      userId,
      address: payload?.email,
      channelType: 'EMAIL',
    });
  }

  const { currentOrg } = getState().user;
  dispatch(setUserId(userId));
  dispatch(setUserRolesAction(roles));
  dispatch(setUserEmail(payload?.email));
  if (!currentOrg || !roles[currentOrg]) {
    dispatch(setCurrentOrg({ orgId: Object.keys(roles)[0] }));
  }
  dispatch(fetchUserOrgs());
};

updateUserRoles();

export default createReducer({
  [setCurrentOrgAction]: (state, orgId) => ({
    ...state,
    currentOrg: orgId,
  }),
  [setUserRolesAction]: (state, roles) => ({
    ...state,
    roles,
  }),
  [setUserId]: (state, userId) => ({
    ...state,
    userId,
  }),
  [setUserEmail]: (state, email) => ({
    ...state,
    email,
  }),

}, {
  roles: {},
  currentOrg: '',
  userId: '',
  email: '',
});

export const selectRoles = state => state.user.roles;
export const selectCurrentOrg = state => state.user.currentOrg;
export const selectUserId = state => state.user.userId;
export const selectUserEmail = state => state.user.email;
