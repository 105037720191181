import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Box from '@x-functions/freyja/lib/components/box';
import Button from '@x-functions/freyja/lib/components/button';
import styles from './styles.module.scss';

function Drawer({
  open,
  onClose,
  children,
  position,
  className,
}) {
  return (
    <Box
      className={classNames(styles.drawer, {
        [styles.open]: open,
        [styles.left]: position === 'left',
      }, className)}
    >
      <Button remove className={styles.close} onClick={onClose} />
      {children}
    </Box>
  );
}

Drawer.propTypes = {
  open: PropTypes.bool.isRequired,
  position: PropTypes.oneOf(['left', 'right']),
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
};

Drawer.defaultProps = {
  position: 'right',
  children: null,
  className: undefined,
};

export default Drawer;
