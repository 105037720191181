import React from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  Control,
  Label,
  Input,
  Textarea,
} from '@x-functions/freyja/lib/components/form';
import Modal from '@x-functions/freyja/lib/components/modal';
import Button from '@x-functions/freyja/lib/components/button';

const initialJsonSchema = `{
  "definitions":{},
  "$schema":"http://json-schema.org/draft-07/schema#",
  "type":"object",
  "title":"Webhook Data Schema",
  "required": [
    "requiredProp"
  ],
  "properties":{
    "requiredProp": {
      "type": "string",
      "title": "Example required property"
    },
    "name": {
      "type":"string",
      "title":"A person's name (optional)",
      "examples": ["John Smith"],
      "pattern": "^(.*)$"
    }
  }
}`;

const createInitialState = () => ({
  webhookId: (+new Date()).toString(36),
  name: '',
  description: '',
  schema: initialJsonSchema,
});

function WebhookCreator({ show, onClose, onSave }) {
  const [saving, setSaving] = React.useState(false);
  const [values, setValues] = React.useState(createInitialState());

  const handleInputChange = React.useCallback(e => {
    const { target: { name, value } } = e;
    setValues({ ...values, [name]: value });
  }, [values]);

  const handleCancel = React.useCallback(e => {
    setValues(createInitialState());
    onClose(e);
  }, [onClose]);

  const handleSave = React.useCallback(async e => {
    setSaving(true);
    try {
      await onSave(values);
      setSaving(false);
      handleCancel(e);
    } catch (err) {
      setSaving(false);
      window.alert(`Error: ${err.message}`);
    }
  }, [values, onSave, handleCancel]);

  return (
    <Modal show={show}>
      <Modal.Card>
        <Modal.Card.Head onClose={handleCancel}>
          <Modal.Card.Title>New Webhook</Modal.Card.Title>
        </Modal.Card.Head>
        <Modal.Card.Body>
          <Field>
            <Label>ID</Label>
            <Control>
              <Input name="webhookId" value={values.webhookId} onChange={handleInputChange} required />
            </Control>
          </Field>
          <Field>
            <Label>Name</Label>
            <Control>
              <Input name="name" value={values.name} onChange={handleInputChange} required />
            </Control>
          </Field>
          <Field>
            <Label>Description</Label>
            <Control>
              <Input name="description" value={values.description} onChange={handleInputChange} required />
            </Control>
          </Field>
          <Field>
            <Label>Schema</Label>
            <Control>
              <Textarea name="schema" value={values.schema} onChange={handleInputChange} required />
            </Control>
          </Field>
        </Modal.Card.Body>
        <Modal.Card.Foot>
          <Button color="primary" onClick={handleSave} loading={saving} disabled={saving}>Create webhook</Button>
          <Button onClick={handleCancel} disabled={saving}>Cancel</Button>
        </Modal.Card.Foot>
      </Modal.Card>
    </Modal>
  );
}

WebhookCreator.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default WebhookCreator;
