import { shallowEqual, useSelector } from 'react-redux';
import { selectCurrentOrg } from '../reducers/user';
import {
  selectOrganizationIntegrations,
  fetchOrganizationIntegrations,
  createOrganizationIntegration,
  deleteOrganizationIntegration,
  getAuthenticationUri,
  getAccessToken,
} from '../reducers/orgIntegrations';
import useActions from './useActions';

const mainActions = {
  fetchOrganizationIntegrations,
  createOrganizationIntegration,
  deleteOrganizationIntegration,
};

const nonDispatchActions = {
  getAuthenticationUri,
  getAccessToken,
};

export function useOrgIntegrationActions() {
  const actions = useActions(mainActions, nonDispatchActions);

  return actions;
}

function useOrgIntegrations() {
  const orgId = useSelector(selectCurrentOrg, shallowEqual);
  const integrations = useSelector(state => selectOrganizationIntegrations(state, orgId), shallowEqual);
  const actions = useOrgIntegrationActions();

  return [integrations, actions];
}

export default useOrgIntegrations;
