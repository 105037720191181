import React from 'react';
import PropTypes from 'prop-types';
import EventInspector from './eventInspector';
import FunctionInspector from './functionInspector';
import MapInspector from './mapInspector';
import RepeatInspector from './repeatInspector';
import ParallelInspector from './parallelInspector';
import ConditionalInspector from './conditionalInspector';
import ApprovalInspector from './approvalInspector';
import WaitInspector from './waitInspector';
import WaitForEventInspector from './waitForEventInspector';
import EndInspector from './endInspector';
import EventMonitor from './eventInspector/monitor';
import Sidebar from '../../../../components/sidebar';
import useOrgWebhooks from '../../../../hooks/useOrgWebhooks';
import styles from './styles.module.scss';

function NodeInspector({
  className,
  executions,
  currentExecution,
  mode,
  onClose,
  ...otherProps
}) {
  const {
    orgId: currentOrg,
    selectedNode,
    actions,
    ...rest
  } = useOrgWebhooks();

  React.useEffect(() => {
    if (!currentOrg || !actions?.fetchOrganizationWebhooks) return;

    actions.fetchOrganizationWebhooks({ orgId: currentOrg });
  }, [currentOrg, actions]);

  const execution = executions && !!currentExecution && executions.find(e => e.executionName === currentExecution);

  const ContentInspector = React.useMemo(() => {
    if (mode === 'monitor' && !!currentExecution) {
      return EventMonitor;
    }
    switch (selectedNode.type) {
      case 'function':
        return FunctionInspector;
      case 'conditional':
        return ConditionalInspector;
      case 'map':
        return MapInspector;
      case 'repeat':
        return RepeatInspector;
      case 'parallel':
        return ParallelInspector;
      case 'approval':
        return ApprovalInspector;
      case 'wait':
        return WaitInspector;
      case 'wait-for-event':
        return WaitForEventInspector;
      case 'end':
        return EndInspector;
      default:
        return EventInspector;
    }
  }, [mode, currentExecution, selectedNode.type]);

  return (
    <Sidebar
      open
      onClose={onClose}
      title={selectedNode.name}
      buttonIcon="close"
      onButtonClick={onClose}
      className={styles.sideBar}
    >
      <ContentInspector currentOrg={currentOrg} node={selectedNode} {...otherProps} {...rest} {...actions} execution={execution} />
    </Sidebar>
  );
}

NodeInspector.propTypes = {
  className: PropTypes.string,
  executions: PropTypes.arrayOf(PropTypes.shape({
    events: PropTypes.objectOf(PropTypes.shape({})),
  })),
  mode: PropTypes.oneOf('monitor', 'editor'),
  currentExecution: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

NodeInspector.defaultProps = {
  className: undefined,
  executions: [],
  currentExecution: '',
  mode: 'editor',
};

export default NodeInspector;
