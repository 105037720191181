import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import Panel from '@x-functions/freyja/lib/components/panel';
import { workflowType } from '@x-functions/x-react-workflow/lib/components/workflow/types';
import WorkflowExecutions from './executions';
import useWorkflowEditor from '../../../../hooks/useWorkflowEditor';
import styles from './styles.module.scss';

function WorkflowHeader({
  className,
  blockNameUpdates,
  history,
  ...otherProps
}) {
  const {
    orgId: currentOrg,
    workflow,
    mode,
    details,
    actions,
    ...otherInfo
  } = useWorkflowEditor();

  const [lastStatus, setLastStatus] = React.useState(undefined);

  React.useEffect(() => {
    if (details && details.statuses && details.statuses.length) {
      setLastStatus(details.statuses[details.statuses.length - 1]);
    }
  }, [details]);

  return (
    <Panel className={className}>
      <Panel.Header>
        Monitor
      </Panel.Header>
      {lastStatus && (
        <Panel.Block className={styles.panelColumn}>
          <p>State: {details?.stateMachineArn ? 'Live' : 'Not published'}</p>
          <p>
            <strong>Last status</strong>:&nbsp;
            {lastStatus.status}
            <br />
            <em>
              Updated&nbsp;
              {moment(lastStatus.timestamp).fromNow()}
            </em>
          </p>
        </Panel.Block>
      )}
      {mode === 'monitor' && workflow.workflowId && (
        <WorkflowExecutions
          orgId={currentOrg}
          workflowId={workflow.workflowId}
          {...actions}
          {...otherInfo}
          {...otherProps}
        />
      )}
    </Panel>
  );
}

WorkflowHeader.propTypes = {
  className: PropTypes.string,
  currentOrg: PropTypes.string.isRequired,
  workflow: workflowType.isRequired,
  mode: PropTypes.oneOf(['edit', 'monitor']).isRequired,
  setEditorMode: PropTypes.func.isRequired,
  updateWorkflowName: PropTypes.func.isRequired,
  saveWorkflow: PropTypes.func.isRequired,
  fetchWorkflowExecutions: PropTypes.func.isRequired,
  blockNameUpdates: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  details: PropTypes.shape({
    stateMachineArn: PropTypes.string,
    statuses: PropTypes.arrayOf(PropTypes.shape({
      status: PropTypes.string.isRequired,
      timestamp: PropTypes.number.isRequired,
    })),
  }),
};

WorkflowHeader.defaultProps = {
  className: undefined,
  blockNameUpdates: false,
  details: {},
};

export default withRouter(WorkflowHeader);
