import { shallowEqual, useSelector } from 'react-redux';
import { selectCurrentOrg } from '../reducers/user';
import useActions from './useActions';
import {
  fetchApps,
  fetchEventTypes,
  fetchEventSamples,
  selectApps,
  selectEventTypes,
  selectEventSamples,
} from '../reducers/eventData';

const builderActionCreators = {
  fetchApps,
  fetchEventTypes,
  fetchEventSamples,
};

export function useEventDataActions() {
  const actions = useActions(builderActionCreators);

  return actions;
}

const emptyRes = undefined;

function useOrgAPIDetails({ appId, eventType }) {
  const orgId = useSelector(selectCurrentOrg, shallowEqual);
  const apps = useSelector(state => selectApps(state, orgId) || emptyRes, shallowEqual);
  const eventTypes = useSelector(state => selectEventTypes(state, { orgId, appId }) || emptyRes, shallowEqual);
  const eventSamples = useSelector(state => selectEventSamples(state, { orgId, appId, eventType }) || emptyRes, shallowEqual);
  const actions = useEventDataActions();

  return {
    orgId,
    apps,
    eventTypes,
    eventSamples,
    actions,
  };
}

export default useOrgAPIDetails;
