import React from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  Label,
  Control,
} from '@x-functions/freyja/lib/components/form';
import { camelToSencenceCase } from '../../utils/text';
import styles from './styles.module.scss';

function KeyValueEditor({
  fieldClassName,
  fields,
  errors,
  renderInput,
}) {
  return (
    <div className={styles.table}>
      {fields.map(f => (
        <React.Fragment key={`field_${f.name}`}>
          <div className={styles.row}>
            <div className={styles.col} title={f.description || f.name}><Label>{camelToSencenceCase(f.name || '')}</Label></div>
            <div className={styles.col}>
              <Field className={fieldClassName}>
                <Control fullwidth>
                  {renderInput(f)}
                </Control>
              </Field>
            </div>
          </div>
          {errors && errors[f.name] ? (
            <div className={styles.row}>
              <div className={styles.error}>
                <span className="has-text-danger">{errors[f.name]}</span>
              </div>
            </div>
          ) : null}
        </React.Fragment>
      ))}
    </div>
  );
}

KeyValueEditor.propTypes = {
  fieldClassName: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  errors: PropTypes.shape({}),
  renderInput: PropTypes.func.isRequired,
};

KeyValueEditor.defaultProps = {
  fieldClassName: undefined,
  fields: [],
  errors: {},
};

export default KeyValueEditor;
