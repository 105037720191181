import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Menu from '@x-functions/freyja/lib/components/menu';

function MenuItem({
  item,
  type,
  onNavigate,
}) {
  const name = item.functionName || item.workflowName || item.name || item.aspect.split('::')[1];

  const getLink = () => {
    switch (type) {
      case 'functions':
        return `/functions?selected=${item.aspect.split('::')[1]}`;
      case 'workflows':
        return `/workflows/${item.workflowId}`;
      case 'users':
        return `/audience/users/${item.userId}`;
      case 'segments':
        return `/audience/segments/${item.segmentId}`;
      default:
        return '#';
    }
  };

  return (
    <Menu.List.Item
      renderAs={Link}
      to={getLink(item, type)}
      onClick={onNavigate}
    >
      {name}
    </Menu.List.Item>
  );
}

MenuItem.propTypes = {
  item: PropTypes.shape({
    aspect: PropTypes.string,
    workflowName: PropTypes.string,
    functionName: PropTypes.string,
    workflowId: PropTypes.string,
    userId: PropTypes.string,
    segmentId: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  type: PropTypes.string.isRequired,
  onNavigate: PropTypes.func.isRequired,
};

export default MenuItem;
