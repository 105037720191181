import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card from '@x-functions/freyja/lib/components/card';
import Content from '@x-functions/freyja/lib/components/content';
import Media from '@x-functions/freyja/lib/components/media';
import loadingStyles from '../loadingStyles/styles.module.scss';
import { getSchemaTypeDescription } from '../../utils/jsonSchemas';
import useWebhook from '../../hooks/useWebhook';

function WebhookDetails({
  node,
  webhookId,
  updateNode,
  className,
}) {
  const {
    orgId,
    webhook,
    actions,
  } = useWebhook(webhookId);

  React.useEffect(() => {
    actions.fetchOrganizationWebhook({ orgId, webhookId });
  }, [orgId, webhookId, actions]);

  const handleChangeWebhookClick = React.useCallback(e => {
    e.stopPropagation();
    e.preventDefault();

    updateNode({ id: node.id, changes: { webhookId: '' } });
  }, [updateNode, node.id]);

  const schema = webhook && webhook.schema && JSON.parse(webhook.schema);

  return (
    <Card className={classNames(className, { [loadingStyles.loading]: !webhook })}>
      <Card.Header>
        <Card.Header.Title>
          {`${orgId}/${webhookId}`}
        </Card.Header.Title>
      </Card.Header>
      <Card.Content>
        <Media>
          <Media.Item>
            <Content>
              <p>
                <strong>{webhook && webhook.name}</strong>
              </p>
              <p>
                {webhook && webhook.description}<br />
                <small>https://api.makerops.com/rpa/orgs/{orgId}/webhooks/{webhookId}/exec</small>
              </p>
              <p>
                <strong>Fields</strong><br />
                {schema && getSchemaTypeDescription(schema).split('\n').map(field => (
                  <span key={field.split(':')[0]}>{field}<br /></span>
                ))}
              </p>
            </Content>
          </Media.Item>
        </Media>
      </Card.Content>
      <Card.Footer>
        <Card.Footer.Item renderAs="a" href="#" onClick={handleChangeWebhookClick}>Change Webhook</Card.Footer.Item>
      </Card.Footer>
    </Card>
  );
}

WebhookDetails.propTypes = {
  node: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  webhookId: PropTypes.string.isRequired,
  updateNode: PropTypes.func.isRequired,
  className: PropTypes.string,
};

WebhookDetails.defaultProps = {
  className: undefined,
};

export default WebhookDetails;
