import React from 'react';
import PropTypes from 'prop-types';
import Menu from '@x-functions/freyja/lib/components/menu';
import EventFields from '../eventFields';
import MapFields from '../mapFields';
import RepeatFields from '../repeatFields';
import FunctionOutputFields from '../functionOutputFields';

function Fields({ node, ...rest }) {
  if (node.type === 'function') {
    return <FunctionOutputFields {...rest} node={node} />;
  }

  if (node.type === 'event') {
    return <EventFields {...rest} node={node} />;
  }

  if (node.type === 'map') {
    return <MapFields {...rest} node={node} />;
  }

  if (node.type === 'repeat') {
    return <RepeatFields {...rest} node={node} />;
  }

  return (
    <Menu>
      <Menu.List title={`${node.name} (no results)`} />
    </Menu>
  );
}

Fields.propTypes = {
  node: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string,
  }).isRequired,
};

export default Fields;
