import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@x-functions/freyja/lib/components/tabs';
import Panel from '@x-functions/freyja/lib/components/panel';
import Icon from '@x-functions/freyja/lib/components/icon';
import useFunctionCatalog from '../../../hooks/useFunctionCatalog';

const categoryTabs = [{
  key: 'audience',
  label: 'Audience',
}, {
  key: 'content',
  label: 'Content',
}, {
  key: 'messaging',
  label: 'Messaging',
}, {
  key: 'utilities',
  label: 'Utilities',
}];

function CatalogFunctions({
  onClick,
  onDragStart,
}) {
  const {
    orgId,
    catalog,
    actions,
  } = useFunctionCatalog();
  const [categoryTabIndex, setCategoryTabIndex] = React.useState(0);

  React.useEffect(() => {
    if (!orgId) return;

    actions.fetchCatalogFunctions({ orgId, category: categoryTabs[categoryTabIndex].key });
  }, [orgId, actions, categoryTabIndex]);

  const handleClick = fn => () => onClick({
    ...fn,
    functionName: fn.name,
  });

  const handleDragStart = fn => e => onDragStart({
    ...fn,
    functionName: fn.name,
  }, e);

  const category = catalog[categoryTabs[categoryTabIndex].key] || {};

  return (
    <>
      <Panel.Block>
        <Tabs align="right">
          {categoryTabs.map((tab, i) => (
            <Tabs.Tab
              key={tab.key}
              active={i === categoryTabIndex}
              onClick={() => setCategoryTabIndex(i)}
            >
              {tab.label}
            </Tabs.Tab>
          ))}
        </Tabs>
      </Panel.Block>
      {category && category.data && category.data.map(f => (
        <Panel.Block
          key={f.name}
          renderAs="a"
          onClick={handleClick(f)}
          onDragStart={handleDragStart(f)}
          draggable="true"
        >
          <Panel.Icon renderAs={Icon}>
            <span className="fa fa-cogs" />
          </Panel.Icon>
          <span style={{ marginRight: '10px' }}>
            {f.name}
          </span>
        </Panel.Block>
      ))}
      {(!category || (!category.data)) && (
        <Panel.Block>
          <span>Loading...</span>
        </Panel.Block>
      )}

    </>
  );
}

CatalogFunctions.propTypes = {
  onClick: PropTypes.func.isRequired,
  onDragStart: PropTypes.func.isRequired,
};

export default CatalogFunctions;
