import React from 'react';
import Panel from '@x-functions/freyja/lib/components/panel';

function NoEventTypeSelected() {
  return (
    <Panel.Block>
      <p>No event type selected...</p>
    </Panel.Block>
  );
}

export default NoEventTypeSelected;
