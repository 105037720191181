import React from 'react';
import { Switch, Route } from 'react-router-dom';
import List from './listTemplates';
import EditTemplate from './editTemplate';

function Workflows() {
  return (
    <div>
      <Switch>
        <Route path="/content/new">
          <EditTemplate />
        </Route>
        <Route
          path="/content/:templateId"
          render={({ match }) => (
            <EditTemplate templateId={match.params.templateId} />
          )}
        />
        <Route path="/content">
          <List />
        </Route>
      </Switch>
    </div>
  );
}

export default Workflows;
