import React from 'react';
import PropTypes from 'prop-types';
import Button from '@x-functions/freyja/lib/components/button';
import Icon from '@x-functions/freyja/lib/components/icon';
import {
  Field,
  Control,
  Label,
  Input,
} from '@x-functions/freyja/lib/components/form';
import Display from './display';

function InlineInputEditor({
  name,
  label,
  value,
  onCancel,
  onSave,
  size,
  headingSize,
  edit,
  showButtons,
  ...rest
}) {
  const [editing, setEditing] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const inputRef = React.useRef();

  React.useEffect(() => {
    if (edit) {
      setEditing(edit);
    }
  }, [edit]);

  const handleDisplayClick = React.useCallback(e => {
    e.preventDefault();
    e.stopPropagation();

    setEditing(val => !val);
  }, []);

  React.useEffect(() => {
    if (editing) {
      const element = inputRef.current.querySelector(`[name="${name}"]`);
      element.focus();
      element.select();
    }
  }, [editing, name]);

  const handleSave = React.useCallback(async e => {
    setEditing(false);
    try {
      setSaving(true);
      await onSave(name, value, e);
    } finally {
      setSaving(false);
    }
  }, [onSave, name, value]);

  const handleCancel = React.useCallback(e => {
    onCancel(name, value, e);
    setEditing(false);
  }, [onCancel, name, value]);

  const handleKeyDown = React.useCallback(e => {
    if (e.key === 'Escape') return handleCancel();
    if (e.key === 'Enter') return handleSave();
    return undefined;
  }, [handleCancel, handleSave]);

  const handleBlur = React.useCallback(() => {
    if (editing) handleSave();
  }, [handleSave, editing]);

  return (
    <div ref={inputRef}>
      {label && <Label htmlFor={name}>{label}</Label>}
      <Field kind={showButtons ? 'addons' : undefined}>
        <Control fullwidth>
          {editing && (
            <Input
              type="text"
              value={value}
              name={name}
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
              size={size}
              {...rest}
            />
          )}
          {!editing && <Display onClick={handleDisplayClick} size={headingSize}>{value}</Display>}
        </Control>
        {editing && showButtons && (
          <>
            <Control>
              <Button onClick={handleSave} loading={saving} disabled={saving} size={size}>
                <Icon><span className="fa fa-check" /></Icon>
              </Button>
            </Control>
            <Control>
              <Button onClick={handleCancel} size={size}>
                <Icon><span className="fa fa-times" /></Icon>
              </Button>
            </Control>
          </>
        )}
      </Field>
    </div>
  );
}

InlineInputEditor.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  size: PropTypes.string,
  edit: PropTypes.bool,
  showButtons: PropTypes.bool,
  headingSize: PropTypes.number,
};

InlineInputEditor.defaultProps = {
  label: '',
  size: undefined,
  edit: false,
  showButtons: true,
  headingSize: undefined,
};

export default InlineInputEditor;
