import { shallowEqual, useSelector } from 'react-redux';
import { selectCurrentOrg } from '../reducers/user';
import useActions from './useActions';
import {
  fetchCatalogFunctions,
  selectCatalog,
} from '../reducers/functionCatalog';

const builderActionCreators = {
  fetchCatalogFunctions,
};

export function useFunctionCatalogActions() {
  const actions = useActions(builderActionCreators);

  return actions;
}

function useFunctionCatalog() {
  const orgId = useSelector(selectCurrentOrg, shallowEqual);
  const catalog = useSelector(state => selectCatalog(state, orgId), shallowEqual);
  const actions = useFunctionCatalogActions();

  return {
    orgId,
    catalog,
    actions,
  };
}

export default useFunctionCatalog;
