import React from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  Control,
  Select,
  Input,
} from '@x-functions/freyja/lib/components/form';
import Button from '@x-functions/freyja/lib/components/button';

const numberToSeconds = (number, from) => {
  let seconds = 0;

  switch (from) {
    case 'minutes':
      seconds = number * 60;
      break;
    case 'hours':
      seconds = number * 3600;
      break;
    case 'days':
      seconds = number * 86400;
      break;
    default:
      seconds = number;
  }

  return seconds;
};

const secondsToTime = (seconds, to) => {
  let value = 0;

  switch (to) {
    case 'minutes':
      value = seconds / 60;
      break;
    case 'hours':
      value = seconds / 3600;
      break;
    case 'days':
      value = seconds / 86400;
      break;
    default:
      value = seconds;
  }

  return value;
};

function TimeInput({
  value,
  onChange,
  ...rest
}) {
  const [text, setText] = React.useState('0');
  const [type, setType] = React.useState('seconds');

  React.useEffect(() => {
    if (typeof value === 'number') {
      setText(`${secondsToTime(value, type)}`);
    } else if (typeof value === 'string') {
      setText(value);
    }
  }, [type, value]);

  const handleTextChanged = React.useCallback(e => {
    const { value: val, name } = e.target;
    setText(val);

    const numeric = Number(val);
    if (Number.isNaN(numeric)) {
      onChange(e);
      return;
    }

    const newValue = numberToSeconds(numeric, type);
    if (value !== newValue) onChange({ ...e, target: { ...e.target, name, value: newValue } });
  }, [value, type, onChange]);

  const handleTypeChanged = React.useCallback(({ target: { value: val } }) => {
    setType(val);
  }, []);

  const isPath = text?.charAt(0) === '$';

  return (
    <Field kind="addons">
      <Control>
        <Input
          value={text}
          onChange={handleTextChanged}
          fullwidth
          {...rest}
        />
      </Control>
      {isPath ? (
        <Control><Button isStatic>Seconds</Button></Control>
      ) : (
        <Control>
          <Select value={type} onChange={handleTypeChanged}>
            <option value="seconds">Seconds</option>
            <option value="minutes">Minutes</option>
            <option value="hours">Hours</option>
            <option value="days">Days</option>
          </Select>
        </Control>
      )}
    </Field>
  );
}

TimeInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
};

TimeInput.defaultProps = {
  value: '0',
};

export default TimeInput;
