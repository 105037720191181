import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import Loading from '@x-functions/freyja/lib/components/loader';
import { MainActionTile } from '../../../../components/tiles';
import useWorkflowEditor from '../../../../hooks/useWorkflowEditor';
import styles from './styles.module.scss';

function CreateNew({ history }) {
  const [loading, setLoading] = React.useState(false);
  const { orgId, actions } = useWorkflowEditor();

  const handleTileClick = React.useCallback(async e => {
    e.preventDefault();

    setLoading(true);
    try {
      const { payload } = await actions.createNewWorkflow({ orgId });
      if (payload?.workflowId) {
        history.push(`/workflows/${payload.workflowId}`);
      }
    } finally {
      setLoading(false);
    }
  }, [history, orgId, actions]);

  return (
    <MainActionTile
      onClick={loading ? undefined : handleTileClick}
      className={classNames(styles.tile, { [styles.loading]: loading })}
      title={loading ? (
        <div className="is-fullwidth is-flex is-flex-direction-row is-justify-content-center"><Loading /></div>
      ) : 'Create workflow'}
      icon="plus-square"
    />
  );
}

CreateNew.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(CreateNew);
