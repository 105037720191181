import React from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  Control,
  Textarea,
} from '@x-functions/freyja/lib/components/form';
import Button from '@x-functions/freyja/lib/components/button';
import Icon from '@x-functions/freyja/lib/components/icon';
import styles from './textEditor.module.scss';

function TextEditor({
  value,
  saving,
  isValid,
  onChange,
  onSave,
  onCancel,
  onDelete,
}) {
  return (
    <div className={styles.root}>
      <Field className={styles.field}>
        <Control className={styles.control}>
          <Textarea name="template" value={value} onChange={onChange} className={styles.textArea} />
        </Control>
      </Field>
      <Button.Group position="right">
        <Button color="primary" disabled={saving || !isValid} loading={saving} onClick={onSave}>Save</Button>
        <Button onClick={onCancel} disabled={saving}>Cancel</Button>
        <Button
          color="danger"
          disabled={saving}
          onClick={onDelete}
        >
          <Icon><span className="fa fa-trash" /></Icon>&nbsp; Delete
        </Button>
      </Button.Group>
    </div>
  );
}

TextEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  saving: PropTypes.bool,
  isValid: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

TextEditor.defaultProps = {
  value: '',
  saving: false,
  isValid: false,
};

export default TextEditor;
