import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Panel from '@x-functions/freyja/lib/components/panel';
import Card from '@x-functions/freyja/lib/components/card';
import Media from '@x-functions/freyja/lib/components/media';
import Button from '@x-functions/freyja/lib/components/button';
import Content from '@x-functions/freyja/lib/components/content';
import NewExecutionModal from './newExecutionModal';
import styles from './executions.module.scss';

function WorkflowExecutions({
  fetchWorkflowExecutions,
  fetchExecutionEvents,
  setCurrentExecution,
  startNewExecution,
  orgId,
  workflowId,
  executions,
  currentExecution,
}) {
  const [modalOpen, setModalOpen] = React.useState(false);

  React.useEffect(() => {
    if (!fetchWorkflowExecutions || !orgId) return;

    fetchWorkflowExecutions({ orgId });
  }, [orgId, fetchWorkflowExecutions]);

  const handleViewExecutionClick = executionName => e => {
    e.preventDefault();
    e.stopPropagation();
    fetchExecutionEvents({ orgId, executionName });
    setCurrentExecution({ executionName });
  };

  const toggleModal = React.useCallback(() => setModalOpen(v => !v), []);

  const handleStartNewExecution = React.useCallback(payload => startNewExecution({
    orgId,
    workflowId,
    payload,
  }), [orgId, workflowId, startNewExecution]);

  return (
    <Panel.Block>
      <Content className={styles.content}>
        <h3>Executions</h3>
        {modalOpen && <NewExecutionModal show={modalOpen} onClose={toggleModal} onSave={handleStartNewExecution} />}
        {!executions && (
          <p>Loading...</p>
        )}
        {executions && (
          <Button onClick={toggleModal}>Start new execution</Button>
        )}
        {executions && executions.map(e => (
          <Card
            key={e.executionName}
            className={classNames(styles.card, {
              [styles.active]: e.executionName === currentExecution,
            })}
          >
            <Card.Header>
              <Card.Header.Title>{moment(e.startedAt).format('DD/MM/YYYY HH:mm:ss a')}</Card.Header.Title>
            </Card.Header>
            <Card.Content>
              <Media>
                <Media.Item>
                  <Content>
                    <strong>Status: {e.status}</strong><br />
                    <small>Started {moment(e.startedAt).fromNow()}</small><br />
                    {e.stoppedAt && (<small>Stopped {moment(e.stoppedAt).from(moment(e.startedAt))}</small>)}
                  </Content>
                </Media.Item>
              </Media>
            </Card.Content>
            <Card.Footer>
              <Card.Footer.Item renderAs="a" href="#" onClick={handleViewExecutionClick(e.executionName)}>View</Card.Footer.Item>
            </Card.Footer>
          </Card>
        ))}
      </Content>
    </Panel.Block>
  );
}

WorkflowExecutions.propTypes = {
  orgId: PropTypes.string.isRequired,
  workflowId: PropTypes.string.isRequired,
  currentExecution: PropTypes.string,
  fetchWorkflowExecutions: PropTypes.func.isRequired,
  fetchExecutionEvents: PropTypes.func.isRequired,
  startNewExecution: PropTypes.func.isRequired,
  setCurrentExecution: PropTypes.func.isRequired,
  executions: PropTypes.arrayOf(PropTypes.shape({
    executionName: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    startedAt: PropTypes.string.isRequired,
    stoppedAt: PropTypes.string,
  })),
};

WorkflowExecutions.defaultProps = {
  executions: undefined,
  currentExecution: '',
};

export default WorkflowExecutions;
