import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const finalCreateStore = initialState => {
  const composeEnhancers = (process.env.NODE_ENV === 'production' ? null : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
  const store = createStore(
    rootReducer,
    { ...initialState },
    composeEnhancers(applyMiddleware(thunk)),
  );

  return store;
};

export default finalCreateStore;
