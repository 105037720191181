import Ajv from 'ajv';
import jsonMap from 'json-source-map';

export const getPropertyFromSchema = (schema, propertyName, typeName = '') => {
  if (!schema) return undefined;

  let type = schema;
  if ('$ref' in schema) {
    const rootTypeName = schema.$ref.split('/')[2];
    type = schema.definitions[typeName || rootTypeName];
  }

  if (!type.properties) return undefined;

  const property = type.properties[propertyName];
  if (property?.type) {
    return property;
  }

  // Has a ref?
  const refName = (property?.$ref || '').split('/')[2];
  if (refName) {
    return getPropertyFromSchema(schema, propertyName, refName);
  }

  // Fallback to the property we found
  return property;
};

const getTypeDefinition = (property, name, requiredFields, schema) => {
  if (property.type) {
    return ({
      name,
      type: property.type,
      required: (requiredFields || []).includes(name),
      enum: property.enum,
      input: property.input,
      description: property.description,
      default: property.default,
      properties: property.properties
        ? Object.keys(property.properties).map(k => getTypeDefinition(property.properties[k], k, property.required, schema))
        : undefined,
    });
  }

  const refName = (property.$ref || '').split('/')[2];
  if (!refName) {
    return ({
      name,
      type: 'any',
      required: (requiredFields || []).includes(name),
      enum: property.enum,
      input: property.input,
      description: property.description,
      default: property.default,
      properties: property.properties,
    });
  }
  return getTypeDefinition(schema.definitions[refName], name, property.required, schema);
};

export const getSchemaTypeDefinitions = (schema, typeName = '') => {
  if (!schema) return [];

  let type = schema;
  if ('$ref' in schema) {
    const rootTypeName = schema.$ref.split('/')[2];
    type = schema.definitions[typeName || rootTypeName];
  }
  return Object.keys(type.properties || {}).map(p => getTypeDefinition(type.properties[p], p, type.required, schema));
};

export const getSchemaTypeDescription = (schema, typeName = '') => getSchemaTypeDefinitions(schema, typeName)
  .map(
    p => `${p.name}: ${p.type}${p.required ? ' (required)' : ''}`,
  ).join(',\n');

const ajv = new Ajv({
  allErrors: true,
  jsonPointers: true,
});

const tryParse = str => {
  let json;
  try {
    json = JSON.parse(str);
  } catch (err) {
    json = undefined;
  }

  return json;
};

export const validateJSON = (string, schema, ignoreJsonPaths = false) => {
  const jsonValue = tryParse(string);
  if (!jsonValue) {
    return [{
      from: { line: 0, ch: 0 },
      to: { line: 0, ch: 0 },
      message: 'Invalid JSON',
    }];
  }

  const map = jsonMap.parse(string);
  if (schema && !ajv.validate(schema, jsonValue)) {
    const allErrors = ajv.errors?.map(error => {
      const pointer = map.pointers[error.dataPath];
      if (ignoreJsonPaths) {
        const value = (string.split('\n')[pointer.value.line] || '').substr(pointer.value.column) || '';
        if (value.trim().startsWith('"$.')) return undefined;
      }

      const from = { line: pointer.value.line, ch: pointer.value.column };
      const to = { line: pointer.valueEnd.line, ch: pointer.valueEnd.column };
      const message = ajv.errorsText([error]);
      return { from, to, message };
    }) || [];
    return allErrors.filter(e => !!e);
  }

  return [];
};

export const getNewObjectWithDefaults = schema => {
  const defaults = getSchemaTypeDefinitions(schema).filter(f => 'default' in f);
  if (defaults.length) {
    return defaults.reduce((acc, curr) => ({
      ...acc,
      [curr.name]: curr.default,
    }), {});
  }

  return {};
};
