import React from 'react';
import PropTypes from 'prop-types';
import {
  withRouter,
} from 'react-router-dom';
import useCurrentOrg from '../../hooks/useCurrentOrg';
import { useOrgIntegrationActions } from '../../hooks/useOrgIntegrations';

function Auth({
  history,
  match,
  location,
}) {
  const [currentOrg, setCurrentOrg] = useCurrentOrg();
  const [orgId, setOrgId] = React.useState(undefined);
  const [vaultItem, setVaultItem] = React.useState(undefined);
  const [accessToken, setAccessToken] = React.useState(undefined);
  const { getAccessToken, createOrganizationIntegration } = useOrgIntegrationActions();

  React.useEffect(() => {
    if (!location.search) return;

    const params = new URLSearchParams(location.search);
    const s = JSON.parse(window.atob(params.get('state')));

    setOrgId(s.orgId);
    setVaultItem(JSON.parse(s.state || '{}'));
  }, [location.search]);

  React.useEffect(() => {
    if (!orgId || !currentOrg) return;

    if (orgId !== currentOrg) {
      setCurrentOrg({ orgId });
    }
  }, [orgId, currentOrg, setCurrentOrg]);

  React.useEffect(() => {
    if (!orgId || !match.params.platform) return;

    const fetchToken = async () => {
      const t = await getAccessToken({
        orgId,
        platform: match.params.platform,
        originalUrl: window.location.href,
      });
      setAccessToken(t);
    };

    fetchToken();
  }, [orgId, match.params.platform, vaultItem, getAccessToken]);

  React.useEffect(() => {
    if (!orgId || !vaultItem || !accessToken) return;
    createOrganizationIntegration({
      orgId,
      integration: {
        ...vaultItem,
        platform: match.params.platform,
        value: JSON.stringify(accessToken),
      },
    }).catch(err => {
      window.alert(`Failed to authenticate: ${err.message}`);
    }).then(() => {
      history.push('/organization/apps');
      // Weirdly, Cognito started crapping itself out of the blue whenever we return from an OAuth screen that isn't Cognito itself.
      // We're having to force a reload here in order to avoid getting stuck in a "loading" screen (which is from Cognito!)
      window.location.reload();
    });
  }, [
    orgId,
    match.params.platform,
    vaultItem,
    accessToken,
    createOrganizationIntegration,
    history,
  ]);

  return (
    <>
      <p>Finalizing Authentication...</p>
    </>
  );
}

Auth.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      platform: PropTypes.string.isRequired,
    }),
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
  }).isRequired,
};

export default withRouter(Auth);
