import React from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  Control,
  Label,
} from '@x-functions/freyja/lib/components/form';
import Modal from '@x-functions/freyja/lib/components/modal';
import Button from '@x-functions/freyja/lib/components/button';
import useEventSchema from '../../../../hooks/useEventSchema';
import FormFromSchema from '../../../../components/formFromSchema';
import JsonEditor from '../../../../components/jsonEditor';
import styles from './newExecutionModal.module.scss';

const initialPayload = {};

const getPayload = (current, schema) => {
  if (current === initialPayload && !!schema) {
    return Object.keys(schema.properties).reduce((acc, curr) => ({
      ...acc,
      [curr]: null,
    }), {});
  }

  return current;
};

function NewExecutionModal({ show, onClose, onSave }) {
  const [schema, refreshing] = useEventSchema();
  const [saving, setSaving] = React.useState(false);
  const [payload, setPayload] = React.useState(initialPayload);

  const handleSingleValueChange = React.useCallback(({ target: { name, value } }) => {
    setPayload(p => ({ ...p, [name]: value }));
  }, []);

  const handlePayloadChange = React.useCallback(val => {
    let obj = val;
    if (typeof val === 'string') obj = JSON.parse(val);
    setPayload(obj);
  }, []);

  const handleCancel = React.useCallback(e => {
    setPayload(initialPayload);
    onClose(e);
  }, [onClose]);

  const handleSave = React.useCallback(async e => {
    setSaving(true);
    try {
      await onSave(payload);
      setSaving(false);
      handleCancel(e);
    } catch (err) {
      setSaving(false);
      window.alert(`Error: ${err.message}`);
    }
  }, [payload, onSave, handleCancel]);

  const isLoadingSchema = !schema && refreshing;
  const Editor = schema ? FormFromSchema : JsonEditor;
  const value = getPayload(payload, schema);

  return (
    <Modal show={show}>
      <Modal.Card>
        <Modal.Card.Head onClose={handleCancel}>
          <Modal.Card.Title>New execution</Modal.Card.Title>
        </Modal.Card.Head>
        <Modal.Card.Body>
          <Field>
            <Label>Payload</Label>
            <Control className={styles.control}>
              {!isLoadingSchema && (
                <Editor
                  value={value}
                  values={value}
                  schema={schema}
                  setValue={handleSingleValueChange}
                  setValues={handlePayloadChange}
                  onChange={handlePayloadChange}
                />
              )}
            </Control>
          </Field>
        </Modal.Card.Body>
        <Modal.Card.Foot>
          <Button color="primary" loading={saving} disabled={saving} onClick={handleSave}>Start execution</Button>
          <Button onClick={handleCancel} disabled={saving}>Cancel</Button>
        </Modal.Card.Foot>
      </Modal.Card>
    </Modal>
  );
}

NewExecutionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default NewExecutionModal;
