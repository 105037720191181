import React from 'react';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';
import Panel from '@x-functions/freyja/lib/components/panel';
import Button from '@x-functions/freyja/lib/components/button';
import Heading from '@x-functions/freyja/lib/components/heading';
import {
  Field,
  Label,
  Control,
  Input,
} from '@x-functions/freyja/lib/components/form';
import { workflowNodeType } from '@x-functions/x-react-workflow/lib/components/workflow/types';
import { getColour } from '@x-functions/x-react-workflow/lib/components/workflow/utils';

function ParallelInspector({
  node,
  updateNode,
}) {
  const handleValueChange = React.useCallback(e => {
    const { name, value } = e.target;
    updateNode({ id: node.id, changes: { [name]: value } });
  }, [node.id, updateNode]);

  const handleAddBranch = React.useCallback(() => {
    updateNode({
      id: node.id,
      changes: {
        branches: node.branches.concat({
          id: v4(),
          firstNode: '',
          nodes: {},
        }),
      },
    });
  }, [node, updateNode]);

  const handleBranchDeleted = branchId => () => {
    updateNode({
      id: node.id,
      changes: {
        branches: node.branches.filter(b => b.id !== branchId),
      },
    });
  };

  return (
    <>
      <Panel.Block>
        <Field fullwidth>
          <Label>Step Name</Label>
          <Control fullwidth>
            <Input fullwidth name="name" id="name" value={node.name} onChange={handleValueChange} />
          </Control>
        </Field>
      </Panel.Block>
      <Panel.Header>
        Branches
      </Panel.Header>
      {node.branches.map((branch, i) => (
        <Panel.Block
          key={branch.id}
          style={{ borderTop: `3px solid ${getColour(i)}` }}
        >
          <Button remove onClick={handleBranchDeleted(branch.id)} />
          <Heading>Branch {branch.id}</Heading>
        </Panel.Block>
      ))}
      <Panel.Block>
        <Button color="primary" onClick={handleAddBranch}>Add branch</Button>
      </Panel.Block>
    </>
  );
}

ParallelInspector.propTypes = {
  node: workflowNodeType.isRequired,
  updateNode: PropTypes.func.isRequired,
};

ParallelInspector.defaultProps = {};

export default ParallelInspector;
