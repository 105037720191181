import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import BulmaTile from '@x-functions/freyja/lib/components/tile';
import styles from './styles.module.scss';

function Tile({
  size = 4,
  clickable = true,
  children = null,
  className,
  ...rest
}) {
  return (
    <BulmaTile kind="parent" size={size || 4}>
      <BulmaTile
        kind="child"
        renderAs="article"
        notification
        className={classNames({ [styles.clickable]: clickable }, className)}
        {...rest}
      >
        {children}
      </BulmaTile>
    </BulmaTile>
  );
}

Tile.propTypes = {
  size: PropTypes.number,
  clickable: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};

Tile.defaultProps = {
  size: 4,
  clickable: true,
  children: null,
  className: undefined,
};

export default Tile;
