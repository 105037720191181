import React from 'react';
import PropTypes from 'prop-types';
import Tile from '@x-functions/freyja/lib/components/tile';
import styles from './styles.module.scss';

function TileContainer({ children }) {
  return (
    <Tile kind="ancestor" className={styles.row}>
      {children}
    </Tile>
  );
}

TileContainer.propTypes = {
  children: PropTypes.node,
};

TileContainer.defaultProps = {
  children: null,
};

export default TileContainer;
