import { shallowEqual, useSelector } from 'react-redux';
import useActions from './useActions';
import { selectRoles, updateUserRoles } from '../reducers/user';

const actionCreators = { updateUserRoles };

function useUserRoles() {
  const roles = useSelector(selectRoles, shallowEqual);
  const actions = useActions(actionCreators);
  return { roles, actions };
}

export default useUserRoles;
