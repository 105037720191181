import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 } from 'uuid';
import Panel from '@x-functions/freyja/lib/components/panel';
import Icon from '@x-functions/freyja/lib/components/icon';
import Button from '@x-functions/freyja/lib/components/button';
import { getColour } from '@x-functions/x-react-workflow/lib/components/workflow/utils';
import FunctionCatalog from '../../../../components/functionCatalog';
import styles from '../styles.module.scss';

const createConditional = () => ({
  id: v4(),
  name: 'Conditional',
  type: 'conditional',
  choices: [{
    id: v4(),
    onSuccess: '',
    operator: '',
    colour: getColour(1),
    condition: {
      variable: '',
      dataType: 'String',
      value: '',
      comparison: 'Equals',
    },
  }],
  onNoMatch: '',
});

const createMap = () => {
  const id = v4();
  return {
    id,
    name: 'List Map',
    type: 'map',
    listPath: '',
    output: `$.result.${id}`,
  };
};

const createRepeat = () => {
  const id = v4();
  return {
    id,
    name: 'Repeat',
    type: 'repeat',
    numberOrList: 0,
    nodes: {},
    firstNode: '',
    output: `$.result.${id}`,
  };
};

const createParallel = () => {
  const id = v4();
  return {
    id,
    name: 'Parallel',
    type: 'parallel',
    branches: [{ id: v4(), firstNode: '', nodes: {} }, { id: v4(), firstNode: '', nodes: {} }],
    output: `$.result.${id}`,
  };
};

const createApproval = () => {
  const id = v4();
  return {
    id,
    name: 'Manual Approval',
    type: 'approval',
    output: `$.result.${id}`,
    onSuccess: '',
    onFailure: '',
    notificationType: 'SMS',
    callbackURL: '',
    emailAddress: '',
    mobileNumber: '',
    templateId: '',
    extraVariables: {},
  };
};

const createWait = () => {
  const id = v4();
  return {
    id,
    name: 'Wait',
    type: 'wait',
    seconds: 0,
    onSuccess: '',
  };
};

const createWaitForEvent = () => {
  const id = v4();
  return {
    id,
    name: 'Wait for Event',
    type: 'wait-for-event',
    eventName: '',
    filter: [],
    onSuccess: '',
  };
};

const createEndBranch = () => {
  const id = v4();
  return {
    id,
    name: 'End branch',
    type: 'end',
  };
};

function NodeSelectionPanel({
  className,
  addNode,
  onClose,
}) {
  const handleFunctionClick = React.useCallback(f => {
    const id = v4();
    const node = {
      id,
      name: f.displayName || f.functionName,
      type: 'function',
      function: `${f.orgId}/${f.functionName}`,
      input: {},
      output: `$.result.${id}`,
    };

    addNode({ id, node });
  }, [addNode]);

  const handleConditionalClick = React.useCallback(() => {
    const node = createConditional();
    addNode({ id: node.id, node });
  }, [addNode]);

  const handleListMapClick = React.useCallback(() => {
    const node = createMap();
    addNode({ id: node.id, node });
  }, [addNode]);

  const handleRepeatClick = React.useCallback(() => {
    const node = createRepeat();
    addNode({ id: node.id, node });
  }, [addNode]);

  const handleParallelClick = React.useCallback(() => {
    const node = createParallel();
    addNode({ id: node.id, node });
  }, [addNode]);

  const handleManualApprovalClick = React.useCallback(() => {
    const node = createApproval();
    addNode({ id: node.id, node });
  }, [addNode]);

  const handleConditionalDragStart = React.useCallback(e => {
    const node = createConditional();
    e.dataTransfer.setData('text/node', JSON.stringify(node));
  }, []);

  const handleWaitClick = React.useCallback(() => {
    const node = createWait();
    addNode({ id: node.id, node });
  }, [addNode]);

  const handleWaitDragStart = React.useCallback(e => {
    const node = createWait();
    e.dataTransfer.setData('text/node', JSON.stringify(node));
  }, []);

  const handleWaitForEventClick = React.useCallback(() => {
    const node = createWaitForEvent();
    addNode({ id: node.id, node });
  }, [addNode]);
  const handleWaitForEventDragStart = React.useCallback(e => {
    const node = createWaitForEvent();
    e.dataTransfer.setData('text/node', JSON.stringify(node));
  }, []);

  const handleEndBranchClick = React.useCallback(() => {
    const node = createEndBranch();
    addNode({ id: node.id, node });
  }, [addNode]);

  const handleEndBranchDragStart = React.useCallback(e => {
    const node = createEndBranch();
    e.dataTransfer.setData('text/node', JSON.stringify(node));
  }, []);

  const handleMapDragStart = React.useCallback(e => {
    const node = createMap();
    e.dataTransfer.setData('text/node', JSON.stringify(node));
  }, []);

  const handleRepeatDragStart = React.useCallback(e => {
    const node = createRepeat();
    e.dataTransfer.setData('text/node', JSON.stringify(node));
  }, []);

  const handleParallelDragStart = React.useCallback(e => {
    const node = createParallel();
    e.dataTransfer.setData('text/node', JSON.stringify(node));
  }, []);

  const handleManualApprovalDragStart = React.useCallback(e => {
    const node = createApproval();
    e.dataTransfer.setData('text/node', JSON.stringify(node));
  }, []);

  return (
    <FunctionCatalog
      className={classNames(styles.fixed, className)}
      onClick={handleFunctionClick}
    >
      <Panel.Header className="is-flex is-flex-direction-row is-justify-content-space-between">
        Nodes
        <Button className={styles.closeButton} remove onClick={onClose} />
      </Panel.Header>
      <Panel.Block
        renderAs="a"
        onClick={handleConditionalClick}
        onDragStart={handleConditionalDragStart}
        draggable="true"
      >
        <Panel.Icon renderAs={Icon}>
          <span className="fa fa-question-circle" />
        </Panel.Icon>
        <span style={{ marginRight: '10px' }}>
          Conditional Node
        </span>
      </Panel.Block>
      <Panel.Block
        renderAs="a"
        onClick={handleListMapClick}
        onDragStart={handleMapDragStart}
        draggable="true"
      >
        <Panel.Icon renderAs={Icon}>
          <span className="fa fa-list-alt" />
        </Panel.Icon>
        <span style={{ marginRight: '10px' }}>
          List Map
        </span>
      </Panel.Block>
      <Panel.Block
        renderAs="a"
        onClick={handleRepeatClick}
        onDragStart={handleRepeatDragStart}
        draggable="true"
      >
        <Panel.Icon renderAs={Icon}>
          <i className="material-icons md-16">repeat_on</i>
        </Panel.Icon>
        <span style={{ marginRight: '10px' }}>
          Repeat
        </span>
      </Panel.Block>
      <Panel.Block
        renderAs="a"
        onClick={handleParallelClick}
        onDragStart={handleParallelDragStart}
        draggable="true"
      >
        <Panel.Icon renderAs={Icon}>
          <i className="material-icons md-16">device_hub</i>
        </Panel.Icon>
        <span style={{ marginRight: '10px' }}>
          Do In Parallel
        </span>
      </Panel.Block>
      <Panel.Block
        renderAs="a"
        onClick={handleManualApprovalClick}
        onDragStart={handleManualApprovalDragStart}
        draggable="true"
      >
        <Panel.Icon renderAs={Icon}>
          <i className="material-icons md-16">approval</i>
        </Panel.Icon>
        <span style={{ marginRight: '10px' }}>
          Manual Approval
        </span>
      </Panel.Block>
      <Panel.Block
        renderAs="a"
        onClick={handleWaitClick}
        onDragStart={handleWaitDragStart}
        draggable="true"
      >
        <Panel.Icon renderAs={Icon}>
          <i className="material-icons md-16">hourglass_empty</i>
        </Panel.Icon>
        <span style={{ marginRight: '10px' }}>
          Wait (time)
        </span>
      </Panel.Block>
      <Panel.Block
        renderAs="a"
        onClick={handleWaitForEventClick}
        onDragStart={handleWaitForEventDragStart}
        draggable="true"
      >
        <Panel.Icon renderAs={Icon}>
          <i className="material-icons md-16">flash_on</i>
        </Panel.Icon>
        <span style={{ marginRight: '10px' }}>
          Wait for event
        </span>
      </Panel.Block>
      <Panel.Block
        renderAs="a"
        onClick={handleEndBranchClick}
        onDragStart={handleEndBranchDragStart}
        draggable="true"
      >
        <Panel.Icon renderAs={Icon}>
          <i className="material-icons md-16">stop</i>
        </Panel.Icon>
        <span style={{ marginRight: '10px' }}>
          End branch execution
        </span>
      </Panel.Block>
    </FunctionCatalog>
  );
}

NodeSelectionPanel.propTypes = {
  className: PropTypes.string,
  addNode: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

NodeSelectionPanel.defaultProps = {
  className: undefined,
};

export default NodeSelectionPanel;
