import React from 'react';
import PropTypes from 'prop-types';
import Menu from '@x-functions/freyja/lib/components/menu';

function RepeatFields({ node, onClick }) {
  const makeItemClickHandler = propertyName => () => {
    onClick(`$.result.${node.id}.iterator.${propertyName}`);
  };

  return (
    <Menu>
      <Menu.List title={node.name}>
        <Menu.List.Item onClick={makeItemClickHandler('numberOrList')}>
          List or Number of Repetitions
        </Menu.List.Item>
        <Menu.List.Item onClick={makeItemClickHandler('current')}>
          Current Iteration (0...n)
        </Menu.List.Item>
        <Menu.List.Item onClick={makeItemClickHandler('element')}>
          Current Element (if using list)
        </Menu.List.Item>
      </Menu.List>
    </Menu>
  );
}

RepeatFields.propTypes = {
  node: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

RepeatFields.defaultProps = {};


export default RepeatFields;
