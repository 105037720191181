import React from 'react';
import PropTypes from 'prop-types';
import Panel from '@x-functions/freyja/lib/components/panel';
import Button from '@x-functions/freyja/lib/components/button';
import {
  Field,
  Label,
  Control,
  Input,
} from '@x-functions/freyja/lib/components/form';
import { workflowNodeType } from '@x-functions/x-react-workflow/lib/components/workflow/types';
import { getColour } from '@x-functions/x-react-workflow/lib/components/workflow/utils';
import { v4 } from 'uuid';
import Choice from './choice';

function ConditionalInspector({
  groupId,
  node,
  updateNode,
}) {
  const handleValueChange = React.useCallback(e => {
    const { name, value } = e.target;
    updateNode({ groupId, id: node.id, changes: { [name]: value } });
  }, [node.id, updateNode, groupId]);

  const handleAddCondition = React.useCallback(() => {
    updateNode({
      groupId,
      id: node.id,
      changes: {
        choices: node.choices.concat({
          id: v4(),
          onSuccess: '',
          operator: 'And',
          colour: getColour(node.choices.length + 1),
          condition: {
            variable: '',
            dataType: 'String',
            value: '',
            comparison: 'Equals',
          },
        }),
      },
    });
  }, [node, updateNode, groupId]);

  const handleConditionChange = choiceId => ({ target: { name, value } }) => {
    const choiceIndex = node.choices.findIndex(choice => choice.id === choiceId);
    if (!~choiceIndex) {
      return;
    }
    const choices = [
      ...node.choices.slice(0, choiceIndex),
      {
        ...node.choices[choiceIndex],
        condition: {
          ...node.choices[choiceIndex].condition,
          [name]: value,
          ...(name === 'dataType' ? { value: '' } : {}),
        },
      },
      ...node.choices.slice(choiceIndex + 1),
    ];

    updateNode({
      groupId,
      id: node.id,
      changes: {
        choices,
      },
    });
  };

  const handleChoiceChange = choiceId => ({ target: { name, value } }) => {
    const choiceIndex = node.choices.findIndex(choice => choice.id === choiceId);
    if (!~choiceIndex) {
      return;
    }
    const choices = [
      ...node.choices.slice(0, choiceIndex),
      {
        ...node.choices[choiceIndex],
        [name]: value,
      },
      ...node.choices.slice(choiceIndex + 1),
    ];

    updateNode({
      groupId,
      id: node.id,
      changes: {
        choices,
      },
    });
  };

  const handleChoiceDeleted = choiceId => () => {
    updateNode({
      groupId,
      id: node.id,
      changes: {
        choices: node.choices
          .filter(c => c.id !== choiceId)
          .map((c, i) => ({ ...c, colour: getColour(i + 1) })),
      },
    });
  };

  return (
    <>
      <Panel.Block>
        <Field fullwidth>
          <Label>Step Name</Label>
          <Control fullwidth>
            <Input fullwidth name="name" id="name" value={node.name} onChange={handleValueChange} />
          </Control>
        </Field>
      </Panel.Block>
      <Panel.Header>
        Branches
      </Panel.Header>
      {node.choices.map(choice => (
        <Choice
          key={choice.id}
          nodeId={node.id}
          choice={choice}
          onChoiceChange={handleChoiceChange(choice.id)}
          onChoiceDelete={handleChoiceDeleted(choice.id)}
          onConditionChange={handleConditionChange(choice.id)}
        />
      ))}
      <Panel.Block>
        <Button color="primary" onClick={handleAddCondition}>Add condition</Button>
      </Panel.Block>
    </>
  );
}

ConditionalInspector.propTypes = {
  groupId: PropTypes.string,
  node: workflowNodeType.isRequired,
  updateNode: PropTypes.func.isRequired,
};

ConditionalInspector.defaultProps = {
  groupId: undefined,
};

export default ConditionalInspector;
